import { updateAppName } from '../../utils/misc';

const ContactingSporforya = () => {
  return (
    <p>
      These Payments Terms are available at {process.env.REACT_HOME_URL}. {updateAppName('Sporti')} Payments
      will provide a copy of these Payments Terms on request. If you have any
      questions about these Payments Terms, please email us.
    </p>
  );
};

export default ContactingSporforya;
