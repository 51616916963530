import React from 'react';
import { updateAppName } from '../../utils/misc';

const RewardPolicy = () => {
  return (
    <>
      <ul className="space-y-4">
        <li className="ml-8">
          1. {updateAppName('Sporti')} Rewards ("Rewards") is also known as the “Sporty Club
          Points”, and is offered to {updateAppName('Sporti')} customers at the sole discretion of
          {updateAppName('Sporti')}, and is open only to individuals who are eighteen (18) years of
          age and older, have a valid email address, and have enrolled in the
          Rewards ("User(s)").
        </li>
        <li className="ml-8">
          2. By participating in the Rewards, Users can earn {updateAppName('Sporti')} Rewards
          points ("Sporty Club points" or "Points") for booking and completing
          eligible services through {updateAppName('Sporti')} and redeem available Points for
          certain services, subject to these Terms and the availability of
          eligible services and reward items.
        </li>
        <li className="ml-8">
          3. In order to earn {updateAppName('Sporti')} Rewards points, eligible services must be
          completed. This means that you, or other users booked in a Sports
          Activity using your {updateAppName('Sporti')} account ("Account"). The terms "you" and
          "your" throughout these Terms refer to any User and any customer
          eligible to participate in the Rewards.
        </li>
        <li className="ml-8">
          4. Access to {updateAppName('Sporti')} Rewards is void if prohibited by law in the state
          or country of the User's residence, and subject to change as may be
          necessary to comply with such laws or regulations.
        </li>
        <li className="ml-8">
          5. Enrollment
          <ul>
            <li className="ml-8">
              5.1. You must have an account with {updateAppName('Sporti')} in order to enroll in
              {updateAppName('Sporti')} Rewards. To earn {updateAppName('Sporti')} Rewards points for a booking on
              {updateAppName('Sporti')}, you must be an enrolled Customer of {updateAppName('Sporti')} Rewards prior
              to making a booking, and be signed in to your Account prior to
              booking. No Points will be earned for bookings made on {updateAppName('Sporti')} when
              you are not signed in to your Account. If you enroll in {updateAppName('Sporti')}
              Rewards after booking but before service is completed, Points will
              not accrue on that booking.
            </li>
            <li className="ml-8">
              5.2. As an enrolled Customer of the Rewards, you will receive
              account servicing updates by email. You may opt out of
              participation in the Rewards from the Rewards on {updateAppName('Sporti')}.com. Your
              ability to earn and redeem {updateAppName('Sporti')} Rewards points will be suspended
              after you opt out. If you opt out of Rewards participation, you
              will no longer have access to your account statement information,
              no {updateAppName('Sporti')} Rewards points will accumulate on future bookings, and
              any accumulated {updateAppName('Sporti')} Rewards points will be unavailable for
              redemption. Any Points in your Account at the time of opting out
              will expire fourteen (14) days from your opt-out date.
            </li>
          </ul>
        </li>
        <li className="ml-8">
          6. Earning Points
          <ul>
            <li className="ml-8">
              6.1. {updateAppName('Sporti')} awards 1 {updateAppName('Sporti')} Rewards Point for every $1 US Dollar
              spent on {updateAppName('Sporti')}. Only to the owner of the Account through which
              any eligible booking is made. You will also earn points for any
              additional individuals named as users on a service booked using
              your Account.
            </li>
            <li className="ml-8">
              6.2. After making an eligible booking, the anticipated amount of
              {updateAppName('Sporti')} Rewards points to be awarded will be held in pending
              status. As mentioned above, "completed service" means that you, or
              other users booked under your Account, must have completed the
              service/activity for which you booked and paid. Pending {updateAppName('Sporti')}
              Rewards points are not available for redemption usage. Points will
              move from pending to available status after 5 days from completion
              of service. Once in available status, {updateAppName('Sporti')} Rewards points are
              eligible to be used by the User for redemption purposes. The
              amount of pending {updateAppName('Sporti')} Rewards points calculated at the time of
              booking is based on the details of the service booked and
              completed. Changes or cancellations to the booked service may
              result in differences between the estimated amount of pending
              points calculated and the ultimate amount of points that post to
              the User's Account for that service. No {updateAppName('Sporti')} Rewards points will
              be earned for change or cancellation fees.
            </li>
            <li className="ml-8">
              6.3. {updateAppName('Sporti')} reserves the right to rescind at any time any pending
              or available {updateAppName('Sporti')} Rewards points that were earned or awarded for
              unused service items. {updateAppName('Sporti')} Rewards points will be rescinded in
              cases where a User receives a refund, from either {updateAppName('Sporti')} or a
              Provider. Points earned pursuant to fraudulent, sham or falsified
              information will be rescinded by {updateAppName('Sporti')}.
            </li>
            <li className="ml-8">
              6.4. {updateAppName('Sporti')} has the right to monitor all Customer Account
              activity. If your {updateAppName('Sporti')} Rewards Account shows signs of fraud,
              abuse, or suspicious activity, {updateAppName('Sporti')} may close or freeze your
              Account immediately, and you may lose your accrued {updateAppName('Sporti')} Rewards
              points. If you have conducted any fraudulent activity, {updateAppName('Sporti')}
              reserves the right to take any necessary legal action and may have
              grounds to confiscate any rewards redeemed as a result of such
              activity. In addition, you may be liable for monetary losses to
              {updateAppName('Sporti')}, including litigation costs and damages, and you will not
              be allowed to participate in {updateAppName('Sporti')} Rewards in the future.
              Bookings discovered to be fraudulent will have their {updateAppName('Sporti')}
              Rewards points rescinded, and the Account associated with such
              fraudulent bookings will be frozen from further Rewards Point
              earning or redemption activity.
            </li>
            <li className="ml-8">
              6.5. If your Account does not represent the correct number of
              {updateAppName('Sporti')} Rewards points that you should have been awarded as
              determined by {updateAppName('Sporti')}, {updateAppName('Sporti')} reserves the right to augment or
              decrement your Point balance. If you believe your Account has been
              the subject of any suspicious activity, please contact the {updateAppName('Sporti')}
              immediately. If it is determined you have been the victim of a
              fraud, the Points you have earned may be transferred to a new
              Account.
            </li>
            <li className="ml-8">
              6.6 Rewards Point accumulation and redemption activity can be
              accessed on your Account. {updateAppName('Sporti')} Rewards points and rewards have
              no cash value and may not be traded or redeemed for cash. {updateAppName('Sporti')}
              Rewards points may not be assigned, sold, transferred, and/or
              pledged to any third party. You have no property rights or other
              legal interests in {updateAppName('Sporti')} Rewards points. You are responsible for
              any personal tax liability that may be related to participation in
              {updateAppName('Sporti')} Rewards and redemption of any rewards.
            </li>
            <li className="ml-8">
              6.7. If a you believe you did not receive the correct amount of
              {updateAppName('Sporti')} Rewards points for booking, the Customer must contact
              {updateAppName('Sporti')} within fifty (50) days from the service end date. {updateAppName('Sporti')}
              will make the final determination as to whether Points adjustments
              of any kind are justified for the travel in question. In no
              Service will any {updateAppName('Sporti')} Rewards points manually be moved from
              pending to available status sooner than after completion of the
              booking.
            </li>
            <li className="ml-8">
              6.8 Points are awarded on the net value of the cost of the
              listing, and not on the value of any taxes, insurance or other
              fees associated with the cost of the listing, charged by the
              Provider or other party.
            </li>
          </ul>
        </li>
        <li className="ml-8">
          7. Redeeming Points
          <ul>
            <li className="ml-8">
              7.1. {updateAppName('Sporti')} Rewards points can be redeemed on sporti to book
              and pay for the sports activities listed by a Provider (services,
              events, facilities).
            </li>
            <li className="ml-8">
              7.2. Points can not be redeemed for cash and have no cash value.
            </li>
            <li className="ml-8">
              7.3. Pending {updateAppName('Sporti')} Rewards points can not be used towards
              bookings.
            </li>
            <li className="ml-8">
              7.4. Customers can redeem available {updateAppName('Sporti')} Rewards points to pay
              the full cost of a sports activity, or the partial cost of a
              sports activity.
            </li>
            <li className="ml-8">
              7.5. All point redemptions and bookings are subject to sports
              activity availability and any special restrictions on the listing
              at the time of booking.
            </li>
            <li className="ml-8">
              7.6. Points may not be redeemed for any required taxes, insurance
              and other fees associated to the cost of the listing, or charged
              by the Provider or other party.
            </li>
          </ul>
        </li>
        <li className="ml-8">
          8. Earning Points from Redeeming Points
          <ul>
            <li className="ml-8">
              8.1. Points can not be earned from a booking, where points are
              redeemed to pay for a booking, such a booking will not be eligible
              to earn points.
            </li>
          </ul>
        </li>
        <li className="ml-8">
          9. Points Value
          <ul>
            <li className="ml-8">
              9.1 The approximate average value of one (1) sporti rewards
              point is $0.2. {updateAppName('Sporti')} reserves the right to revise or adjust this
              value at any time without notice.
            </li>
          </ul>
        </li>
        <li className="ml-8">
          10. Disputes and Right to Participate
          <ul className="space-y-4">
            <li className="ml-8">
              10.1 {updateAppName('Sporti')} is committed to customer satisfaction, if you have a
              problem or dispute, we will try to resolve your concerns. But if
              unsuccessful, you may pursue claims as explained in this section.
            </li>
            <li className="ml-8">
              10.2 You agree to give us an opportunity to resolve any disputes
              or claims relating in any way to the Website, any dealings with
              our customer service agents, any services or products provided,
              any representations made by us, or our Privacy Policy (“Claims”)
              by contacting {updateAppName('Sporti')}. If we are not able to resolve your Claims
              within 60 days, you may seek relief through arbitration or in
              small claims court, as set forth below.
            </li>
            <li className="ml-8">
              10.3 Any and all Claims will be resolved by binding arbitration,
              rather than in court, except you may assert Claims on an
              individual basis in small claims court if they qualify. This
              includes any Claims you assert against us, our subsidiaries,
              providers, suppliers or any companies offering products or
              services through us (which are beneficiaries of this arbitration
              agreement). This also includes any Claims that arose before you
              accepted these Terms, regardless of whether prior versions of the
              Terms required arbitration.
            </li>
            <li className="ml-8">
              10.4 There is no judge or jury in arbitration, and court review of
              an arbitration award is limited. However, an arbitrator can award
              on an individual basis the same damages and relief as a court
              (including statutory damages, attorneys’ fees and costs), and must
              follow and enforce these Terms as a court would.
            </li>
            <li className="ml-8">
              10.5 Arbitrations will be conducted by the American Arbitration
              Association (AAA) under its rules, including the AAA Consumer
              Rules. Any and all proceedings to resolve Claims will be conducted
              only on an individual basis and not in a class, consolidated or
              representative action. If for any reason a Claim proceeds in court
              rather than in arbitration we each waive any right to a jury
              trial. The Federal Arbitration Act and federal arbitration law
              apply to this agreement. An arbitration decision may be confirmed
              by any court with competent jurisdiction.
            </li>
            <li className="ml-8">
              10.6 {updateAppName('Sporti')} may at its sole discretion modify any of the Terms
              governing the Rewards—including, but not limited to, the rules for
              earning and redeeming {updateAppName('Sporti')} Rewards points, the expiration policy
              for Points and the products and services on which Points may be
              earned and redeemed—at any time, with or without notice, even
              though these changes may affect your ability to use any Points or
              benefits you have already earned. {updateAppName('Sporti')} reserves the right in its
              sole discretion to disqualify any {updateAppName('Sporti')} Rewards User it finds to
              be tampering with the operation of the Rewards or to be acting in
              violation of these Terms & Conditions or in a fraudulent or
              deceptive manner.
            </li>
            <li className="ml-8">
              10.7 Any attempt by any {updateAppName('Sporti')} Rewards User to undermine the
              legitimate operation of the Rewards may be a violation of criminal
              and civil law, and, should such an attempt be made, {updateAppName('Sporti')}
              reserves the right to seek damages from any such person to the
              fullest extent permitted by law. Failure by {updateAppName('Sporti')} to enforce any
              of these Terms & Conditions shall not constitute a waiver of that
              provision. {updateAppName('Sporti')} makes no guarantees, warranties, or
              representations of any kind concerning the Rewards.
            </li>
            <li className="ml-8">
              10.8 You release {updateAppName('Sporti')} and its affiliates and partners from all
              liability regarding the earning, redemption, and use of {updateAppName('Sporti')}
              Rewards points, including any rewards that, after receipt, may be
              lost, stolen, or destroyed. {updateAppName('Sporti')} suppliers are in no way
              affiliated with or responsible for {updateAppName('Sporti')} Rewards Rewards
              administration.
            </li>
            <li className="ml-8">
              10.9 The Rewards has no predetermined termination date and may
              continue until such time as {updateAppName('Sporti')} decides to terminate the
              Rewards. {updateAppName('Sporti')} may terminate the Rewards at any time. Upon
              termination, you will have ninety (90) days from the date Rewards
              termination is announced to use available {updateAppName('Sporti')} Rewards points
              remaining in your account. After that date, any Points will be
              forfeited without compensation.
            </li>
            <li className="ml-8">
              10.10 Your continued participation in the Rewards constitutes your
              acceptance of any changes to these Terms . You are responsible for
              remaining knowledgeable as to any changes that {updateAppName('Sporti')} may make to
              these Terms . The most current version of these Terms will be
              available on the website, and will supersede all previous versions
              of these Terms .
            </li>
          </ul>
        </li>
        <li className="ml-8">
          11. Points Expiration
          <ul>
            <li className="ml-8">
              {updateAppName('Sporti')} Rewards points will not expire as long as there is a
              qualifying purchase or redemption activity through {updateAppName('Sporti')} on your
              Account at least once every twelve (12) months. "Qualifying
              purchase or redemption activity" means an {updateAppName('Sporti')} Rewards eligible
              service which has been both booked and completed (and for which
              points have been confirmed), an eligible purchase made with your
              {updateAppName('Sporti')} Rewards account. All points in your Account will expire if
              there is no qualifying purchase or redemption activity for a
              period of twelve (12) months. Once expired, {updateAppName('Sporti')} Rewards points
              will not be reinstated.
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default RewardPolicy;
