import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/index';
import Footer from '../../components/Footer';

const SupportPage = () => {
  return (
    <div className="w-full h-full">
      <Header />
      <div className= "min-h-screen bg-primary-background">
      <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default SupportPage;
