import React from 'react';
import { updateAppName } from '../../utils/misc';

const Damage = () => {
  return (
    <ul className="space-y-2">
      <li>
        11.1 As a User, you are responsible for leaving the Sports Activities
        (including any personal or other property located at the Sports
        Activities) in the condition it was in when you arrived. You are
        responsible for your own acts and omissions and are also responsible for
        the acts and omissions of any individuals whom you invite to, or
        otherwise provide access to, the Sports Activities, excluding the
        Provider (and the individuals the Provider invites to the Sports
        Activities, if applicable).
      </li>
      <li>
        11.2 If a Provider claims and provides evidence that you as a User have
        damaged an Sports Activities or any personal or other property at an
        Sports Activities (<strong>"Damage Claim"</strong>), the Provider can
        seek payment from you through the Resolution Center. If a Provider
        escalates a Damage Claim to {updateAppName('Sporti')}, you will be given an opportunity to
        respond. If you agree to pay the Provider, or {updateAppName('Sporti')} determines in its
        sole discretion that you are responsible for the Damage Claim, {updateAppName('Sporti')}
        via {updateAppName('Sporti')} Payments will, after the end of your participate, collect any
        such sums from you and/or against the Security Deposit (if applicable)
        required to cover the Damage Claim pursuant to the Payments Terms.
        {updateAppName('Sporti')} also reserves the right to otherwise collect payment from you and
        pursue any remedies available to {updateAppName('Sporti')} in this regard in situations in
        which you are responsible for a Damage Claim, including, but not limited
        to, in relation to any payment requests made by Providers.
      </li>
      <li>
        11.3 Customers agree to cooperate with and assist {updateAppName('Sporti')} in good faith,
        and to provide {updateAppName('Sporti')} with such information and take such actions as may
        be reasonably requested by {updateAppName('Sporti')}, in connection with any Damage Claims
        or other complaints or claims made by Customers relating to (i) Sports
        Activities or any personal or other property located at an Sports
        Activities, (ii) Co-Provider agreements, or (iii) a Group Payment
        Booking. A Customer shall, upon {updateAppName('Sporti')}'s reasonable request and at no
        cost to the Customer, participate in mediation or a similar resolution
        process with another Customer, which process will be conducted by {updateAppName('Sporti')}
        or a third party selected by {updateAppName('Sporti')} or its insurer, with respect to
        losses for which a Customer is requesting payment from {updateAppName('Sporti')}.
      </li>
      <li>
        11.4 If you are a User or a Provider, you understand and agree that
        {updateAppName('Sporti')} may make a claim under your business or other insurance policy
        related to any damage or loss that you may have caused, or been
        responsible for, to any personal or other property (including an Sports
        Activities) of the Provider. You agree to cooperate with and assist
        {updateAppName('Sporti')} in good faith, and to provide {updateAppName('Sporti')} with such information as may
        be reasonably requested by {updateAppName('Sporti')}, to make a claim under your
        homeowner's, renter's or other insurance policy, including, but not
        limited to, executing documents and taking such further acts as {updateAppName('Sporti')}
        may reasonably request to assist {updateAppName('Sporti')} in accomplishing the foregoing.
      </li>
    </ul>
  );
};

export default Damage;
