import TextWrapper from '../../components/TextWrapper/Index';
import { updateAppName } from '../../utils/misc';
import Abandonment from './Abandonment';
import Account from './Account';
import AdditionalClauses from './AdditionalClauses';
import ApplicableLaw from './ApplicableLaw';
import Appointment from './Appointment';
import ContactingSporforya from './ContactingSporforya';
import CurrencyConvertion from './CurrencyConvertion';
import Desclaimers from './Desclaimers';
import DisputeResolution from './DisputeResolution';
import Eligibility from './Eligibility';
import Feedback from './Feedback';
import FinancialTerms from './FinancialTerms';
import FinancialTermsUsr from './FinancialTermsUsr';
import GeneralFinTerms from './GeneralFinTerms';
import GeneralProvisions from './GeneralProvisions';
import Idemnification from './Idemnification';
import IntellectualProperty from './IntellectualProperty';
import KeyDefinitions from './KeyDefinitions';
import Liability from './Liability';
import Modification from './Modification';
import PaymentMethods from './PaymentMethods';
import ProhibitedActivities from './ProhibitedActivities';
import Security from './Security';
import Termination from './Termination';

const PaymentTerms = () => {
  return (
    <section className="px-4 py-6 bg-primary-background text-justify text-gray-500">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl text-black py-4">Payment Terms</h1>
        <div className="space-y-4">
          <Introduction />

          <TextWrapper title="1. Scope and Use of the Payment Services">
            <First />
          </TextWrapper>

          <TextWrapper title="2. Key Definitions">
            <KeyDefinitions />
          </TextWrapper>
          <TextWrapper title="3. Modification of these Payments Terms">
            <Modification />
          </TextWrapper>
          <TextWrapper title="4. Eligibility, Customer Verification">
            <Eligibility />
          </TextWrapper>
          <TextWrapper title="5. Account Registration">
            <Account />
          </TextWrapper>
          <TextWrapper title="6. Payment Methods and Payout Methods">
            <PaymentMethods />
          </TextWrapper>
          <TextWrapper title="7. Financial Terms for Providers">
            <FinancialTerms />
          </TextWrapper>
          <TextWrapper title="8. Financial Terms for Users">
            <FinancialTermsUsr />
          </TextWrapper>
          <TextWrapper title={`9. Appointment of ${updateAppName('Sporti')} Payments as Limited Payment Collection Agent`}>
            <Appointment />
          </TextWrapper>
          <TextWrapper title="10. General Financial Terms">
            <GeneralFinTerms />
          </TextWrapper>

          <TextWrapper title="11. Security Deposits">
            <Security />
          </TextWrapper>
          <TextWrapper title="12. Currency Conversion">
            <CurrencyConvertion />
          </TextWrapper>
          <TextWrapper title="13. Abandonment">
            <Abandonment />
          </TextWrapper>
          <TextWrapper title="14. Prohibited Activities">
            <ProhibitedActivities />
          </TextWrapper>
          <TextWrapper title="15. Intellectual Property Ownership, Rights Notices">
            <IntellectualProperty />
          </TextWrapper>
          <TextWrapper title="16. Feedback">
            <Feedback />
          </TextWrapper>
          <TextWrapper title="17. Disclaimers">
            <Desclaimers />
          </TextWrapper>
          <TextWrapper title="18. Liability">
            <Liability />
          </TextWrapper>
          <TextWrapper title="19. Indemnification">
            <Idemnification />
          </TextWrapper>
          <TextWrapper title="20. Termination, Suspension, and other Measures">
            <Termination />
          </TextWrapper>
          <TextWrapper title="21. Applicable Law and Jurisdiction">
            <ApplicableLaw />
          </TextWrapper>
          <TextWrapper title="22. Dispute Resolution and Arbitration Agreement">
            <DisputeResolution />
          </TextWrapper>
          <TextWrapper title="23. General Provisions">
            <GeneralProvisions />
          </TextWrapper>
          <TextWrapper title="24-26. Intentionally Absent">
            <span></span>
          </TextWrapper>
          <TextWrapper title="27. Additional Clauses for Users that are Businesses.">
            <AdditionalClauses />
          </TextWrapper>
          <TextWrapper title={`28. Contacting ${updateAppName('Sporti')} Payments`}>
            <ContactingSporforya />
          </TextWrapper>
        </div>
      </div>
    </section>
  );
};

export default PaymentTerms;

const Introduction = () => {
  return (
    <div className="space-y-4">
      <p>
        Please read these Payments Terms of Service (“Payments Terms”) carefully
        as they contain important information about your legal rights, remedies
        and obligations. By using the Payment Services (as defined below), you
        agree to comply with and be bound by these Payments Terms.
      </p>
      <p>
        Please note: these Payments Terms contain an arbitration clause and
        class action waiver that applies to all {updateAppName('Sporti')} Customers. If your
        country of residence is the United States, this provision applies to all
        disputes with {updateAppName('Sporti')} Payments. If your country of residence is outside
        of the United States, this provision applies to any action you bring
        against {updateAppName('Sporti')} Payments in the United States. It affects how disputes
        with {updateAppName('Sporti')} Payments are resolved. By accepting these Payments Terms,
        you agree to be bound by this arbitration clause and class action
        waiver. Please read it carefully.
      </p>
      <p>
        These Payments Terms constitute a legally binding agreement (“{' '}
        <strong className="text-black">Agreement</strong>”) between you and
        {updateAppName('Sporti')} Payments (as defined below) governing the Payment Services
        (defined below) conducted through or in connection with the {updateAppName('Sporti')}
        Platform. When these Payments Terms mention “
        <strong className="text-black">{updateAppName('Sporti')} Payments,</strong>” “
        <strong className="text-black">we,</strong>” “
        <strong className="text-black">us,</strong>” or “
        <strong className="text-black">our,</strong>” it refers to the {updateAppName('Sporti')}
        Payments company you are contracting with for Payment Services, which in
        this case is our payments partner stripe.com. {updateAppName('Sporti')} Payments and all
        payment processing services for Providers on {updateAppName('Sporti')} are provided by
        Stripe and are subject to the Stripe Connected Account Agreement, which
        includes the Stripe Terms of Service (collectively, the “Stripe Services
        Agreement''). By agreeing to these terms or continuing to operate as a
        Provider on {updateAppName('Sporti')}, you agree to be bound by the Stripe Services
        Agreement, as the same may be modified by Stripe from time to time. As a
        condition of {updateAppName('Sporti')} enabling payment processing services through Stripe,
        you agree to provide {updateAppName('Sporti')} accurate and complete information about you
        and your business, and you authorize {updateAppName('Sporti')} to share it and transaction
        information related to your use of the payment processing services
        provided by Stripe.
      </p>
      <p>
        The {updateAppName('Sporti')} Terms of Service (“
        <strong className="text-black">{updateAppName('Sporti')} Terms</strong>”) separately
        govern your use of the {updateAppName('Sporti')} Platform. All capitalized terms have the
        meaning set forth in the {updateAppName('Sporti')} Terms unless otherwise defined in these
        Payments Terms.
      </p>
      <p>
        In certain situations, customers may be required to enter into unique
        payment processing agreements with {updateAppName('Sporti')} Payments. To the extent that
        there is a conflict between these Payment Terms and Terms set forth in
        any relevant payment processing agreement, the latter Terms will take
        precedence, unless specified otherwise.
      </p>
      <p>
        Our collection and use of personal information in connection with your
        access to and use of the Payment Services is described in {updateAppName('Sporti')}&apos;s
        Privacy Policy.
      </p>
    </div>
  );
};

const First = () => {
  return (
    <div className="space-y-4">
      <p>
        {updateAppName('Sporti')} Payments provides payments services to Customers, including
        payment collection services, payments and payouts, in connection with
        and through the {updateAppName('Sporti')} Platform (“
        <strong className="text-black">Payment Services</strong>
        ”).
      </p>
      <p>
        {updateAppName('Sporti')} Payments may temporarily and under consideration of the
        Customers&apos; legitimate interests (e.g., by providing prior notice),
        restrict the availability of the Payment Services, or certain services
        or features thereof, to carry out maintenance measures that ensure the
        proper or improved functioning of the Payment Services. {updateAppName('Sporti')} Payments
        may improve, enhance and modify the Payment Services and introduce new
        Payment Services from time to time. {updateAppName('Sporti')} Payments will provide notice
        to Customers of any changes to the Payment Services, unless such changes
        do not materially increase the Customers&apos; contractual obligations
        or decrease the Customers&apos; rights under these Payments Terms.
      </p>
      <p>
        The Payment Services may contain links to third-party websites or
        resources (“<strong className="text-black">Third-Party Services</strong>
        ”). Such Third-Party Services are subject to different Terms and privacy
        practices and Customers should review them independently. {updateAppName('Sporti')}
        Payments is not responsible or liable for the availability or accuracy
        of such Third-Party Services, or the content, products, or services
        available from such Third-Party Services. Links to such Third-Party
        Services are not an endorsement by {updateAppName('Sporti')} Payments of such Third-Party
        Services.
      </p>
      <p>
        You may not use the Payment Services except as authorized by United
        States law, the laws of the jurisdiction that is your country of
        residence, and any other applicable laws. In particular, but without
        limitation, the Payment Services may not be used to send or receive
        funds: (i) into any United States embargoed countries; or (ii) to anyone
        on the U.S. Treasury Department&apos;s list of Specially Designated
        Nationals or the U.S. Department of Commerce&apos;s Denied Persons List
        or Entity List. You represent and warrant that: (i) neither you nor your
        Provider Services are located or take place in a country that is subject
        to a U.S. Government embargo, or that has been designated by the U.S.
        Government as a “terrorist supporting” country; and (ii) you are not
        listed on any U.S. Government list of prohibited or restricted parties.
        In addition to complying with the above, you must also comply with any
        relevant export control laws in your local jurisdiction.
      </p>
      <p>
        Your access to or use of certain Payment Services may be subject to, or
        require you to accept, additional Terms . If there is a conflict between
        these Payments Terms and Terms applicable for a specific Payment
        Service, the latter Terms will take precedence with respect to your use
        of or access to that Payment Service, unless specified otherwise.
      </p>
    </div>
  );
};
