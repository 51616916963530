import TextWrapper from '../../components/TextWrapper/Index';
import { updateAppName } from '../../utils/misc';
import ChangesToPrivacy from './ChangesToPrivacy';
import ContactUs from './ContactUs';
import HowWeUse from './HowWeUse';
import Information from './Information';
import Operating from './Operating';
import OtherImportant from './OtherImportant';
import Security from './Security';
import Sharing from './Sharing';
import YourRights from './YourRights';

const PrivacyPolicy = () => {
  return (
    <section className="px-4 py-6 bg-primary-background text-justify text-gray-500">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl text-black py-4">Privacy Policy</h1>
        <div className="space-y-4">
          <Introduction />

          <TextWrapper title="2. Information We Collect">
            <Information />
          </TextWrapper>
          <TextWrapper title="3. How We Use Information We Collect">
            <HowWeUse />
          </TextWrapper>
          <TextWrapper title="4. Sharing and Disclosure">
            <Sharing />
          </TextWrapper>
          <TextWrapper title="5. Other Important Information">
            <OtherImportant />
          </TextWrapper>
          <TextWrapper title="6. Your Rights">
            <YourRights />
          </TextWrapper>
          <TextWrapper title="7. Operating Globally & International Transfers">
            <Operating />
          </TextWrapper>
          <TextWrapper title="8. Security">
            <Security />
          </TextWrapper>
          <TextWrapper title="9. Changes To This Privacy Policy">
            <ChangesToPrivacy />
          </TextWrapper>
          <TextWrapper title="10. Contact Us">
            <ContactUs />
          </TextWrapper>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;

const Introduction = () => {
  return (
    <div className="space-y-4">
      <h2 className="text-black text-lg">1. Introduction</h2>
      <p>
        Thank you for using {updateAppName('Sporti')}! Your trust is important to us and we&apos;re
        committed to protecting the privacy and security of your personal
        information. The information that&apos;s shared with us helps us to
        provide a great experience with {updateAppName('Sporti')}. We have a dedicated privacy team
        that&apos;s committed to protecting all the personal information we
        collect and help ensure that personal information is handled properly
        worldwide.
      </p>
      <p>
        This Privacy Policy describes how we collect, use, process, and disclose
        your personal information, in conjunction with your access to and use of
        the {updateAppName('Sporti')} Platform and the Payment Services. This privacy policy
        describes our privacy practices for all websites, platforms and services
        that link to it. Please read the privacy policy on the applicable site.
      </p>
      <ul className="space-y-4">
        <li>
          <strong className="text-black text-lg"> 1.1 Definitions</strong>
          <p>
            If you see an undefined term in this Privacy Policy (such as
            “Listing” or {updateAppName('Sporti')} Platform”), it has the same definition as in
            our Terms of Service (“Terms”).
          </p>
        </li>
        <li>
          <strong className="text-black text-lg"> 1.2 Data Controller</strong>
          <p>
            When this policy mentions {updateAppName('Sporti')},” “we,” “us,” or “our,” it
            refers to the {updateAppName('Sporti')} company that is responsible for your
            information under this Privacy Policy (the “Data Controller”).
          </p>
          <ul className="list-disc">
            <li className="ml-6">
              If your country of residence is the United States, the Data
              Controller is {updateAppName('Sporti')}, Inc.
            </li>
          </ul>
        </li>
        <li>
          <strong className="text-black text-lg">
            1.3 Applicability to Payments
          </strong>

          <p>
            This Privacy Policy also applies to the Payment Services provided to
            you by {updateAppName('Sporti')} Payments pursuant to the Payments Terms of Service
            (“Payments Terms”). When using the Payment Services, you will be
            also providing your information, including personal information, to
            one or more {updateAppName('Sporti')} Payments entities, which will also be the Data
            Controller (the "Payments Data Controller") of your information
            related to the Payment Services, generally depending on your country
            of residence.
          </p>
          <p>
            If you change your country of residence, the Data Controller and/or
            Payments Data Controller will be determined by your new country of
            residence as specified above, from the date on which your country of
            residence changes. To this end the Data Controller and/or Payment
            Data Controller that originally collected your personal information
            will need to transfer such personal information to the new
            applicable Data Controller and/or Payments Data Controller due to
            the fact that such transfer is necessary for the performance of the
            contractual relationship with you.
          </p>
          <p>
            Please see the Contact Stripe below for contact details of the Data
            Controllers and Payments Data Controllers.
          </p>
        </li>
      </ul>
    </div>
  );
};
