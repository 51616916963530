import { useEffect, useState } from 'react';
import Logo from '../../assets/headerIcons/logo_new.png';
import AthLogo from '../../assets/headerIcons/athLogo.png';
import SearchInput from '../SearchInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/type';
import { useDispatch } from 'react-redux';
import { fetchArticlesBySearch } from '../../store/reducers/activitySlice';

const Header = () => {
  const [selected, setSelected] = useState<string>('');
  const onQurreyChange = (value: string) => setSelected(value);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchArticlesBySearch(selected));
  }, [selected, dispatch]);

  const filteredArticles = useSelector(
    (state: RootState) => state.activity.filteredArticles,
  );

  return (
    <section className="border-2 px-4 w-full h-20 opacity-100 bg-white shadow-3xl max-[350px]:h-fit">
      <div className=" flex justify-around md:justify-between flex-wrap items-center mt-[14px] max-w-7xl mx-auto">
        <div className="flex justify-center items-center gap-2">
          <img src={process.env.REACT_APP_NAME === 'Athlos' ? AthLogo : Logo} alt="logo" className="h-12 w-16" />
          <span className="opacity-100 font-semibold text-[#0BA14A] text-xl tracking-[0.3px]">
            Help Center
          </span>
        </div>
        <div className="w-1/2 max-[350px]:w-[90%] max-[350px]:py-4 flex justify-end">
          <div className="w-full max-w-[480px]">
            <SearchInput
              placeholder="Search help articles"
              options={filteredArticles}
              value={selected}
              onChange={onQurreyChange}
              className=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
