import React from 'react';

const Cancellation = () => {
  return (
    <ul className="space-y-2">
      <li>
        9.1 For cancellations, refunds, resolution center, see our Cancellation
        Policy.
      </li>
    </ul>
  );
};

export default Cancellation;
