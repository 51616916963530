import React from 'react';
import { updateAppName } from '../../utils/misc';

const GeneralFinTerms = () => {
  return (
    <div className="space-y-4">
      <p>10.1 {updateAppName('Sporti')} service fees</p>
      <p>
        To help {updateAppName('Sporti')} run smoothly and to cover the cost of our platform
        services, we charge a Provider Fee when a booking is made by a User. The
        entire Provider Fee is deducted from the Provider payout, we do not
        charge the User any Fees. The Provider Fee is calculated as a percentage
        of the Listing Fee, and is currently set as a default of 10%.
      </p>
      <p>Example: </p>
      <p>
        A User pays a <strong className="text-black">Listing Fee</strong> to
        {updateAppName('Sporti')} to book a listing from a Provider. = $100
      </p>
      <p>
        {updateAppName('Sporti')} collects a <strong className="text-black">Provider Fee</strong>{' '}
        of 10%, deducted from the{' '}
        <strong className="text-black">Listing Fee</strong> ($100). = $10
      </p>
      <p>
        {updateAppName('Sporti')} makes a Payout to the Provider, which is the{' '}
        <strong className="text-black">Listing Fee</strong> less the{' '}
        <strong className="text-black">Provider Fee</strong> ($100-$10). = $90
      </p>
      <p>10.2 Payment Authorizations</p>
      <p>
        You authorize {updateAppName('Sporti')} Payments to collect from you amounts due pursuant
        to these Payment Terms or the {updateAppName('Sporti')} Terms. Specifically, you authorize
        {updateAppName('Sporti')} Payments to collect from you:
        <ul className="list-disc space-y-4 pl-6 my-2">
          <li>
            Any amount due to {updateAppName('Sporti')} (e.g., as a result of your bookings,
            Booking Modifications, cancellations, or other actions as a User,
            Co-Payer, Provider or user of the {updateAppName('Sporti')} Platform), including
            reimbursement for costs prepaid by {updateAppName('Sporti')} on your behalf, by
            charging any Payment Method on file in your {updateAppName('Sporti')} Account (unless
            you have previously removed the authorization to charge such Payment
            Method(s)) or by withholding such amounts from your future Payouts.
            Any funds collected by {updateAppName('Sporti')} Payments will set off the amount owed
            by you to {updateAppName('Sporti')} and extinguish your obligation to {updateAppName('Sporti')}.
          </li>
          <li>
            Any amount due to a Providing Customer from a Purchasing Customer
            which {updateAppName('Sporti')} collects as the Providing Customer’s payment collection
            agent as further set out in Section 9 above.
          </li>
          <li>Taxes, where applicable and as set out in the {updateAppName('Sporti')} Terms.</li>
          <li>
            Any amount you pay through the Resolution Center in connection with
            your {updateAppName('Sporti')} Account. {updateAppName('Sporti')} Payments may do so by charging the
            Payment Method associated with the relevant booking, or any other
            Payment Method on file in your {updateAppName('Sporti')} Account (unless you have
            previously removed the authorization to charge such Payment
            Method(s)), or by withholding the amount from your future Payouts.
          </li>
          <li>
            Overparticipate Fees payable under the {updateAppName('Sporti')} Terms. In addition,
            {updateAppName('Sporti')} Payments may recover any costs and expenses it incurs in
            collecting the Over Participate Fees by charging any Payment
            Method(s) you have on file in your {updateAppName('Sporti')} Account (unless you have
            previously removed the authorization to charge such Payment
            Method(s)).
          </li>
          <li>
            Any Service Fees or cancellation fees imposed pursuant to the {updateAppName('Sporti')}
            Terms (e.g., if, as a Provider, you cancel a confirmed booking).
            {updateAppName('Sporti')} Payments will be entitled to recover the amount of any such
            fees from you, including by subtracting such refund amount out from
            any future Payouts due to you.
          </li>
          <li>
            Fees improperly paid to you as a Provider. If, as a Provider, your
            User cancels a confirmed booking or {updateAppName('Sporti')} decides that it is
            necessary to cancel a confirmed booking, and {updateAppName('Sporti')} issues a refund
            to the User in accordance with the {updateAppName('Sporti')} Terms, User Refund Policy,
            Extenuating Circumstances Policy, or other applicable cancellation
            policy, you agree that in the event you have already been paid,
            {updateAppName('Sporti')} Payments will be entitled to recover the amount of any such
            refund from you, including by subtracting such refund amount out
            from any future Payouts due to you.
          </li>
          <li>
            Fees, costs and/or expenses associated with a Damage Claim,
            including any Security Deposit, as set out in the {updateAppName('Sporti')} Terms. If
            {updateAppName('Sporti')} Payments is unable to collect from your Payment Method used
            to make the booking, you agree that {updateAppName('Sporti')} Payments may charge any
            other Payment Method on file in your {updateAppName('Sporti')} Account at the time of
            the Damage Claim (unless you have previously removed the
            authorization to charge such Payment Method(s)). {updateAppName('Sporti')} Payments
            also reserves the right to otherwise collect payment from you and
            pursue any remedies available to {updateAppName('Sporti')} Payments in this regard in
            situations in which you are responsible for a Damage Claim pursuant
            to the {updateAppName('Sporti')} Terms, including, but not limited to, in relation to
            any payment requests made by Providers under the {updateAppName('Sporti')} Provider
            Guarantee.
          </li>
          <li>
            Fees payable by Co-Payers as part of the Group Payment Service by
            charging any Payment Method on file in your {updateAppName('Sporti')} Account (unless
            you have previously removed the authorization to charge such Payment
            Method(s)), or by withholding such amounts from your future Payouts.
            Any funds collected by {updateAppName('Sporti')} Payments will set off the amount owed
            by you to {updateAppName('Sporti')} and extinguish your obligation to {updateAppName('Sporti')}.
          </li>
        </ul>
        In addition to any amount due as outlined above, if there are delinquent
        amounts or chargebacks associated with your Payment Method, you may be
        charged fees that are incidental to our collection of these delinquent
        amounts and chargebacks. Such fees or charges may include collection
        fees, convenience fees or other third-party charges.
      </p>
      <p className="text-black">10.3 Refunds</p>
      <p>
        10.3.1 Any refunds or credits due to a Customer pursuant to the {updateAppName('Sporti')}
        Terms, Extenuating Circumstances Policy, User Refund Policy, will be
        initiated and remitted by {updateAppName('Sporti')} Payments in accordance with these
        Payments Terms.
      </p>
      <p>
        10.3.2 {updateAppName('Sporti')} Payments will process refunds immediately, however, the
        timing to receive any refund will vary based on the Payment Method and
        any applicable payment system (e.g., Visa, Mastercard, etc.) rules.
      </p>
      <p className="text-black">
        10.4 Recurring Payments and Alternative payment methods
      </p>
      <p>
        10.4.1 For certain bookings (such as for Sports Activities with a
        duration of twenty-eight (28) days days or more), {updateAppName('Sporti')} Payments may
        require a User to make recurring, incremental payments toward the Total
        Fees owed (<strong className="text-black">“Recurring Payments”</strong>
        ). More information on Recurring Payments (including the amount and the
        frequency of payments) will be made available via the {updateAppName('Sporti')} Platform if
        applicable to a booking.
      </p>
      <p>
        10.4.2 If Recurring Payments apply to a confirmed booking, then the User
        authorizes {updateAppName('Sporti')} Payments to collect the Total Fees, and the Provider
        agrees that {updateAppName('Sporti')} Payments will initiate Payouts, in the increments and
        at the frequency agreed to and identified via the {updateAppName('Sporti')} Platform.
      </p>
      <p>
        10.4.3 Users may stop payment of a Recurring Payment by notifying {updateAppName('Sporti')}
        Payments orally or in writing at least five (5) business days before the
        scheduled date of the payment. {updateAppName('Sporti')} Payments may require that you give
        written confirmation of a stop-payment order within fourteen (14) days
        of an oral notification. If you fail to provide written confirmation
        within fourteen (14) days as requested, {updateAppName('Sporti')} Payments is not obligated
        to honor your request to stop any future Recurring Payments. If you have
        any questions regarding your Recurring Payments, please contact {updateAppName('Sporti')}
        Payments pursuant to Section 28.
      </p>
      <p>
        10.4.4 {updateAppName('Sporti')} may partner with financial providers of alternative
        payment methods (such as consumer finance companies), to provide Users
        with alternative payment methods (such as buy now pay later installment
        plans). Such Companies offer flexible payment options including Buy Now,
        Pay Later. Once you’ve placed your order on {updateAppName('Sporti')} you will have the
        option to select an alternative payment method and be redirected to the
        financial providers website where you’ll be asked to provide payment
        details, and information to enable them to check your eligibility.
        {updateAppName('Sporti')} does not endorse or recommend any alternative payment provider or
        their products or services, and we are not responsible for the content
        or the acts or omissions of any alternative payment provider. Your use
        of any such provider’s payment method is at your own risk and will be
        governed by such provider’s terms and policies.
      </p>
      <p className="text-black">10.5 Payment Processing Errors</p>
      <p>
        We will take steps to rectify any payment processing errors that we
        become aware of. These steps may include crediting or debiting (as
        appropriate) the same Payout Method or Payment Method used for the
        original Payout to or payment by you, so that you end up receiving or
        paying the correct amount. This may be performed by {updateAppName('Sporti')} Payments or a
        third party such as your financial institution.
      </p>
      <p className="text-black">10.6 Collections</p>
      <p>
        10.6.1 If {updateAppName('Sporti')} Payments is unable to collect any amounts you owe under
        these Payments Terms, {updateAppName('Sporti')} Payments may engage in collection efforts
        to recover such amounts from you.
      </p>
      <p>
        10.6.2 {updateAppName('Sporti')} Payments will deem any owed amounts overdue when: (a) for
        authorized charges, one hundred and twenty (120) days have elapsed after
        {updateAppName('Sporti')} Payments first attempts to charge the Customer’s Payment Method
        or the associated services have been provided, whichever is later; and
        (b) for withholdings from a Provider’s future Payouts, two hundred and
        seventy (270) days have elapsed after the adjustment is made to the
        Provider’s account or the associated services have been provided,
        whichever is later.
      </p>
      <p>
        10.6.3 {updateAppName('Sporti')} Payments will deem any overdue amounts not collected to be
        in default when three hundred and sixty five (365) days have elapsed:
        (a) for authorized charges, after {updateAppName('Sporti')} Payments first attempts to
        charge the Customer’s Payment Method or the associated services have
        been provided, whichever is later; and (b) for withholdings from a
        Provider’s future Payouts, after the adjustment is made to the
        Provider’s account or the associated services have been provided,
        whichever is later.
      </p>
      <p>
        10.6.4 You hereby explicitly agree that all communication in relation to
        amounts owed will be made by electronic mail or by phone, as provided to
        {updateAppName('Sporti')} and/or {updateAppName('Sporti')} Payments by you. Such communication may be made by
        {updateAppName('Sporti')}, {updateAppName('Sporti')} Payments, or by anyone on their behalf, including but not
        limited to a third-party collection agent.
      </p>
    </div>
  );
};

export default GeneralFinTerms;
