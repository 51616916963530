import { updateAppName } from "../../utils/misc";

const CurrencyConvertion = () => {
  return (
    <p>
      {updateAppName('Sporti')} Payments will process each transaction in the currency the Customer
      selects via the {updateAppName('Sporti')} Platform. The currencies available to make and
      receive payments for any given transaction may be limited for regulatory
      or operational reasons based on factors such as the Customer’s selected
      Payment Method or Payout Method and/or the Customer’s country of residence
      or {updateAppName('Sporti')} contracting entity(ies). Any such limitations will be
      communicated via the {updateAppName('Sporti')} Platform, and you will be prompted to select a
      different currency, Payment Method or Payout Method. Note that a
      Customer’s third-party payment service provider may impose transaction,
      currency conversion or other fees based on the currency or Payment Method
      the Customer selects to make or receive payments, and {updateAppName('Sporti')} Payments is
      not responsible for any such fees and disclaims all liability in this
      regard.
    </p>
  );
};

export default CurrencyConvertion;
