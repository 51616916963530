import { updateAppName } from "../../utils/misc";

const Feedback = () => {
  return (
    <p>
      We welcome and encourage you to provide feedback, comments, and
      suggestions for improvements to the Payment Services (
      <span className="text-black">“Feedback”</span>). You may submit Feedback
      by emailing us, through the “Contact” section of the {updateAppName('Sporti')} Platform, or
      pursuant to Section 28 (
      <span className="text-black">“Contacting {updateAppName('Sporti')} Payments”</span>). Any
      Feedback you submit to us will be considered non-confidential and
      non-proprietary to you. By submitting Feedback to us, you grant us a
      non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
      perpetual license to use and publish those ideas and materials for any
      purpose, without compensation to you.
    </p>
  );
};

export default Feedback;
