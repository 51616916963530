import React from 'react';
import { updateAppName } from '../../utils/misc';

const Trust = () => {
  return (
    <ul className="space-y-2">
      <li>
        16.1 {updateAppName('Sporti')} offers a number of features that help build trust and
        cultivate a transparent community marketplace, including our secure
        messaging and payments system, reviews, ratings, customer services, and
        more. We have built and will continue to improve a trusted community, to
        help ensure our Customers safety, by relying on our Customers and our
        dedicated knowledgeable and experienced Support team to monitor any
        suspicious activity in our marketplace.
      </li>
      <li>
        16.2 Customers are encouraged to help our efforts by reporting
        suspicious or inappropriate activity on a profile, listing, or messages,
        that they believe threatens their safety or the safety of others, or
        violates our terms and policies.
      </li>
      <li>
        16.3 Our Support team investigates reports and may follow up for
        additional documentation. If needed, we'll also offer personalized,
        hands-on support. If someone has violated our terms or policies we will
        take corrective action. This can include warnings, suspension, or
        account removal, however, for privacy reasons, we're unable to share the
        outcomes of these investigations.
      </li>
      <li>
        16.4 To maintain the highest possible levels of safety for our
        Customers, all communication and information shared should take place on
        our secure platform throughout the entire process. If you are asked to
        communicate or provide alternative means of communication, or provide
        payments outside of {updateAppName('Sporti')}, Customers are required to report it to our
        Support team immediately.
      </li>
    </ul>
  );
};

export default Trust;
