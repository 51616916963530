import React from 'react';
import { updateAppName } from '../../utils/misc';

const Indemnification = () => {
  return (
    <p>
      To the maximum extent permitted by applicable law, you agree to release,
      defend (at {updateAppName('Sporti')}'s option), indemnify, and hold {updateAppName('Sporti')} and its affiliates
      and subsidiaries, including but not limited to, {updateAppName('Sporti')} Payments, and their
      officers, directors, employees and agents, harmless from and against any
      claims, liabilities, damages, losses, and expenses, including, without
      limitation, reasonable legal and accounting fees, arising out of or in any
      way connected with (i) your breach of these Terms or our Policies or
      Standards, (ii) your improper use of the {updateAppName('Sporti')} Platform or any {updateAppName('Sporti')}
      Services, (iii) your interaction with any Customer, participate at an
      Sports Activities, or other Provider Service, participation in the Group
      Payment Service, including without limitation any injuries, losses or
      damages (whether compensatory, direct, incidental, consequential or
      otherwise) of any kind arising in connection with or as a result of such
      interaction, participate, participation or use, (iv) {updateAppName('Sporti')}'s Collection
      and Remittance of Taxes, or (v) your breach of any laws, regulations or
      third party rights.
    </p>
  );
};

export default Indemnification;
