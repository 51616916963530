export const getSlug = (text: string) => {
  let replaced = text.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();

  if (/[^a-zA-Z0-9]/.test(replaced[replaced.length - 1])) {
    return replaced.slice(0, -1);
  } else {
    return replaced;
  }
};

export const updateAppName = (str: string) => {
  if (str && process.env.REACT_APP_NAME) {
  let fStr = str;
  fStr = fStr.replaceAll('Sporti', process.env.REACT_APP_NAME);
  fStr = fStr.replaceAll('sporti', process.env.REACT_APP_NAME.toLowerCase());
  return fStr;
}  else return str;
}
