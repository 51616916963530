import { updateAppName } from "../../utils/misc";

const Termination = () => {
  return (
    <div className="space-y-4">
      <p>
        20.1 This Agreement will continue unless and until it is terminated,
        suspended or other measures are taken as described in this Section 20.
      </p>
      <p>
        20.2 You may terminate this Agreement at any time by sending us an
        email, or by following the termination procedures specified in the
        {updateAppName('Sporti')} Terms. Terminating this Agreement will also serve as notice to
        cancel your {updateAppName('Sporti')} Account pursuant to the {updateAppName('Sporti')} Terms. If you cancel
        your {updateAppName('Sporti')} Account as a Provider, {updateAppName('Sporti')} Payments will provide a full
        refund to any Users with confirmed booking(s). If you cancel your {updateAppName('Sporti')}
        Account as a User, {updateAppName('Sporti')} Payments will initiate a refund for any
        confirmed booking(s) based on the Listing’s cancellation policy.
      </p>
      <p>
        20.3 Without limiting our rights specified below, {updateAppName('Sporti')} Payments may
        terminate this Agreement for convenience at any time by giving you
        thirty (30) days' notice via email to your registered email address.
      </p>
      <p>
        20.4 {updateAppName('Sporti')} Payments may immediately, without notice terminate this
        Agreement if (i) you have materially breached your obligations under
        this Agreement; (ii) you have provided inaccurate, fraudulent, outdated,
        or incomplete information; (iii) you have violated applicable laws,
        regulations, or third-party rights; or (iv) {updateAppName('Sporti')} Payments believes in
        good faith that such action is reasonably necessary to protect other
        Customers, {updateAppName('Sporti')}, {updateAppName('Sporti')} Payments, or third parties (for example in the
        case of fraudulent behavior of a Customer).
      </p>
      <p>
        20.5 In addition, {updateAppName('Sporti')} Payments may limit or temporarily or
        permanently suspend your use of or access to the Payment Services (i) to
        comply with applicable law, or the order or request of a court, law
        enforcement, or other administrative agency or governmental body, or if
        (ii) you have breached these Payments Terms, the {updateAppName('Sporti')} Terms,
        applicable laws, regulations or third-party rights, (iii) you have
        provided inaccurate, fraudulent, outdated, or incomplete information
        regarding a Payment Method or Payout Method, (iv) any amounts you owe
        under these Payments Term are overdue or in default, or (v) {updateAppName('Sporti')}
        Payments believes in good faith that such action is reasonably necessary
        to protect the personal safety or property of {updateAppName('Sporti')}, its Customers,
        {updateAppName('Sporti')} Payments, or third parties, or to prevent fraud or other illegal
        activity.
      </p>
      <p>
        20.6 In case of non-material breaches and where appropriate, you will be
        given notice of any measure by {updateAppName('Sporti')} Payments and an opportunity to
        resolve the issue to {updateAppName('Sporti')} Payments' reasonable satisfaction.
      </p>
      <p>
        20.7 If {updateAppName('Sporti')} Payments takes any of the measures described in this
        Section you may appeal such a decision by contacting customer service.
      </p>
      <p>
        20.8 If you are a Provider and we take any of the measures described in
        this Section we may refund your Users in full for any and all confirmed
        bookings, irrespective of preexisting cancellation policies, and you
        will not be entitled to any compensation for pending or confirmed
        bookings that were canceled.
      </p>
      <p>
        20.9 If your access to or use of the Payment Services has been limited
        or this Agreement has been terminated by us, you may not register a new
        {updateAppName('Sporti')} Account or attempt to access and use the Payment Services through
        another {updateAppName('Sporti')} Account of another Customer.
      </p>
      <p>
        20.10 Unless your country of residence is in the EEA, Sections 10 and 15
        to 25 of these Payments Terms shall survive any termination or
        expiration of this Agreement.
      </p>
    </div>
  );
};

export default Termination;
