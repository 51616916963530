import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import { ReactComponent as DollarIcon } from '../../assets/icons/dollar-icon.svg';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/stars.svg';
import { ReactComponent as SecurityIcon } from '../../assets/icons/security.svg';
import { ReactComponent as AboutIcon } from '../../assets/icons/about.svg';
import { TTopic } from '../../store/type';

const getInfo = (name: string) => {
  switch (name) {
    case 'Searching & Bookings':
      return {
        icon: <SearchIcon />,
      };
    case 'Paying and Refunds':
      return {
        icon: <DollarIcon />,
      };
    case 'Your Account':
      return {
        icon: <AvatarIcon />,
      };
    case 'Reviews and Ratings':
      return {
        icon: <StarIcon />,
      };
    case 'Trust and Safety':
      return {
        icon: <SecurityIcon />,
      };
    case 'About Sporti':
      return {
        icon: <AboutIcon />,
      };
    case 'About Being a Provider':
      return {
        icon: <AboutIcon />,
      };
    default:
      return {
        icon: <SearchIcon />,
      };
  }
};

type TopicProps = {
  topics: TTopic[];
  activityPath: string;
};

const TopicCards = ({ topics, activityPath }: TopicProps) => {
  return (
    <div className="py-10 px-4">
      <h3 className="text-3xl font-bold tracking-tightest leading-10 mb-7">
        Browse Topics
      </h3>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-11">
        {topics.map(({ name, id }, index) => {
          const { icon } = getInfo(name);
          return (
            <a
              key={index}
              href={`/${activityPath}/${id}`}
              className="bg-white border border-[#15488F27] hover:border-[#2C99C665] rounded-xl py-7 px-8 hover:shadow-lg transition-all">
              <span className="text-2xl font-medium flex gap-4 items-center">
                <span className="flex items-center justify-center w-8 h-8">
                  {icon}
                </span>
                {name.replaceAll('Sporti', process.env.REACT_APP_NAME || 'Sporti')}
              </span>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default TopicCards;
