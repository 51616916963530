import React from 'react';
import { updateAppName } from '../../utils/misc';

const OtherImportant = () => {
  return (
    <>
      <p className="my-6">
        <strong className="text-black">
          5.1 Analyzing your Communications.
        </strong>{' '}
        <br />
        We may review, scan, or analyze your communications on the {updateAppName('Sporti')}
        Platform for fraud prevention, risk assessment, regulatory compliance,
        investigation, product development, research, analytics, and customer
        support purposes. For example, as part of our fraud prevention efforts,
        we scan and analyze messages to mask contact information and references
        to other websites. In some cases, we may also scan, review, or analyze
        messages to debug, improve, and expand product offerings. We use
        automated methods where reasonably possible. However, occasionally we
        may need to manually review some communications, such as for fraud
        investigations and customer support, or to assess and improve the
        functionality of these automated tools. We will not review, scan, or
        analyze your messaging communications to send third party marketing
        messages to you, and we will not sell reviews or analyses of these
        communications. <br />
        These activities are carried out based on {updateAppName('Sporti')}'s legitimate interest
        in ensuring compliance with applicable laws and our Terms, preventing
        fraud, promoting safety, and improving and ensuring the adequate
        performance of our services.
      </p>
      <p className="my-6">
        <strong className="text-black">
          5.2 Linking Third Party Accounts.{' '}
        </strong>{' '}
        <br />
        You may link your {updateAppName('Sporti')} Account with your account at a third party
        social networking service. Your contacts on these third party services
        are referred to as “Friends.” When you create this link: <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            some of the information you provide to us from the linking of your
            accounts may be published on your {updateAppName('Sporti')} Account profile;
          </li>
          <li>
            your activities on the {updateAppName('Sporti')} Platform may be displayed to your
            Friends on the {updateAppName('Sporti')} Platform and/or that third party site;
          </li>
          <li>
            a link to your public profile on that third party social networking
            service may be included in your {updateAppName('Sporti')} public profile;
          </li>
          <li>
            other {updateAppName('Sporti')} users may be able to see any common Friends that you
            may have with them, or that you are a Friend of their Friend if
            applicable;
          </li>
          <li>
            other {updateAppName('Sporti')} users may be able to see any schools, hometowns or
            other groups you have in common with them as listed on your linked
            social networking service;
          </li>
          <li>
            the information you provide to us from the linking of your accounts
            may be stored, processed and transmitted for fraud prevention and
            risk assessment purposes; and
          </li>
          <li>
            the publication and display of information that you provide to
            {updateAppName('Sporti')} through this linkage is subject to your settings and
            authorizations on the {updateAppName('Sporti')} Platform and the third party site.
          </li>
        </ul>
        We only collect your information from linked third party accounts to the
        extent necessary to ensure the adequate performance of our contract with
        you, or to ensure that we comply with applicable laws, or with your
        consent.
      </p>
      <p className="my-6">
        <strong className="text-black">
          5.3 Third Party Partners & Integrations{' '}
        </strong>
        <br /> The {updateAppName('Sporti')} Platform may contain links to third party websites or
        services, such as third party integrations, co-branded services, or
        third party-branded services (“Third Party Partners”). {updateAppName('Sporti')} doesn’t
        own or control these Third Party Partners and when you interact with
        them, you may be providing information directly to the Third Party
        Partner, {updateAppName('Sporti')}, or both. These Third Party Partners will have their own
        rules about the collection, use, and disclosure of information. We
        encourage you to review the privacy policies of the other websites you
        visit. <br />
        Parts of the {updateAppName('Sporti')} Platform may use third party services such as Google
        Maps/Earth services, including the Google Maps API(s), and Stripe for
        Payments. Use of these respective services is subject to their privacy
        policies.
      </p>
    </>
  );
};

export default OtherImportant;
