import { updateAppName } from "../../utils/misc";

const IntellectualProperty = () => {
  return (
    <div className="space-y-4">
      <p>
        15.1 The Payment Services are protected by copyright, trademark, and
        other laws of the United States and foreign countries. You acknowledge
        and agree that the Payment Services, including all associated
        intellectual property rights, are the exclusive property of {updateAppName('Sporti')},
        {updateAppName('Sporti')} Payments and its licensors. You will not remove, alter or obscure
        any copyright, trademark, service mark or other proprietary rights
        notices incorporated in or accompanying the Payment Services. All
        trademarks, service marks, logos, trade names, and any other proprietary
        designations of {updateAppName('Sporti')} or {updateAppName('Sporti')} Payments used on or in connection with
        the Payment Services are trademarks or registered trademarks of {updateAppName('Sporti')}
        or {updateAppName('Sporti')} Payments in the United States and abroad. Trademarks, service
        marks, logos, trade names, and any other proprietary designations of
        third parties used on or in connection with Payment Services are used
        for identification purposes only and may be the property of their
        respective owners.
      </p>
      <p>
        15.2 You will not use, copy, adapt, modify, prepare derivative works
        based upon, distribute, license, sell, transfer, publicly display,
        publicly perform, transmit, broadcast, or otherwise exploit the Payment
        Services, except as expressly permitted in these Payments Terms. No
        licenses or rights are granted to you by implication or otherwise under
        any intellectual property rights owned or controlled by {updateAppName('Sporti')}, {updateAppName('Sporti')}
        Payments, or its licensors, except for the licenses and rights expressly
        granted in these Payments Terms.
      </p>
    </div>
  );
};

export default IntellectualProperty;
