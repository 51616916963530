import React from 'react';
import { updateAppName } from '../../utils/misc';

const Liability = () => {
  return (
    <p>
      You acknowledge and agree that, to the maximum extent permitted by law,
      the entire risk arising out of your access to and use of the {updateAppName('Sporti')}
      Platform and Collective Content, your publishing or booking of any Listing
      via the {updateAppName('Sporti')} Platform, your participate at any Sports Activities, or use
      of any other Provider Service, participation in the Group Payment Service,
      or any other interaction you have with other Customers whether in person
      or online remains with you. Neither {updateAppName('Sporti')} nor any other party involved in
      creating, producing, or delivering the {updateAppName('Sporti')} Platform or Collective
      Content will be liable for any incidental, special, exemplary or
      consequential damages, including lost profits, loss of data or loss of
      goodwill, service interruption, computer damage or system failure or the
      cost of substitute products or services, or for any damages for personal
      or bodily injury or emotional distress arising out of or in connection
      with (i) these Terms, (ii) from the use of or inability to use the {updateAppName('Sporti')}
      Platform or Collective Content, (iii) from any communications,
      interactions or meetings with other Customers or other persons with whom
      you communicate, interact or meet with as a result of your use of the
      {updateAppName('Sporti')} Platform, or (iv) from your publishing or booking of a Listing,
      including the provision or use of a Listing’s Provider Services, whether
      based on warranty, contract, tort (including negligence), product
      liability or any other legal theory, and whether or not {updateAppName('Sporti')} has been
      informed of the possibility of such damage, even if a limited remedy set
      forth herein is found to have failed of its essential purpose. Except for
      our obligations to pay amounts to applicable Providers pursuant to these
      Terms or an approved payment request under the {updateAppName('Sporti')} Provider Guarantee
      or Japan Provider Insurance, in no event will {updateAppName('Sporti')}’s aggregate liability
      arising out of or in connection with these Terms and your use of the
      {updateAppName('Sporti')} Platform including, but not limited to, from your publishing or
      booking of any Listings via the {updateAppName('Sporti')} Platform, or from the use of or
      inability to use the {updateAppName('Sporti')} Platform or Collective Content and in
      connection with any Sports Activities, Experiences, Event,other Provider
      Service, the Group Payment Service, or interactions with any other
      Customers, exceed the amounts you have paid or owe for bookings via the
      {updateAppName('Sporti')} Platform as a User in the twelve (12) month period prior to the
      event giving rise to the liability, or if you are a Provider, the amounts
      paid by {updateAppName('Sporti')} to you in the twelve (12) month period prior to the event
      giving rise to the liability, or one hundred U.S. dollars (US$100), if no
      such payments have been made, as applicable. The limitations of damages
      set forth above are fundamental elements of the basis of the bargain
      between {updateAppName('Sporti')} and you. Some jurisdictions do not allow the exclusion or
      limitation of liability for consequential or incidental damages, so the
      above limitation may not apply to you. If you reside outside of the U.S.,
      this does not affect {updateAppName('Sporti')}’s liability for death or personal injury
      arising from its negligence, nor for fraudulent misrepresentation,
      misrepresentation as to a fundamental matter or any other liability which
      cannot be excluded or limited under applicable law.
    </p>
  );
};

export default Liability;
