import React from 'react';
import { updateAppName } from '../../utils/misc';

const Prohibited = () => {
  return (
    <ul className="space-y-2">
      <li>
        14.1 You are solely responsible for compliance with any and all laws,
        rules, regulations, and Tax obligations that may apply to your use of
        the {updateAppName('Sporti')} Platform. In connection with your use of the {updateAppName('Sporti')} Platform,
        you will not and will not assist or enable others to:
        <ul className="list-disc">
          <li className="ml-8">
            breach or circumvent any applicable laws or regulations, agreements
            with third-parties, third-party rights, or our Terms, Policies or
            Standards;
          </li>
          <li className="ml-8">
            use the {updateAppName('Sporti')} Platform or Collective Content for any commercial or
            other purposes that are not expressly permitted by these Terms or in
            a manner that falsely implies {updateAppName('Sporti')} endorsement, partnership or
            otherwise misleads others as to your affiliation with {updateAppName('Sporti')};
          </li>
          <li className="ml-8">
            copy, store or otherwise access or use any information, including
            personally identifiable information about any other Customer,
            contained on the {updateAppName('Sporti')} Platform in any way that is inconsistent
            with {updateAppName('Sporti')}’s Privacy Policy or these Terms or that otherwise
            violates the privacy rights of Customers or third parties;
          </li>
          <li className="ml-8">
            use the {updateAppName('Sporti')} Platform in connection with the distribution of
            unsolicited commercial messages ("spam");
          </li>
          <li className="ml-8">
            offer, as a Provider, any Sports Activities that you do not yourself
            own or have permission to make available through the {updateAppName('Sporti')}
            Platform;
          </li>
          <li className="ml-8">
            unless {updateAppName('Sporti')} explicitly permits otherwise, book any Listing if you
            will not actually be using the Provider Services yourself;
          </li>
          <li className="ml-8">
            contact another Customer for any purpose other than asking a
            question related to a your own booking, Listing, or the Customer's
            use of the {updateAppName('Sporti')} Platform, including, but not limited to,
            recruiting or otherwise soliciting any Customer to join third-party
            services, applications or websites, without our prior written
            approval;
          </li>
          <li className="ml-8">
            use the {updateAppName('Sporti')} Platform to request, make or accept a booking
            independent of the {updateAppName('Sporti')} Platform, to circumvent any Service Fees
            or for any other reason;
          </li>
          <li className="ml-8">
            request, accept or make any payment for Listing Fees outside of the
            {updateAppName('Sporti')} Platform or {updateAppName('Sporti')} Payments. If you do so, you acknowledge
            and agree that you: (i) would be in breach of these Terms; (ii)
            accept all risks and responsibility for such payment, and (iii) hold
            {updateAppName('Sporti')} harmless from any liability for such payment;
          </li>
          <li className="ml-8">
            discriminate against or harass anyone on the basis of race, national
            origin, religion, gender, gender identity, physical or mental
            disability, medical condition, marital status, age or sexual
            orientation, or otherwise engage in any violent, harmful, abusive or
            disruptive behavior;
          </li>
          <li className="ml-8">
            misuse or abuse any Listings or services associated with the {updateAppName('Sporti')}
            service as determined by {updateAppName('Sporti')} in its sole discretion.
          </li>
          <li className="ml-8">
            use, display, mirror or frame the {updateAppName('Sporti')} Platform or Collective
            Content, or any individual element within the {updateAppName('Sporti')} Platform,
            {updateAppName('Sporti')}'s name, any {updateAppName('Sporti')} trademark, logo or other proprietary
            information, or the layout and design of any page or form contained
            on a page in the {updateAppName('Sporti')} Platform, without {updateAppName('Sporti')}'s express written
            consent;
          </li>
          <li className="ml-8">
            dilute, tarnish or otherwise harm the {updateAppName('Sporti')} brand in any way,
            including through unauthorized use of Collective Content,
            registering and/or using {updateAppName('Sporti')} or derivative terms in domain names,
            trade names, trademarks or other source identifiers, or registering
            and/or using domains names, trade names, trademarks or other source
            identifiers that closely imitate or are confusingly similar to
            {updateAppName('Sporti')} domains, trademarks, taglines, promotional campaigns or
            Collective Content;
          </li>
          <li className="ml-8">
            use any robots, spider, crawler, scraper or other automated means or
            processes to access, collect data or other content from or otherwise
            interact with the {updateAppName('Sporti')} Platform for any purpose;
          </li>
          <li className="ml-8">
            avoid, bypass, remove, deactivate, impair, descramble, or otherwise
            attempt to circumvent any technological measure implemented by
            {updateAppName('Sporti')} or any of {updateAppName('Sporti')}'s providers or any other third party to
            protect the {updateAppName('Sporti')} Platform;
          </li>
          <li className="ml-8">
            attempt to decipher, decompile, disassemble or reverse engineer any
            of the software used to provide the {updateAppName('Sporti')} Platform;
          </li>
          <li className="ml-8">
            take any action that damages or adversely affects, or could damage
            or adversely affect the performance or proper functioning of the
            {updateAppName('Sporti')} Platform;
          </li>
          <li className="ml-8">
            export, re-export, import, or transfer the Application except as
            authorized by United States law, the export control laws of your
            jurisdiction, and any other applicable laws; or
          </li>
          <li className="ml-8">
            violate or infringe anyone else’s rights or otherwise cause harm to
            anyone.
          </li>
        </ul>
      </li>
      <li>
        14.2 You acknowledge that {updateAppName('Sporti')} has no obligation to monitor the access
        to or use of the {updateAppName('Sporti')} Platform by any Customer or to review, disable
        access to, or edit any Customer Content, but has the right to do so to
        (i) operate, secure and improve the {updateAppName('Sporti')} Platform (including without
        limitation for fraud prevention, risk assessment, investigation and
        customer support purposes); (ii) ensure Customers’ compliance with these
        Terms; (iii) comply with applicable law or the order or requirement of a
        court, law enforcement or other administrative agency or governmental
        body; (iv) respond to Customer Content that it determines is harmful or
        objectionable; or (v) as otherwise set forth in these Terms. Customers
        agree to cooperate with and assist {updateAppName('Sporti')} in good faith, and to provide
        {updateAppName('Sporti')} with such information and take such actions as may be reasonably
        requested by {updateAppName('Sporti')} with respect to any investigation undertaken by
        {updateAppName('Sporti')} or a representative of {updateAppName('Sporti')} regarding the use or abuse of the
        {updateAppName('Sporti')} Platform.
      </li>
      <li>
        14.3 If you feel that any Customer you interact with, whether online or
        in person, is acting or has acted inappropriately, including but not
        limited to anyone who (i) engages in offensive, violent or sexually
        inappropriate behavior, (ii) you suspect of stealing from you, or (iii)
        engages in any other disturbing conduct, you should immediately report
        such person to the appropriate authorities and then to {updateAppName('Sporti')} by
        contacting us with your police station and report number (if available).
        You agree that any report you make will not obligate us to take any
        action (beyond that required by law, if any).
      </li>
    </ul>
  );
};

export default Prohibited;
