import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
  title: string;
};

const TextWrapper = ({ children, title }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <button
        onClick={handleButtonClick}
        className="bg-white p-4 rounded-lg w-full text-start">
        <span className="text-2xl  text-black">{title}</span>
        {isOpen ? <div className="py-4">{children}</div> : null}
      </button>
    </>
  );
};

export default TextWrapper;
