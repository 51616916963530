import React from 'react';
import { updateAppName } from '../../utils/misc';

const Disclaimers = () => {
  return (
    <p>
      If you choose to use the {updateAppName('Sporti')} Platform or Collective Content, you do so
      voluntarily and at your sole risk. The {updateAppName('Sporti')} Platform and Collective
      Content is provided “as is”, without warranty of any kind, either express
      or implied. <br />
      You agree that you have had whatever opportunity you deem necessary to
      investigate the {updateAppName('Sporti')} Services, laws, rules, or regulations that may be
      applicable to your Listings and/or Provider Services you are receiving and
      that you are not relying upon any statement of law or fact made by {updateAppName('Sporti')}
      relating to a Listing.
      <br />
      If we choose to conduct identity verification or background checks on any
      Customer, to the extent permitted by applicable law, we disclaim
      warranties of any kind, either express or implied, that such checks will
      identify prior misconduct by a Customer or guarantee that a Customer will
      not engage in misconduct in the future.
      <br />
      You agree that some Experiences, Events, other Provider Services, or the
      Group Payment Service may carry inherent risk, and by participating in
      such services, you choose to assume those risks voluntarily. For example,
      some Provider Services may carry risk of illness, bodily injury,
      disability, or death, and you freely and willfully assume those risks by
      choosing to participate in those Provider Services. You assume full
      responsibility for the choices you make before, during and after your
      participation in a Provider Service or the Group Payment Service. If you
      are bringing a minor as an additional User, you are solely responsible for
      the supervision of that minor throughout the duration of your Provider
      Service and to the maximum extent permitted by law, you agree to release
      and hold harmless {updateAppName('Sporti')} from all liabilities and claims that arise in any
      way from any injury, death, loss or harm that occurs to that minor during
      the Provider Service or in any way related to your Provider Service.
      <br />
      The foregoing disclaimers apply to the maximum extent permitted by law.
      You may have other statutory rights. However, the duration of statutorily
      required warranties, if any, shall be limited to the maximum extent
      permitted by law.
    </p>
  );
};

export default Disclaimers;
