import React from 'react';
import { updateAppName } from '../../utils/misc';

const UserTerms = () => {
  return (
    <ul className="space-y-2">
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          8.1 Terms applicable to all bookings
        </h3>
        <ul className="space-y-2">
          <li className="ml-4">
            8.1.1 Subject to meeting any requirements (such as completing any
            verification processes) set by {updateAppName('Sporti')} and/or the Provider, you can
            book a Listing available on the {updateAppName('Sporti')} Platform by following the
            respective booking process. All applicable fees, including the
            Listing Fee, Security Deposit (if applicable), User Fee and any
            applicable Taxes (collectively, <strong>“Total Fees”</strong>) will
            be presented to you prior to booking a Listing. You agree to pay the
            Total Fees for any booking requested in connection with your {updateAppName('Sporti')}
            Account.
          </li>
          <li className="ml-4">
            8.1.2 Upon receipt of a booking confirmation from {updateAppName('Sporti')}, a legally
            binding agreement is formed between you and your Provider, subject
            to any additional Terms of the Provider that apply, including in
            particular the applicable cancellation policy and any rules and
            restrictions specified in the Listing. {updateAppName('Sporti')} Payments will collect
            the Total Fees at the time of the booking request or upon the
            Provider’s confirmation pursuant to the Payments Terms. For certain
            bookings, Users may be required to pay or have the option to pay in
            multiple installments.
          </li>
          <li className="ml-4">
            8.1.3 If you book a Provider Service on behalf of additional Users
            (who must be your child), you are required to ensure that every
            additional User meets any requirements set by the Provider, and is
            made aware of and agrees to these Terms and any Terms , rules and
            restrictions set by the Provider. If you are booking for an
            additional User (who must be your child), you represent and warrant
            that you are legally authorized to act on behalf of the minor.
          </li>
          <li className="ml-4">
            8.1.4 {updateAppName('Sporti')} may enable a function for a User who is booking a
            Listing on behalf of one or more additional Users (the{' '}
            <strong>“Organizer”</strong>) to split the payment of the Total Fees
            for an eligible booking on a pro-rata basis between the Organizer
            and at least one other additional User (each a{' '}
            <strong>“Co-Payer”</strong>) (the{' '}
            <strong>“Group Payment Service”</strong>). In order to participate
            in the Group Payment Service, each Co-Payer must have or register an
            {updateAppName('Sporti')} Account prior to making a payment. All payments via the Group
            Payment Service are handled by {updateAppName('Sporti')} Payments and are subject to
            the Group Payment Terms of Service. This function may or may not be
            available at this time.
          </li>
        </ul>
      </li>
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          8.2 Booking Sports Activities
        </h3>
        <ul className="space-y-2">
          <li className="ml-4">
            8.2.1 You understand that a confirmed booking of an Sports
            Activities (<strong>“Sports Activities Booking”</strong>) is a
            limited license granted to you by the Provider to participate in the
            Sports Activities for the duration of your booking, during which
            time the Provider (only where and to the extent permitted by
            applicable law) retains the right to make minor revisions to the
            Sports Activities, in accordance with your agreement with the
            Provider.
          </li>
          <li className="ml-4">
            8.2.2 You agree to participate in the Sports Activities in
            accordance specified in the Listing or such other specifications as
            mutually agreed upon between you and the Provider after the booking
            is confirmed.
          </li>
          <li className="ml-4">
            8.2.3 You should carefully review the description of any Service,
            Event or Facility you intend to book to ensure you (and any
            additional Users you are booking for) meet any minimum age,
            proficiency, fitness or other requirements which the Provider has
            specified in their Listing. At your sole discretion you may want to
            inform the Provider of any medical or physical conditions, or other
            circumstances that may impact your and any additional User’s ability
            to participate. You are responsible for identifying, understanding,
            and complying with all laws, rules and regulations that apply to
            your participation in a Service, Event, Facility or other Provider
            Service.
          </li>
          <li className="ml-4">
            8.2.4 Before and during a Sports Activity or other Provider Service
            you must at all times adhere to the Providers’ guidelines, rules and
            instructions.
          </li>
          <li className="ml-4">
            8.2.5 You may not bring any additional individuals to participate in
            a Sports Activity or other Provider Service, unless {updateAppName('Sporti')} has
            enabled the function “Add additional Users”, and such an individual
            was added by you as an additional User during the booking process on
            the {updateAppName('Sporti')} Platform.
          </li>
        </ul>
      </li>
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          8.3 Booking Modifications
        </h3>
        <p>
          Providers and Users are responsible for any Booking Modifications they
          agree to make via the {updateAppName('Sporti')} Platform or direct {updateAppName('Sporti')} customer
          service to make on their behalf, and agree to pay any additional
          amounts, fees or taxes associated with a Booking Modification.
        </p>
      </li>
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          8.4 {updateAppName('Sporti')} Sporty Club Points
        </h3>
        <p>
          {updateAppName('Sporti')} Sporty Club Points may be redeemed for eligible bookings via
          the {updateAppName('Sporti')} Platform as specified in the Terms . You may only redeem
          {updateAppName('Sporti')} Sporty Club Points after they are reflected in your {updateAppName('Sporti')}
          Account.
        </p>
      </li>
    </ul>
  );
};

export default UserTerms;
