import { createContext, useContext } from 'react';

export const initContext = {
  baseUrl: process.env.REACT_APP_BASE_URL || '',
};

// We have only the baseUrl here for now but we might need something else later.
export interface SporforyaContextInfo {
  baseUrl: string;
}

export const SporforyaContext =
  createContext<SporforyaContextInfo>(initContext);

export const useSporforyaContext = () => useContext(SporforyaContext);
