import React from 'react';
import { updateAppName } from '../../utils/misc';

const Provider = () => {
  return (
    <ul className="space-y-2">
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          7.1 Terms applicable to all Listings
        </h3>
        <ul className="space-y-2">
          <li className="ml-4">
            7.1.1 When creating a Listing through the {updateAppName('Sporti')} Platform you must
            (i) provide complete and accurate information about your Provider
            Service (such as listing description, location, and availability),
            (ii) disclose any equipment, deficiencies, restrictions (such as
            specific rules) and requirements that apply (such as any minimum
            age, proficiency, health or fitness requirements) and (iii) provide
            any other pertinent information requested by {updateAppName('Sporti')}. You are
            responsible for keeping your Listing information (including
            availability) up-to-date at all times.
          </li>
          <li className="ml-4">
            7.1.2 You are solely responsible for setting a price (including any
            Taxes if applicable, or any charges for your Listing{' '}
            <strong>(“Listing Fee”)</strong>. Once a User requests a booking of
            your Listing, you may not request that the User pays a higher price
            than in the booking request.
          </li>
          <li className="ml-4">
            7.1.3 Any Terms included in your Listing, in particular in relation
            to cancellations, must not conflict with these Terms or the relevant
            cancellation policy for your Listing.
          </li>
          <li className="ml-4">
            7.1.4 {updateAppName('Sporti')} may encourage Providers to participate in its
            partnership programs for the disabled and handicapped.
          </li>
          <li className="ml-4">
            7.1.5 Pictures, animations or videos (collectively,{' '}
            <strong>"Images"</strong>) used in your Listings must accurately
            reflect the quality and condition of your Provider Services. {updateAppName('Sporti')}
            reserves the right to require that Listings have a minimum number of
            Images of a certain format, size and resolution.
          </li>
          <li className="ml-4">
            7.1.6 The placement and ranking of Listings in search results on the
            {updateAppName('Sporti')} Platform may vary and depend on a variety of factors, such as
            User search parameters and preferences, Provider requirements, price
            and availability, number and quality of Images, customer service and
            cancellation history, Reviews and Ratings, type of Provider Service,
            and/or ease of booking.
          </li>
          <li className="ml-4">
            7.1.7 When you receive a booking from a User, you are entering into
            a legally binding agreement with the User and are required to
            provide your Provider Service(s) to the User as described in your
            Listing when the booking request is made. You also agree to pay the
            applicable Provider Fee and any applicable Taxes.
          </li>
          <li className="ml-4">
            7.1.8 {updateAppName('Sporti')} recommends that Providers obtain appropriate insurance
            for their Provider Services. Please review any respective insurance
            policy carefully, and in particular make sure that you are familiar
            with and understand any exclusions to, and any deductibles that may
            apply for, such insurance policy, including, but not limited to,
            whether or not your insurance policy will cover the actions or
            inactions of Users (and the individuals the User has booked for, if
            applicable) while participating at your Sports Activities or
            participating in your Event or other Provider Service.
          </li>
        </ul>
      </li>
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          7.2 Listing Sports Activities
        </h3>
        <ul className="space-y-2">
          <li className="ml-4">
            7.2.1 Unless expressly allowed by {updateAppName('Sporti')}, you may not list more than
            one Sports Activities per Listing. Variations of a listing are
            permitted, such as different locations, times, dates, ages, levels,
            etc… Duplicate listings are not permitted. A listing identified or
            reported as a duplicate listing will be removed, and if such
            duplicate listing was deemed as intentional, all listings may be
            removed.
          </li>
          <li className="ml-4">
            7.2.2 If you choose to require a security deposit for your Sports
            Activities, you must specify this in your Listing . Providers are
            not allowed to ask for a Security Deposit (i) after a booking has
            been confirmed or (ii) outside of the {updateAppName('Sporti')} Platform.
          </li>
          <li className="ml-4">
            7.2.3 You represent and warrant that any Listing you post and the
            booking of, or a User's participation in or at a Sports Activities
            will (i) not breach any agreements you have entered into with any
            third parties, such as local regulations, facility provider rules
            and policies, or other agreements, and (ii) comply with all
            applicable laws (such as permits), and other rules and regulations
            (including having all required permits, licenses and registrations).
            As a Provider, you are responsible for your own acts and omissions
            and are also responsible for the acts and omissions of any
            individuals who are involved in, or are otherwise present at the
            Sports Activities at your request or invitation, excluding the User
            and any individuals the User invites to the Sports Activities.
          </li>
        </ul>
      </li>
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          7.3 Booking Modifications
        </h3>
        <p>
          Providers and Users are responsible for any Booking Modifications they
          agree to make via the {updateAppName('Sporti')} Platform or direct {updateAppName('Sporti')} customer
          service to make on their behalf, and agree to pay any additional
          amounts, fees or taxes associated with a Booking Modification.
        </p>
      </li>
      <li>
        <h3 className="text-black font-extrabold my-2 text-lg">
          7.4 Co-Providers
        </h3>
        <ul className="space-y-2">
          <li className="ml-4">
            7.4.1 {updateAppName('Sporti')} may enable Providers to authorize other Customers (
            <strong>“Co-Providers”</strong>) to administer the Provider’s
            Listing(s), and to bind the Provider and take certain actions in
            relation to the Listing(s) as permitted by the Provider, such as
            accepting booking requests, messaging and welcoming Users, and
            updating the Listing Fee and calendar availability (collectively,{' '}
            <strong>“Co-Provider Services”</strong>). Any agreement formed
            between Provider and Co-Provider may not conflict with these Terms,
            the Payments Terms, or any other Policies applicable to your
            Provider Service(s). {updateAppName('Sporti')} reserves the right, in our sole
            discretion, to limit the number of Co-Providers a Provider may
            invite for each Listing and to limit the number of Listings a
            Co-Provider may manage.
          </li>
          <li className="ml-4">
            7.4.2 Providers should exercise due diligence and care when deciding
            who to add as a Co-Provider to their Listing(s). Providers remain
            solely responsible and liable for any and all Listings and Customer
            Content published on the {updateAppName('Sporti')} Platform, including any Listing
            created by a Co-Provider on their behalf. Further, Providers remain
            responsible and liable for their own acts and omissions, including,
            but not limited to, conduct that causes harm or damage to the
            Co-Provider(s). Co-Providers remain responsible and liable for their
            own acts and omissions when engaging in their roles and
            responsibilities as a Co-Provider, including, but not limited to,
            conduct that causes harm or damage to the Provider. In addition,
            both Provider and Co-Provider are jointly responsible and severely
            liable for third party claims, including User claims, arising from
            the acts and omissions of the other person as related to Providing
            activities, communications with Users, and the provision of any
            Co-Provider Services.
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Provider;
