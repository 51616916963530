import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import { fetchArticleByID } from '../../store/reducers/activitySlice';
import type { RootState, TActivity, TArticle, TTopic } from '../../store/type';
import RelatedArticles from './RelatedArticles';
import ArticleDetailsDrawer from './ArticleDetailsDrawer';
import ArticleBody from './ArticleBody';
import HelpSection from './HelpSection';

const ArticleDetails = () => {
  const { articleId } = useParams();
  const dispatch = useDispatch();
  const [hasMarkdown, setHasMarkdown] = useState(false);
  const [markdown, setMarkdown] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!articleId) return;
    dispatch(fetchArticleByID(articleId));
  }, [dispatch, articleId]);

  const selectedArticle = useSelector(
    (state: RootState) => state.activity.selectedArticle,
  ) as TArticle;

  const topics = useSelector(
    (state: RootState) => state.activity.filteredTopics,
  );

  const activity = useSelector(
    (state: RootState) => state.activity.selectedActivity,
  ) as TActivity;

  useEffect(() => {
    window.scroll(0, 0);
  }, [selectedArticle]);

  useEffect(() => {
    if (!selectedArticle.pathname) return;
    let isMounted = true;
    setLoading(true);
    try {
      import(`../../articles/${selectedArticle?.pathname}.md`)
        .then(res => {
          axios.get(res.default).then(res => {
            if (isMounted) {
              let markup = res.data;
              if (process.env.REACT_APP_NAME) {
              markup = markup.replaceAll('Sporti', process.env.REACT_APP_NAME);
              markup = markup.replaceAll('sporti', process.env.REACT_APP_NAME.toLowerCase());
              }
              console.log(process.env, markup);
              setHasMarkdown(true);
              setMarkdown(markup);
              setLoading(false);
            }
          });
        })
        .catch(err => {
          if (isMounted) {
            setHasMarkdown(false);
            setMarkdown('No Article Found');
            setLoading(false);
            console.error(err);
          }
        });
    } catch (err) {
      if (isMounted) {
        setHasMarkdown(false);
        setMarkdown('No Article Found');
        setLoading(false);
        console.error(err);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [selectedArticle]);

  const selectedActivity = useSelector(
    (state: RootState) => state.activity.selectedActivity,
  ) as TActivity;

  const selectedTopic = useSelector(
    (state: RootState) => state.activity.selectedTopic,
  ) as TTopic;

  const selectedActivityName = selectedActivity.name;
  const selectedTopicName = selectedTopic.name;

  return (
    <section data-testid="article-details" className="bg-primary-background">
      <BreadCrumbs
        activityName={selectedActivityName}
        topic={selectedTopic}
        article={selectedArticle}
      />

      <div className="max-w-7xl mx-auto px-4 py-10 flex flex-col md:flex-row gap-14 lg:gap-28">
        {activity && topics ? (
          <>
            <ArticleDetailsDrawer
              selectedTopicName={selectedTopicName}
              selectedArticleId={selectedArticle.id}
            />
          </>
        ) : null}

        <div className="w-full md:w-3/4 markdown-content">
          {loading ? (
            <p className="text-2xl font-bold">Loading...</p>
          ) : (
            <ArticleBody
              selectedArticle={selectedArticle}
              hasMarkdown={hasMarkdown}
              markdown={markdown}
            />
          )}
          <HelpSection />

          <RelatedArticles selectedArticle={selectedArticle} />
        </div>
      </div>
    </section>
  );
};

export default ArticleDetails;
