import React from 'react';
import { updateAppName } from '../../utils/misc';

const Information = () => {
  return (
    <>
      <p className="my-6">
        There are three general categories of information we collect.
      </p>
      <p className="my-6">
        <strong className="text-black">2.1 Information You Give to Us.</strong>
      </p>
      <p className="my-6">
        <span className="underline">
          2.1.1 Information that is necessary for the use of the {updateAppName('Sporti')}
          Platform.
        </span>
        <br />
        We ask for and collect the following personal information about you when
        you use the {updateAppName('Sporti')} Platform. This information is necessary for the
        adequate performance of the contract between you and us and to allow us
        to comply with our legal obligations. Without it, we may not be able to
        provide you with all the requested services.
      </p>
      <ul className="list-disc pl-6">
        <li>
          <strong className="text-black">Account Information.</strong> When you
          sign up for a {updateAppName('Sporti')} Account, we require certain information such as
          your first name, last name, email address, and date of birth.
        </li>
        <li>
          <strong className="text-black">
            Profile and Listing Information.
          </strong>
          To use certain features of the {updateAppName('Sporti')} Platform (such as booking or
          creating a Listing), we may ask you to provide additional information,
          which may include your address, phone number, and a profile picture.
        </li>
        <li>
          <strong className="text-black">
            Identity Verification Information.
          </strong>
          To help create and maintain a trusted environment, we may collect
          identity verification information (such as images of your government
          issued ID, passport, national ID card, or driving license, as
          permitted by applicable laws) or other authentication information. To
          learn more, see our Help Center article about providing identification
          on {updateAppName('Sporti')}.
        </li>
        <li>
          <strong className="text-black">Payment Information.</strong> To use
          certain features of the {updateAppName('Sporti')} Platform (such as booking or creating a
          Listing), we may require you to provide certain financial information
          (like your bank account or credit card information) in order to
          facilitate the processing of payments (via {updateAppName('Sporti')} Payments).
        </li>
        <li>
          <strong className="text-black">
            Communications with {updateAppName('Sporti')} and other Customers.
          </strong>
          When you communicate with {updateAppName('Sporti')} or use the {updateAppName('Sporti')} Platform to
          communicate with other Customers, we collect information about your
          communication and any information you choose to provide.
        </li>
      </ul>
      <p className="my-6 space-y-3">
        <span className="underline">
          2.1.2 Information you choose to give us.
        </span>
        <br />
        You may choose to provide us with additional personal information in
        order to obtain a better user experience when using {updateAppName('Sporti')} Platform.
        This additional information will be processed based on our legitimate
        interest or when applicable, your consent. <br />
        <ul className="list-disc pl-6">
          <li>
            <strong className="text-black">
              Additional Profile Information.
            </strong>
            You may choose to provide additional information as part of your
            {updateAppName('Sporti')} profile (such as gender, preferred language(s), city, and a
            personal description). Some of this information as indicated in your
            Account settings is part of your public profile page, and will be
            publicly visible to others.
          </li>
          <li>
            <strong className="text-black">
              Address Book Contact Information.
            </strong>
            You may choose to import your address book contacts or enter your
            contacts&apos; information manually to access certain features of
            the {updateAppName('Sporti')} Platform, like inviting them to use {updateAppName('Sporti')}.
          </li>
          <li>
            <strong className="text-black">Other Information.</strong> You may
            otherwise choose to provide us information when you fill in a form,
            update or add information to your {updateAppName('Sporti')} Account, respond to
            surveys, post to community forums, participate in promotions,
            communicate with our customer care team, share your experience with
            us (such as through Provider Stories), or use other features of the
            {updateAppName('Sporti')} Platform.
          </li>
        </ul>
      </p>
      <p className="my-6">
        <span className="underline">
          2.1.3 Information that is necessary for the use of the Payment
          Services.
        </span>
        <br />
        The Payments Data Controller needs to collect the following information
        necessary for the adequate performance of the contract with you and to
        comply with applicable law (such as anti-money laundering regulations).
        Without it, you will not be able to use Payment Services: <br />
        <ul className="list-disc pl-6">
          <li>
            <strong className="text-black">Payment Information.</strong> When
            you use the Payment Services, the Payments Data Controller requires
            certain financial information (like your bank account or credit card
            information) in order to process payments and comply with applicable
            law.
          </li>
          <li>
            <strong className="text-black">
              Identity Verification and Other Information.
            </strong>
            If you are a Provider, the Payments Data Controller may require
            identity verification information (such as images of your government
            issued ID, passport, national ID card, or driving license) or other
            authentication information, your date of birth, your address, email
            address, phone number and other information in order to verify your
            identity, provide the Payment Services to you, and to comply with
            applicable law.
          </li>
        </ul>
      </p>
      <p className="my-6">
        <span className="underline">
          2.1.4 Information We Automatically Collect from Your Use of the {updateAppName('Sporti')}
          Platform and Payment Services.
        </span>
        <br />
        When you use the {updateAppName('Sporti')} Platform and the Payment Services, we
        automatically collect personal information about the services you use
        and how you use them. This information is necessary for the adequate
        performance of the contract between you and us, to enable us to comply
        with legal obligations and given our legitimate interest in being able
        to provide and improve the functionalities of the {updateAppName('Sporti')} Platform and
        Payment Services.
        <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <strong className="text-black">Geo-location Information. </strong>
            When you use certain features of the {updateAppName('Sporti')} Platform, we may collect
            information about your precise or approximate location as determined
            through data such as your IP address or mobile device’s GPS to offer
            you an improved user experience. Most mobile devices allow you to
            control or disable the use of location services for applications in
            the device’s settings menu. {updateAppName('Sporti')} may also collect this information
            even when you are not using the app if this connection is enabled
            through your settings or device permissions.
          </li>
          <li>
            <strong className="text-black">Usage Information. </strong> We
            collect information about your interactions with the {updateAppName('Sporti')} Platform
            such as the pages or content you view, your searches for Listings,
            bookings you have made, and other actions on the {updateAppName('Sporti')} Platform.
          </li>
          <li>
            <strong className="text-black">
              Log Data and Device Information.
            </strong>
            We automatically collect log data and device information when you
            access and use the {updateAppName('Sporti')} Platform, even if you have not created a
            {updateAppName('Sporti')} Account or logged in. That information includes, among other
            things: details about how you’ve used the {updateAppName('Sporti')} Platform (including
            if you clicked on links to third party applications), IP address,
            access dates and times, hardware and software information, device
            information, device event information, unique identifiers, crash
            data, cookie data, and the pages you’ve viewed or engaged with
            before or after using the {updateAppName('Sporti')} Platform.
          </li>
          <li>
            <strong className="text-black">
              Cookies and Similar Technologies.
            </strong>
            We use cookies and other similar technologies when you use our
            platform, use our mobile app, or engage with our online ads or email
            communications. We may collect certain information by automated
            means using technologies such as cookies, web beacons, pixels,
            browser analysis tools, server logs, and mobile identifiers. In many
            cases the information we collect using cookies and other tools is
            only used in a non-identifiable without reference to personal
            information. For example, we may use information we collect to
            better understand website traffic patterns and to optimize our
            website experience. In some cases we associate the information we
            collect using cookies and other technology with your personal
            information. Our business partners may also use these tracking
            technologies on the {updateAppName('Sporti')} Platform or engage others to track your
            behavior on our behalf.
          </li>
          <li>
            <strong className="text-black">Pixels and SDKs. </strong>
            Third parties, including Facebook, may use cookies, web beacons, and
            other storage technologies to collect or receive information from
            our websites and elsewhere on the internet and use that information
            to provide measurement services and target ads. For apps, that third
            parties, including Facebook, may collect or receive information from
            your app and other apps and use that information to provide
            measurement services and targeted ads. Users can opt-out of the
            collection and use of information for ad targeting by updating their
            Facebook account ad settings and by contacting opt-out@{updateAppName('sporti')}.com
            with a description of your request and validation information. Users
            can access a mechanism for exercising such choice by going to
            http://www.aboutads.info/choices and
            http://www.youronlinechoices.eu/. For more information on our use of
            these technologies, see our Cookie Policy.
          </li>
          <li>
            <strong className="text-black">Do Not Track Signals.</strong> While
            you may disable the usage of cookies through your browser settings,
            the {updateAppName('Sporti')} Platform currently does not respond to a “Do Not Track”
            signal in the HTTP header from your browser or mobile application
            due to lack of standardization regarding how that signal should be
            interpreted.
          </li>
          <li>
            <strong className="text-black">
              Payment Transaction Information.
            </strong>
            {updateAppName('Sporti')} Payments collects information related to your payment
            transactions through the {updateAppName('Sporti')} Platform, including the payment
            instrument used, date and time, payment amount, payment instrument
            expiration date and billing postcode, PayPal email address, IBAN
            information, your address and other related transaction details.
            This information is necessary for the adequate performance of the
            contract between you and {updateAppName('Sporti')} Payments and to allow the provision
            of the Payment Services.
          </li>
        </ul>
      </p>
      <p className="my-6">
        <span className="underline">
          2.1.5 Information We Collect from Third Parties.
        </span>
        <br />
        {updateAppName('Sporti')} and {updateAppName('Sporti')} Payments may collect information, including personal
        information, that others provide about you when they use the {updateAppName('Sporti')}
        Platform and the Payment Services, or obtain information from other
        sources and combine that with information we collect through the {updateAppName('Sporti')}
        Platform and the Payment Services. We do not control, supervise or
        respond to how the third parties providing your information process your
        Personal Information, and any information request regarding the
        disclosure of your personal information to us should be directed to such
        third parties.
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <strong className="text-black">Third Party Services. </strong>If you
            link, connect, or login to your {updateAppName('Sporti')} Account with a third party
            service (e.g. Apple, Google, Facebook, WeChat), the third party
            service may send us information such as your registration, friends
            list, and profile information from that service. This information
            varies and is controlled by that service or as authorized by you via
            your privacy settings at that service.
          </li>
          <li>
            <strong className="text-black">Your References. </strong> If someone
            has written a reference for you, it will be published on your {updateAppName('Sporti')}
            public profile page with your consent. To learn more, see our Help
            Center article about References.
          </li>
          <li>
            <strong className="text-black">Background Information. </strong> For
            Customers in the United States, to the extent permitted by
            applicable laws, {updateAppName('Sporti')} and {updateAppName('Sporti')} Payments may obtain reports from
            public records of criminal convictions or sex offender
            registrations. For Customers outside of the United States, to the
            extent permitted by applicable laws and with your consent where
            required, {updateAppName('Sporti')} and {updateAppName('Sporti')} Payments may obtain the local version of
            police, background or registered sex offender checks. We may use
            your information, including your full name and date of birth, to
            obtain such reports.
          </li>
          <li>
            <strong className="text-black">
              Enterprise Product Invitations and Account Management.
            </strong>
            Organizations that use our Enterprise products (such a {updateAppName('Sporti')} for
            work and programs with property managers and owners) may submit
            personal information to facilitate account management and
            invitations to use enterprise products.
          </li>
          <li>
            <strong className="text-black">Referrals. </strong> If you are
            invited to {updateAppName('Sporti')}, the person who invited you may submit personal
            information about you such as your email address or other contact
            information.
          </li>
          <li>
            <strong className="text-black">Other Sources. </strong> To the
            extent permitted by applicable law, we may receive additional
            information about you, such as demographic data or information to
            help detect fraud and safety issues, from third party service
            providers and/or partners, and combine it with information we have
            about you. For example, we may receive background check results
            (with your consent where required) or fraud warnings from service
            providers like identity verification services for our fraud
            prevention and risk assessment efforts. We may receive information
            about you and your activities on and off the {updateAppName('Sporti')} Platform through
            partnerships, or about your experiences and interactions from our
            partner ad networks.
          </li>
        </ul>
      </p>
      <h4 className="text-xl text-black">2.2 Children’s Data. </h4>
      <p className="my-6">
        Our websites and applications are not directed to children under 16 and
        we do not knowingly collect any personal information directly from
        children under 16. If you believe that we are processing the personal
        information pertaining to a child inappropriately, we take this very
        seriously and urge you to contact us using the information provided
        under the “Contact Us” section below.
      </p>
    </>
  );
};

export default Information;
