import { updateAppName } from "../../utils/misc";

const DisputeResolution = () => {
  return (
    <div className="space-y-4">
      <p>
        22.1 This Dispute Resolution and Arbitration Agreement shall apply if
        you (i) are contracting with {updateAppName('Sporti')} Payments US; or (ii) bring any claim
        against any {updateAppName('Sporti')} Payments entity in the United States (to the extent
        not in conflict with Section 21).
      </p>
      <p>
        22.2 Overview of Dispute Resolution Process. {updateAppName('Sporti')} Payments is
        committed to participating in a consumer-friendly dispute resolution
        process. To that end, these Payments Terms provide for a two-part
        process for individuals to whom Section 22.1 applies: (1) an informal
        negotiation directly with {updateAppName('Sporti')}&apos;s customer service team, and (2) a
        binding arbitration administered by the American Arbitration Association
        (“<strong className="text-black">AAA</strong>”) using its specially
        designed Consumer Arbitration Rules (as modified by this Section 22 and
        except as provided in Section 22.6). Specifically, the Consumer
        Arbitration Rules provide:
        <ul className="list-disc space-y-4 pl-6">
          <li>Claims can be filed with AAA online (www.adr.org);</li>
          <li>
            Arbitrators must be neutral and no party may unilaterally select an
            arbitrator;
          </li>
          <li>
            Arbitrators must disclose any bias, interest in the result of the
            arbitration, or relationship with any party;
          </li>
          <li>
            Parties retain the right to seek relief in small claims court for
            certain claims, at their option;
          </li>
          <li>The initial filing fee for the consumer is capped at $200;</li>
          <li>
            The consumer gets to elect the hearing location and can elect to
            participate live, by phone, video conference, or, for claims under
            $25,000, by the submission of documents; and
          </li>
          <li>
            The arbitrator can grant any remedy that the parties could have
            received in court to resolve the party&apos;s individual claim.
          </li>
        </ul>
      </p>
      <p>
        22.3 Pre-Arbitration Dispute Resolution and Notification. Prior to
        initiating an arbitration, you and {updateAppName('Sporti')} Payments each agree to notify
        the other party of the dispute and attempt to negotiate an informal
        resolution to it first. We will contact you at the email address you
        have provided to us; you can contact {updateAppName('Sporti')}&apos;s customer service team
        by emailing us. If after a good faith effort to negotiate one of us
        feels the dispute has not and cannot be resolved informally, the party
        intending to pursue arbitration agrees to notify the other party via
        email prior to initiating the arbitration. In order to initiate
        arbitration, a claim must be filed with the AAA and the written Demand
        for Arbitration (available at www.adr.org) provided to the other party,
        as specified in the AAA Rules.
      </p>
      <p>
        22.4 Agreement to Arbitrate. You and {updateAppName('Sporti')} Payments mutually agree that
        any dispute, claim or controversy arising out of or relating to these
        Payments Terms or the applicability, breach, termination, validity,
        enforcement or interpretation thereof, or to the use of the Payment
        Services (collectively, “Disputes”) will be settled by binding
        individual arbitration (the “Arbitration Agreement”). If there is a
        dispute about whether this Arbitration Agreement can be enforced or
        applies to our Dispute, you and {updateAppName('Sporti')} Payments agree that the
        arbitrator will decide that issue.
      </p>
      <p>
        22.5 Exceptions to Arbitration Agreement. You and {updateAppName('Sporti')} Payments each
        agree that the following claims are exceptions to the Arbitration
        Agreement and will be brought in a judicial proceeding in a court of
        competent jurisdiction: (i) Any claim related to actual or threatened
        infringement, misappropriation or violation of a party&apos;s
        copyrights, trademarks, trade secrets, patents, or other intellectual
        property rights; (ii) Any claim seeking emergency injunctive relief
        based on exigent circumstances (e.g., imminent danger or commission of a
        crime, hacking, cyber-attack).
      </p>
      <p>
        22.6 Arbitration Rules and Governing Law. This Arbitration Agreement
        evidences a transaction in interstate commerce and thus the Federal
        Arbitration Act governs the interpretation and enforcement of this
        provision. The arbitration will be administered by AAA in accordance
        with the Consumer Arbitration Rules and/or other AAA arbitration rules
        determined to be applicable by the AAA (the{' '}
        <strong className="text-black">“AAA Rules“</strong>) then in effect,
        except as modified here. The AAA Rules are available at www.adr.org.
      </p>
      <p>
        22.7 Modification to AAA Rules - Arbitration Hearing/Location. In order
        to make the arbitration most convenient to you, {updateAppName('Sporti')} Payments agrees
        that any required arbitration hearing may be conducted, at your option,
        (a) in the county where you reside; (b) in San Francisco County; (c) in
        any other location to which you and {updateAppName('Sporti')} Payments both agree; (d) via
        phone or video conference; or (e) for any claim or counterclaim under
        $25,000, by solely the submission of documents to the arbitrator.
      </p>
      <p>
        22.8 Modification of AAA Rules - Attorney&apos;s Fees and Costs. You and
        {updateAppName('Sporti')} Payments agree that {updateAppName('Sporti')} Payments will be responsible for
        payment of the balance of any initial filing fee under the AAA Rules in
        excess of $200 for claims of $75,000 or less. You may be entitled to
        seek an award of attorney fees and expenses if you prevail in
        arbitration, to the extent provided under applicable law and the AAA
        rules. Unless the arbitrator determines that your claim was frivolous or
        filed for the purpose of harassment, {updateAppName('Sporti')} Payments agrees it will not
        seek, and hereby waives all rights it may have under applicable law or
        the AAA rules, to recover attorneys&apos; fees and expenses if it
        prevails in arbitration.
      </p>
      <p>
        22.9 Arbitrator&apos;s Decision. The arbitrator&apos;s decision will
        include the essential findings and conclusions upon which the arbitrator
        based the award. Judgment on the arbitration award may be entered in any
        court with proper jurisdiction. The arbitrator may award declaratory or
        injunctive relief only on an individual basis and only to the extent
        necessary to provide relief warranted by the claimant&apos;s individual
        claim.
      </p>
      <p>
        22.10 Jury Trial Waiver. You and {updateAppName('Sporti')} Payments acknowledge and agree
        that we are each waiving the right to a trial by jury as to all
        arbitrable Disputes.
      </p>
      <p>
        22.11 No Class Actions or Representative Proceedings. You and {updateAppName('Sporti')}
        Payments acknowledge and agree that, to the fullest extent permitted by
        applicable law, we are each waiving the right to participate as a
        plaintiff or class Customer in any purported class action lawsuit,
        class-wide arbitration, private attorney general action, or any other
        representative proceeding as to all Disputes. Further, unless you and
        {updateAppName('Sporti')} Payments both otherwise agree in writing, the arbitrator may not
        consolidate more than one party&apos;s claims and may not otherwise
        preside over any form of any class or representative proceeding. If the
        “class action lawsuit” waiver or the “class-wide arbitration” waiver in
        this Section 22.11 is held unenforceable with respect to any Dispute,
        then the entirety of the Arbitration Agreement will be deemed void with
        respect to such Dispute and the Dispute must proceed in court. If the
        “private attorney general action” waiver or the “representative
        proceeding” waiver in this Section 19.11 is held unenforceable with
        respect to any Dispute, those waivers may be severed from this
        Arbitration Agreement and you and {updateAppName('Sporti')} Payments agree that any private
        attorney general claims and representative claims in the Dispute will be
        severed and participated, pending the resolution of any arbitrable
        claims in the Dispute in individual arbitration.
      </p>
      <p>
        22.12 Severability. Except as provided in Section 22.11, in the event
        that any portion of this Arbitration Agreement is deemed illegal or
        unenforceable, such provision shall be severed and the remainder of the
        Arbitration Agreement shall be given full force and effect.
      </p>
      <p>
        22.13 Changes. Notwithstanding the provisions of Section 3
        (“Modification of these Payments Terms”), if {updateAppName('Sporti')} Payments changes
        this Section 22 (“Dispute Resolution and Arbitration Agreement”) after
        the date you last accepted these Payments Terms (or accepted any
        subsequent changes to these Payments Terms), you may reject any such
        change by sending us written notice (including by email) within thirty
        (30) days of the date such change became effective, as indicated in the
        “Last Updated” date above or in the date of {updateAppName('Sporti')} Payments&apos; email
        to you notifying you of such change. By rejecting any change, you are
        agreeing that you will arbitrate any Dispute between you and {updateAppName('Sporti')}
        Payments in accordance with the provisions of the “Dispute Resolution”
        section as of the date you last accepted these Payments Terms (or
        accepted any subsequent changes to these Payments Terms).
      </p>
      <p>
        22.14 Survival. Except as provided in Section 22.12 and subject to
        Section 20.8, this Section 22 will survive any termination of these
        Payments Terms and will continue to apply even if you stop using the
        Payment Services or terminate your {updateAppName('Sporti')} Account.
      </p>
    </div>
  );
};

export default DisputeResolution;
