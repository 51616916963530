import { updateAppName } from "../../utils/misc";

const FinancialTerms = () => {
  return (
    <div>
      <p className="flex flex-col">
        <strong className="text-black">7.1 Generally</strong>
        <span>
          Generally speaking, {updateAppName('Sporti')} Payments will collect the Customer Fees at
          the time the User completes a booking.
        </span>
      </p>
      <p className="flex flex-col space-y-4">
        <strong className="text-black mt-4">7.2. Payouts</strong>
        <span>
          7.2.1 In order to receive a Payout the Provider must have a valid
          Payout Method linked to your {updateAppName('Sporti')} Account. {updateAppName('Sporti')} Payments will
          generally initiate Payouts to the Providers selected Payout Method
          within 7 days following the actual start date of the Sport Activity
          that the User has booked. In certain jurisdictions or instances,
          {updateAppName('Sporti')} Payments may offer the Provider a different date or trigger for
          payment. For example, in certain cases, eligible Providers may be able
          to receive a Payout for a booking prior to the User’s start date of
          the Sport Activity. Any such alternative Payout option may be subject
          to additional Terms .
        </span>
        <span>
          7.2.2 The time it takes to receive Payouts once released by {updateAppName('Sporti')}
          Payments may depend upon the Payout Method the Provider selects and
          the Payout Method provider’s processing schedule. {updateAppName('Sporti')} Payments may
          delay or cancel any Payout for purposes of preventing unlawful
          activity or fraud, risk assessment, security, or investigation.{' '}
        </span>
        <span>
          7.2.3 The Provider Payout for a booking will be the Listing Fee less
          applicable Provider Fees and Taxes.
        </span>
        <span>
          7.2.4 In the event of a User’s cancellation of a booking, {updateAppName('Sporti')}
          Payments will remit a Payout of any portion of the Total Fees due to
          the Provider under the applicable cancellation policy.
        </span>
        <span>
          7.2.5 {updateAppName('Sporti')} Payments will remit the Provider Payouts in your currency
          of choice, depending upon the Provider selections via the {updateAppName('Sporti')}
          Platform’ and amounts may be rounded up or down as described in the
          {updateAppName('Sporti')} Terms.
        </span>
        <span>
          7.2.6 For compliance or operational reasons, {updateAppName('Sporti')} Payments may limit
          the value of each individual Payout. If the Provider is due an amount
          above that limit, {updateAppName('Sporti')} Payments may initiate a series of Payouts
          (potentially over multiple days) in order to provide the Provider full
          payout amount.
        </span>
      </p>
    </div>
  );
};

export default FinancialTerms;
