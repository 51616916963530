import { updateAppName } from "../../utils/misc";

const GeneralProvisions = () => {
  return (
    <div className="space-y-4">
      <p>
        23.1 Except as they may be supplemented by additional Terms , policies,
        guidelines, or standards, these Payments Terms constitute the entire
        Agreement between {updateAppName('Sporti')} Payments and you regarding the subject matter
        hereof, and supersede any and all prior oral or written understandings
        or agreements between {updateAppName('Sporti')} Payments and you regarding the Payment
        Services.
      </p>
      <p>
        23.2 No joint venture, partnership, employment, or agency relationship
        exists between you or {updateAppName('Sporti')} Payments as a result of this Agreement or
        your use of the Payment Services.
      </p>
      <p>
        23.3 If any provision of these Payments Terms is held to be invalid or
        unenforceable, such provision will be struck and will not affect the
        validity and enforceability of the remaining provisions.
      </p>
      <p>
        23.4 {updateAppName('Sporti')} Payments’ failure to enforce any right or provision in these
        Payments Terms will not constitute a waiver of such right or provision
        unless acknowledged and agreed to by us in writing. Except as expressly
        set forth in these Payments Terms, the exercise by either party of any
        of its remedies under these Payments Terms will be without prejudice to
        its other remedies under these Payments Terms or otherwise permitted
        under law.
      </p>
      <p>
        23.5 You may not assign, transfer, or delegate this Agreement and your
        rights and obligations hereunder without {updateAppName('Sporti')} Payments’ prior written
        consent. {updateAppName('Sporti')} Payments may without restriction assign, transfer, or
        delegate this Agreement and any rights and obligations, at its sole
        discretion, with thirty (30) days’ prior notice (or two (2) months’
        prior notice for Customers contracting with {updateAppName('Sporti')} Payments UK or {updateAppName('Sporti')}
        Payments Luxembourg). Your right to terminate this Agreement at any time
        remains unaffected.
      </p>
      <p>
        23.6 This Agreement does not and is not intended to confer any rights or
        remedies upon any person other than the parties. Notwithstanding the
        foregoing, the parties agree that the payment card networks are
        third-party beneficiaries of this Agreement for purposes of enforcing
        provisions related to payments, but that their consent or agreement is
        not necessary for any changes or modifications to this Agreement.
      </p>
      <p>
        23.7 Unless specified otherwise, any notices or other communications
        permitted or required under this Agreement, will be in writing and given
        by {updateAppName('Sporti')} Payments via email, {updateAppName('Sporti')} Platform notification, or messaging
        service (including SMS and WeChat). For notices made to Customers
        residing outside of EEA, the date of receipt will be deemed the date on
        which {updateAppName('Sporti')} transmits the notice.
      </p>
    </div>
  );
};

export default GeneralProvisions;
