import HeroSection from './HeroSection';
import Articles from './Articles';

const HomePage = () => {
  return (
    <section className="bg-primary-background py-20">
      <div className="max-w-7xl mx-auto">
        <HeroSection />
        <Articles />
      </div>
    </section>
  );
};

export default HomePage;
