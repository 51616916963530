import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import BreadCrumbs from '../../components/BreadCrumbs';
import { fetchTopicsByActivityName } from '../../store/reducers/activitySlice';
import type { TActivity, TTopic } from '../../store/type';
import Articles from '../Home/Articles';
import HeroSection from './HeroSection';
import TopicCards from './TopicSetion';

import { ReactComponent as HeroCardFirstImage } from '../../assets/images/hero-card-1.svg';
import { ReactComponent as HeroCardSecondImage } from '../../assets/images/hero-card-2.svg';

type RootState = {
  activity: {
    data: TActivity[];
    filteredTopics: TTopic[];
  };
};

const ActivityTemplate = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const pathname = location.pathname;

  const activityPath = pathname.split('/')[1];

  React.useEffect(() => {
    dispatch(fetchTopicsByActivityName(activityPath));
  }, [dispatch, activityPath]);

  const topics = useSelector(
    (state: RootState) => state.activity.filteredTopics,
  );

  const title =
    activityPath === 'booking-activity'
      ? 'I am booking sports activities'
      : 'I am providing sports activities';

  const description =
    activityPath === 'booking-activity'
      ? 'Find information on booking, changing or canceling, payment options, contacting your Provider, and more.'
      : 'Find information on managing your listing, getting paid, communicating with Users, and more.';

  const imageComponent =
    activityPath !== 'providing-activity' ? (
      <HeroCardFirstImage />
    ) : (
      <HeroCardSecondImage />
    );

  return (
    <section className="bg-primary-background">
      <BreadCrumbs activityName={activityPath} />
      <div className="max-w-7xl mx-auto">
        <HeroSection
          title={title}
          description={description}
          image={imageComponent}
        />
        {topics ? (
          <TopicCards topics={topics} activityPath={activityPath} />
        ) : null}
        <Articles />
      </div>
    </section>
  );
};

export default ActivityTemplate;
