import * as React from 'react';
import { ReactComponent as HeroCardFirstImage } from '../../assets/images/hero-card-1.svg';
import { ReactComponent as HeroCardSecondImage } from '../../assets/images/hero-card-2.svg';
import { ReactComponent as LinkArrowIcon } from '../../assets/icons/linkarrow.svg';

const HeroSection = () => {
  return (
    <div className="px-4 xl:px-0 mb-12 md:mb-0">
      <div className="flex flex-col lg:flex-row lg:items-center gap-14">
        <div className="w-full md:w-2/3 lg:w-1/3">
          <h1 className="text-[42px] leading-[50px] tracking-tight font-bold mb-5">
            Hi, what can we help you with today?
          </h1>
          <p className="text-xl text-gray-500">
            Checkout the frequently asked questions regarding booking the sports
            activities or providing the sports activities.
          </p>
        </div>

        <div className="flex flex-col md:flex-row w-full lg:w-2/3 gap-8 lg:gap-14">
          <CardLayout href="/booking-activity">
            <HeroCardFirstImage className="mx-auto " />
            <div className="absolute left-4 right-4 bottom-6 flex justify-between items-center">
              <span className="text-xl font-medium">
                I am booking sports activities
              </span>
              <LinkArrowIcon className="-rotate-90" />
            </div>
          </CardLayout>

          <CardLayout href="/providing-activity">
            <HeroCardSecondImage className="mx-auto" />
            <div className="absolute left-4 right-4 bottom-6 flex justify-between items-center">
              <span className="text-xl font-medium">
                I am providing sports activities
              </span>
              <LinkArrowIcon className="-rotate-90" />
            </div>
          </CardLayout>
        </div>
      </div>
    </div>
  );
};

const CardLayout = ({
  children,
  href,
}: {
  children: React.ReactNode;
  href: string;
}) => {
  return (
    <a
      href={href}
      className="min-h-[378px] w-full  p-4 lg:p-6 rounded-xl bg-white border border-[#2C99C665] relative lg:hover:scale-105 hover:shadow-lg hover:text-primary-link transition-all">
      {children}
    </a>
  );
};
export default HeroSection;
