import React from 'react';
import { updateAppName } from '../../utils/misc';

const ChangesToPrivacy = () => {
  return (
    <p className="my-6">
      {updateAppName(`Sporti reserves the right to modify this Privacy Policy at any time in
      accordance with this provision. If we make changes to this Privacy Policy,
      we will post the revised Privacy Policy on the Sporti Platform and update
      the “Last Updated” date at the top of this Privacy Policy. We will also
      provide you with notice of the modification by email at least thirty (30)
      days before the date they become effective. If you disagree with the
      revised Privacy Policy, you may cancel your Account. If you do not cancel
      your Account before the date the revised Privacy Policy becomes effective,
      your continued access to or use of the Sporti Platform will be subject to
      the revised Privacy Policy.`)}
    </p>
  );
};

export default ChangesToPrivacy;
