import React from 'react';
import { updateAppName } from '../../utils/misc';

const Rounding = () => {
  return (
    <p>
      {updateAppName('Sporti')} generally supports payment amounts that are payable from or to
      Users or Providers to the smallest unit supported by a given currency
      (i.e., U.S. cents, Euro cents or other supported currencies). Where
      {updateAppName('Sporti')}’s third-party payment services provider does not support payments
      in the smaller unit supported by a given currency, {updateAppName('Sporti')} may, in its sole
      discretion, round up or round down the displayed amounts that are payable
      from or to Users or Providers to the nearest whole functional base unit in
      which the currency is denominated (i.e. to the nearest dollar, Euro or
      other supported currency); for example, {updateAppName('Sporti')} may round up an amount of
      $101.50 to $102.00, and round down an amount of $101.49 to $101.00.
    </p>
  );
};

export default Rounding;
