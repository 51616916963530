import { updateAppName } from "../../utils/misc";

const FinancialTermsUsr = () => {
  return (
    <div className="space-y-4">
      <p>
        8.1 The User authorizes {updateAppName('Sporti')} Payments to charge the User Payment
        Method the Total Fees for any booking requested in connection with the
        User {updateAppName('Sporti')} Account. {updateAppName('Sporti')} Payments will collect the Total Fees in the
        manner agreed between the User and {updateAppName('Sporti')} Payments via the {updateAppName('Sporti')}
        Platform. {updateAppName('Sporti')} Payments will collect the Total Fees at the time of the
        User booking request. If {updateAppName('Sporti')} Payments is unable to collect the Total
        Fees as scheduled, {updateAppName('Sporti')} Payments will collect the Total Fees at a
        later point. Once the payment transaction for the User booking is
        successfully completed, the User will receive a confirmation email.
      </p>
      <p>
        8.2 When the User request to book a Listing, {updateAppName('Sporti')} Payments may also
        (i) obtain a pre-authorization via the User Payment Method for the Total
        Fees, (ii) charge or authorize the User Payment Method a nominal amount,
        to verify the User Payment Method, or (iii) authenticate your account
        via a third-party payment service provider to verify the User Payment
        Method.
      </p>
      <p>
        8.3 If a booking is canceled by the Provider, any amounts collected by
        {updateAppName('Sporti')} Payments will be refunded to the User in accordance with our
        terms, and any pre-authorization of the User Payment Method will be
        released (if applicable). The timing to receive the refund or for the
        pre-authorization to be released will vary based on the Payment Method
        and any applicable payment system (e.g., Visa, MasterCard, etc.) rules.
      </p>
      <p>
        8.4 the User authorizes {updateAppName('Sporti')} Payments to perform the Payment Method
        verifications described in Sections 6 and 8, and to charge the User
        Payment Method for any bookings made in connection with the User {updateAppName('Sporti')}
        Account. the User hereby authorizes {updateAppName('Sporti')} Payments to collect any
        amounts due, by charging the Payment Method provided at checkout, either
        directly by {updateAppName('Sporti')} Payments or indirectly, via a third-party online
        payment processor, and/or by one or more of the payment methods
        available on the {updateAppName('Sporti')} Platform (such as points).
      </p>
    </div>
  );
};

export default FinancialTermsUsr;
