import React from 'react';

const Applicable = () => {
  return (
    <ul className="space-y-2">
      <li>
        22.1 If your country of residence or establishment is the United States,
        these Terms will be interpreted in accordance with the laws of the State
        of California and the United States of America, without regard to
        conflict-of-law provisions. Judicial proceedings (other than small
        claims actions) that are excluded from the Arbitration Agreement in
        Section 19 must be brought in state or federal court in Houston Texas,
        unless we both agree to some other location. You and we both consent to
        venue and personal jurisdiction in Houston Texas.
      </li>
    </ul>
  );
};

export default Applicable;
