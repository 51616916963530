import { updateAppName } from "../../utils/misc";

const Eligibility = () => {
  return (
    <div className="space-y-4">
      <p>
        4.1 You must be at least 18 years old and able to enter into legally
        binding contracts to use the Payment Services. By using the Payment
        Services you represent and warrant that you are 18 or older.
      </p>
      <p>
        4.2 {updateAppName('Sporti')} Payments may make access to and use of certain areas or
        features of the Payment Services subject to certain conditions or
        requirements, such as completing a verification process or meeting
        specific eligibility criteria.
      </p>
      <p>
        4.3 We may make inquiries we consider necessary to help verify or check
        your identity or prevent fraud. Towards this end, you authorize {updateAppName('Sporti')}
        Payments to screen you against third party databases or other sources
        and request reports from service providers. In some jurisdictions, we
        have a legal obligation to collect identity information to comply with
        anti-money laundering regulations. This may include (i) asking you to
        provide a form of government identification (e.g., driver’s license or
        passport), your date of birth, your address, and other information; (ii)
        requiring you to take steps to confirm ownership of your email address,
        Payment Methods or Payout Methods; (iii) attempting to screen your
        information against third-party databases. {updateAppName('Sporti')} Payments reserves the
        right to close, suspend, or limit access to the Payment Services in the
        event we are unable to obtain or verify any of this information.
        {/* (i)  (ii)
         or (iii)  */}
      </p>
    </div>
  );
};

export default Eligibility;
