import { updateAppName } from "../../utils/misc";

const AdditionalClauses = () => {
  return (
    <div className="space-y-4">
      <p>
        The following paragraphs also apply if you are using the Payment
        Services as a representative (
        <strong className="text-black">“Representative”</strong>) acting on
        behalf a business, company or other legal entity (in such event, for
        purposes of the Payment Terms, “you” and “your” will refer and apply to
        that business, company or other legal entity).
      </p>
      <p>
        27.1 You accept the Payment Terms and you will be responsible for any
        act or omission of employees or third-party agents using the Payment
        Service on your behalf.
      </p>
      <p>
        27.2 You and your Representative individually affirm that you are
        authorized to provide the information described in section 6 above and
        your Representative has the authority to bind you to these Payment
        Terms. We may require you to provide additional information or
        documentation demonstrating your Representative’s authority.
      </p>
      <p>
        27.3 You represent and warrant to us that: (i) you are duly organized,
        validly existing and in good standing under the laws of the country in
        which your business is registered and that you are registering for
        receiving the Payment Services; and (ii) you have all requisite right,
        power, and authority to enter into this Agreement, perform your
        obligations, and grant the rights, licenses, and authorizations in this
        Agreement.
      </p>
      <p>
        27.4 If you are using your Payment Method for the benefit of your
        employees or other authorized third-party in connection with {updateAppName('Sporti')} for
        Work, as permitted by your account, you authorize {updateAppName('Sporti')} Payments to
        charge your Payment Method for bookings requested by employees at your
        company or other permitted third-party.
      </p>
      <p>
        27.5 For any Payout Method linked to your {updateAppName('Sporti')} Account, you authorize
        {updateAppName('Sporti')} Payments to store the Payout Method, remit payments using the
        Payout Method for bookings associated with your {updateAppName('Sporti')} Account and take
        any other action as permitted in the Payment Terms in respect of the
        Payout Method.
      </p>
      <p>
        27.6 If you handle, store or otherwise process payment card information
        on behalf of anyone or any third-party, you agree to comply on an
        ongoing basis with applicable data privacy and security requirements
        under the current Payment Card Industry Data Security Standard with
        regards to the use, access, and storage of such credit card information.{' '}
      </p>
    </div>
  );
};

export default AdditionalClauses;
