import React from 'react';
import { updateAppName } from '../../utils/misc';

const Modification = () => {
  return (
    <p>
      {updateAppName('Sporti')} reserves the right to modify these Terms at any time in accordance
      with this provision. If we make changes to these Terms, we will post the
      revised Terms on the {updateAppName('Sporti')} Platform and update the “Last Updated” date at
      the top of these Terms. We will also provide you with notice of the
      modifications by email at least thirty (30) days before the date they
      become effective. If you disagree with the revised Terms, you may
      terminate this Agreement with immediate effect. We will inform you about
      your right to terminate the Agreement in the notification email. If you do
      not terminate your Agreement before the date the revised Terms become
      effective, your continued access to or use of the {updateAppName('Sporti')} Platform will
      constitute acceptance of the revised Terms.
    </p>
  );
};

export default Modification;
