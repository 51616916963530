import { Link } from 'react-router-dom';

type DrawerItemProps = {
  name: string;
  activityPath: string;
  id: string;
  selectedTopicName?: string;
};

const DrawerItem: React.FC<DrawerItemProps> = ({
  name,
  activityPath,
  id,
  selectedTopicName,
}) => {
  let isActive = name === selectedTopicName;

  return (
    <Link
      to={`/${activityPath}/${id}`}
      className={
        isActive
          ? 'text-sm opacity-80 px-3 py-4 cursor-pointer tracking-normal w-full inline-block border-4 border-white border-l-primary-link rounded-r-lg text-primary bg-white shadow-3xl xmd:text-base transition-all'
          : 'opacity-80 text-black tracking-normal text-sm py-4 cursor-pointer w-full inline-block hover:text-primaryborder-l-primary-link xmd:text-base transition-all'
      }>
      {name}
    </Link>
  );
};

export default DrawerItem;
