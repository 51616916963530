import React from 'react';
import { updateAppName } from '../../utils/misc';

const HowWeUse = () => {
  return (
    <>
      <p className="my-6">
        We may use, store, and process personal information to (1) provide,
        understand, improve, and develop the {updateAppName('Sporti')} Platform, (2) create and
        maintain a trusted and safer environment (such as to comply with our
        legal obligations and ensure compliance with {updateAppName('Sporti')} Policies) and (3)
        provide, personalize, measure, and improve our advertising and
        marketing.
      </p>

      <p className="my-6">
        <strong className="text-black">
          3.1 Provide, Improve, and Develop the {updateAppName('Sporti')} Platform.{' '}
        </strong>{' '}
        We may use the personal information to provide, improve, and develop the
        {updateAppName('Sporti')} Platform such as to:
        <ul className="list-disc pl-6 space-y-2">
          <li>enable you to access and use the {updateAppName('Sporti')} Platform,</li>
          <li>enable you to communicate with other Customers,</li>
          <li>
            operate, protect, improve, and optimize the {updateAppName('Sporti')} Platform and
            experience, such as by performing analytics and conducting research,
          </li>
          <li>provide customer service,</li>
          <li>
            send you service or support messages, updates, security alerts, and
            account notifications,
          </li>
          <li>
            if you provide us with your contacts’ information, we may process
            this information: (i) to facilitate your referral invitations, (ii)
            send your requests for references, (iii) for fraud detection and
            prevention, and (iv) for any purpose you authorize at the time of
            collection,
          </li>
          <li>
            to operate, protect, improve, and optimize the {updateAppName('Sporti')} Platform and
            experience, and personalize and customize your experience (such as
            making Listing suggestions, ranking search results), and facilitate
            claims, and
          </li>
          <li>enable your use of our enterprise products.</li>
        </ul>
        We process this personal information for these purposes given our
        legitimate interest in improving the {updateAppName('Sporti')} Platform and our Customers’
        experience with it, and where it is necessary for the adequate
        performance of the contract with you.
      </p>
      <p className="my-6">
        <strong className="text-black">
          3.2 Create and Maintain a Trusted and Safer Environment.{' '}
        </strong>{' '}
        We may use the personal information to create and maintain a trusted and
        safer environment such as to:
        <ul className="list-disc pl-6 space-y-2">
          <li>
            detect and prevent fraud, spam, abuse, security incidents, and other
            harmful activity,
          </li>
          <li>conduct security investigations and risk assessments,</li>
          <li>
            verify or authenticate information or identifications provided by
            you (such as to verify your Sports Activities address or compare
            your identification photo to another photo you provide),
          </li>
          <li>
            conduct checks against databases and other information sources,
            including background or police checks, to the extent permitted by
            applicable laws and with your consent where required,
          </li>
          <li>comply with our legal obligations,</li>
          <li>
            Resolve any disputes with any of our Customers and enforce our
            agreements with third parties,
          </li>
          <li>enforce our Terms of Service and other policies, and</li>
          <li>
            In connection with the activities above, we may conduct profiling
            based on your interactions with the {updateAppName('Sporti')} Platform, your profile
            information and other content you submit to the {updateAppName('Sporti')} Platform, and
            information obtained from third parties. In limited cases, automated
            processes may restrict or suspend access to the {updateAppName('Sporti')} Platform if
            such processes detect activity that we think poses a safety or other
            risk to the {updateAppName('Sporti')} Platform, our community, or third parties. If you
            challenge the decisioning based on the automated process, please
            contact us as provided in the Contact Us section below.
          </li>
        </ul>
        We process this personal information for these purposes given our
        legitimate interest in protecting the {updateAppName('Sporti')} Platform, to measure the
        adequate performance of our contract with you, and to comply with
        applicable laws.
      </p>

      <p className="my-6">
        <strong className="text-black">
          3.3 Provide, Personalize, Measure, and Improve our Advertising and
          Marketing.{' '}
        </strong>{' '}
        We may use the personal information to provide, personalize, measure,
        and improve our advertising and marketing such as to: <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            send you promotional messages, marketing, advertising, and other
            information that may be of interest to you based on your preferences
            (including information about {updateAppName('Sporti')} or partner campaigns and
            services) and social media advertising through social media
            platforms such as Facebook or Google),
          </li>
          <li>personalize, measure, and improve our advertising,</li>
          <li>
            Administer referral programs, rewards, surveys, sweepstakes,
            contests, or other promotional activities or events sponsored or
            managed by {updateAppName('Sporti')} or its third party partners,
          </li>
          <li>
            conduct profiling on your characteristics and preferences (based on
            the information you provide to us, your interactions with the {updateAppName('Sporti')}
            Platform, information obtained from third parties, and your search
            and booking history) to send you promotional messages, marketing,
            advertising and other information that we think may be of interest
            to you,
          </li>
          <li>
            Enrolling in an email subscription will not affect the frequency of
            administrative emails that we may send in connection with any {updateAppName('Sporti')}
            Account. No fee is charged for sending promotional emails to you,
            but third-party data rates may apply. Note that you may not be able
            to take advantage of certain promotions if you do not have an {updateAppName('Sporti')}
            Account, and
          </li>
          <li>
            invite you to events and relevant opportunities (for example, when
            you share your Provider story, we may use the information provided
            to reach out to you to invite you to relevant events).
          </li>
        </ul>
        We will process your personal information for the purposes listed in
        this section given our legitimate interest in undertaking marketing
        activities to offer you products or services that may be of your
        interest.
      </p>
      <p className="my-6">
        <strong className="text-black">
          3.4 How the Payments Data Controller uses the Personal Information
          Collected.{' '}
        </strong>{' '}
        We may use the personal information as a part of Payment services such
        as to:
        <ul className="list-disc pl-6 space-y-2">
          <li>Enable you to access and use the Payment Services.</li>
          <li>
            Detects and prevents fraud, abuse, security incidents, and other
            harmful activity.
          </li>
          <li>Conduct security investigations and risk assessments.</li>
          <li>
            Conduct checks against databases and other information sources.
          </li>
          <li>
            Comply with legal obligations (such as anti-money laundering
            regulations).
          </li>
          <li>Enforce the Payment Terms and other payment policies.</li>
          <li>
            With your consent, send you promotional messages, marketing,
            advertising, and other information that may be of interest to you
            based on your preferences.
          </li>
        </ul>
        The Payments Data Controller processes this personal information given
        its legitimate interest in improving the Payment Services and its users’
        experience with it, and where it is necessary for the adequate
        performance of the contract with you and to comply with applicable laws.
      </p>
      <p className="my-6">
        <strong className="text-black">3.5 SMS Terms for U.S. </strong> <br />{' '}
        For text messaging in the United States, by requesting, joining,
        agreeing to, enrolling in, signing up for, acknowledging, or otherwise
        consenting to receive one or more text messages (“Opting In”) or using a
        {updateAppName('Sporti')} arrangement in which {updateAppName('Sporti')} sends (or indicates that it may send,
        or receives a request that it send) one or more text messages (“Text
        Message Service”), you accept these SMS Terms for U.S. (“SMS Terms”),
        consent to the handling of your personal information as described in the
        {updateAppName('Sporti')} Privacy Policy, and agree to resolve disputes with {updateAppName('Sporti')} as
        described in our Terms of Service. Message and data rates may apply.{' '}
        <br />
        {updateAppName('Sporti')} will use reasonable commercial efforts to deliver the automated
        marketing text messages to the number you provide through compatible
        wireless carriers. Carriers and {updateAppName('Sporti')} are not liable for delayed or
        undelivered messages. The short code we use for some Text Message
        Services may not be supported on all U.S. carriers.
      </p>
      <p className="my-6">
        <span className="underline my-2"> Opting In </span>
        <br />
        <strong className="text-black my-2">
          By Opting In to a Text Message Service:
        </strong>{' '}
        <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            You expressly authorize {updateAppName('Sporti')} to use autodialer or non-autodialer
            technology to send text messages to the cell phone number associated
            with your Opt-In (i.e., the number listed on the Opt-In form or
            instructions, or, if none, the number from which you send the
            Opt-In, or, if none, the number on file for your account). You also
            authorize {updateAppName('Sporti')} to include marketing content in any such messages.
            You do not have to Opt In or agree to Opt In as a condition of
            purchase.
          </li>
          <li>
            You consent to the use of an electronic record to document your
            Opt-In. To withdraw that consent, request a free paper or email copy
            of the Opt-In, or to update our records with your contact
            information, please contact us via the methods described in the
            Contact Us section. To view and retain an electronic copy of these
            SMS Terms or the rest of your Opt-In, you will need (i) a device
            (such as a computer or cell phone) with internet access, and (ii)
            either a printer or storage space on such device. If you withdraw
            your consent, certain features of our service may not be available
            to you. To update information on how we would contact you
            electronically, visit your Notification settings in your account
            settings.
          </li>
          <li>
            You confirm that you are the subscriber to the relevant phone number
            or that you are the customary user of that number on a family or
            business plan and that you are authorized to Opt In.
          </li>
          <li>
            You consent to the use of an electronic record to document your
            Opt-In. To withdraw that consent, update our records with your
            contact information, please visit your {updateAppName('Sporti')} account Notifications
            settings or contact customer support.
          </li>
          <li>
            These SMS Terms still will apply if you withdraw the consent
            mentioned above or opt out of the Text Message Service.
          </li>
        </ul>
        After Opting In, in addition to the main messages the service offers,
        you may receive one or more welcome messages or administrative messages,
        such as (in some cases) a request to confirm your Opt-In.
      </p>
      <p className="my-6">
        <strong className="text-black">
          About the Text Message Services and Opting Out{' '}
        </strong>
        <br />
        Message and data rates may apply. Unless otherwise noted, Text Message
        Services send multiple, recurring messages. {updateAppName('Sporti')} may terminate any
        Text Message Service or your participation in it at any time with or
        without notice, including, for example, before you have received any or
        all messages that you otherwise would have received, but these SMS Terms
        still will apply. Text STOP to any promotional message to Opt Out or if
        applicable update your “Notification” settings on your {updateAppName('Sporti')} account.{' '}
        <br />
        For additional help, text HELP in response to a marketing message or
        contact customer service.
      </p>
      <p className="my-6">
        <strong className="text-black">3.6 Your Choices </strong>
        <br />
        You have choices on the promotional messages that you choose to receive.
        <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            You can limit the information you provide to {updateAppName('Sporti')}. Participation
            in promotions and marketing programs is voluntary.
          </li>
          <li>You can limit the communications that {updateAppName('Sporti')} sends to you.</li>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              To opt-out of marketing emails, simply click the link labeled
              “unsubscribe” at the bottom of any marketing email we send you or
              access the notification settings in your {updateAppName('Sporti')} Account.
            </li>
            <li>
              To revoke permissions that you may have given to send promotional
              text messages, text STOP in response to any message.
            </li>
            <li>
              You can always update your notification settings within your
              {updateAppName('Sporti')} Account by visiting the Notifications section of your
              {updateAppName('Sporti')} Account. Please note that even if you opt-out of marketing
              communications, we may still need to contact you with important
              transactional information about your account. For example, even if
              you opt-out of emails, we may still send you activity
              confirmations or fraud alerts.
            </li>
          </ul>
        </ul>
      </p>
    </>
  );
};

export default HowWeUse;
