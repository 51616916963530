import { RootState } from '../../store/type';
import { ReactComponent as Arrow } from '../../assets/icons/forwardArrow.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchArticlesByTopicID } from '../../store/reducers/activitySlice';
import { useDispatch } from 'react-redux';

type ArticleDetailsDrawerProps = {
  selectedTopicName: string;
  selectedArticleId: string;
};

const ArticleDetailsDrawer = ({
  selectedTopicName,
  selectedArticleId,
}: ArticleDetailsDrawerProps) => {

  
  const dispatch = useDispatch();
  const topics = useSelector(
    (state: RootState) => state.activity.filteredTopics,
  );

  const articles = useSelector((state: RootState) => state.activity.articles);

  const handleTopicChange = (id: string) => {
    dispatch(fetchArticlesByTopicID(id));
  };

  return (
    <>
      <div className="w-full md:w-1/4">
        <div className="transition-all space-y-4">
          {topics.map(({ id, name }) => (
            <div
              key={id}
              className={`${
                selectedTopicName === name
                  ? 'bg-white shadow-sm pt-4 rounded-md'
                  : null
              }`}>
              <button
                onClick={() => handleTopicChange(id)}
                className="w-full flex justify-between items-center transition-all px-2">
                <p
                data-testid={name}
                  className={`${
                    selectedTopicName === name ? 'text-primary-link' : null
                  } text-lg font-bold`}>
                  {name}
                </p>
                <Arrow
                  className={`${
                    selectedTopicName === name && 'rotate-90 text-primary-link'
                  } transition-all`}
                />
              </button>
              {selectedTopicName === name && (
                <div className="pb-2">
                  <ul className="flex flex-col gap-3 mt-4 max-h-80 overflow-y-auto transition-all">
                    {articles.map(({ id, name }) => (
                      <li key={id} className="w-full transition-all">
                        <Link
                          to={`/articles/${id}`}
                          className={`${
                            id === selectedArticleId
                              ? 'border-l-primary-link rounded-r-lg text-primary-link'
                              : 'border-l-white '
                          } text-sm w-full opacity-80 p-3 cursor-pointer  tracking-normal inline-block hover:text-primary-link md:text-base transition-all border-l-2`}>
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ArticleDetailsDrawer;
