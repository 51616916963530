import React from 'react';
import { updateAppName } from '../../utils/misc';

const Content = () => {
  return (
    <ul className="space-y-2">
      <li>
        5.1 {updateAppName('Sporti')} may, at its sole discretion, enable Customers to (i) create,
        upload, post, send, receive and store content, such as text, photos,
        audio, video, or other materials and information on or through the
        {updateAppName('Sporti')} Platform <strong>("Customer Content")</strong>; and (ii) access
        and view Customer Content and any content that {updateAppName('Sporti')} itself makes
        available on or through the {updateAppName('Sporti')} Platform, including proprietary
        {updateAppName('Sporti')} content and any content licensed or authorized for use by or
        through {updateAppName('Sporti')} from a third party <strong>("{updateAppName('Sporti')} Content"</strong>{' '}
        and together with Customer Content,{' '}
        <strong>"Collective Content")</strong>.
      </li>
      <li>
        5.2 The {updateAppName('Sporti')} Platform, {updateAppName('Sporti')} Content, and Customer Content may in its
        entirety or in part be protected by copyright, trademark, and/or other
        laws of the United States and other countries. You acknowledge and agree
        that the {updateAppName('Sporti')} Platform and {updateAppName('Sporti')} Content, including all associated
        intellectual property rights, are the exclusive property of {updateAppName('Sporti')}
        and/or its licensors or authorizing third-parties. You will not remove,
        alter or obscure any copyright, trademark, service mark or other
        proprietary rights notices incorporated in or accompanying the {updateAppName('Sporti')}
        Platform, {updateAppName('Sporti')} Content or Customer Content. All trademarks, service
        marks, logos, trade names, and any other source identifiers of {updateAppName('Sporti')}
        used on or in connection with the {updateAppName('Sporti')} Platform and {updateAppName('Sporti')} Content are
        trademarks or registered trademarks of {updateAppName('Sporti')} in the United States and
        abroad. Trademarks, service marks, logos, trade names and any other
        proprietary designations of third parties used on or in connection with
        the {updateAppName('Sporti')} Platform, {updateAppName('Sporti')} Content, and/or Collective Content are used
        for identification purposes only and may be the property of their
        respective owners.
      </li>
      <li>
        5.3 You will not use, copy, adapt, modify, prepare derivative works of,
        distribute, license, sell, transfer, publicly display, publicly perform,
        transmit, broadcast or otherwise exploit the {updateAppName('Sporti')} Platform or
        Collective Content, except to the extent you are the legal owner of
        certain Customer Content or as expressly permitted in these Terms. No
        licenses or rights are granted to you by implication or otherwise under
        any intellectual property rights owned or controlled by {updateAppName('Sporti')} or its
        licensors, except for the licenses and rights expressly granted in these
        Terms.
      </li>
      <li>
        5.4 Subject to your compliance with these Terms, {updateAppName('Sporti')} grants you a
        limited, non-exclusive, non-sublicensable, revocable, non-transferable
        license to (i) download and use the Application on your personal
        device(s); and (ii) access and view any Collective Content made
        available on or through the {updateAppName('Sporti')} Platform and accessible to you,
        solely for your personal and non-commercial use.
      </li>
      <li>
        5.5 By creating, uploading, posting, sending, receiving, storing, or
        otherwise making available any Customer Content on or through the {updateAppName('Sporti')}
        Platform, you grant to {updateAppName('Sporti')} a non-exclusive, worldwide, royalty-free,
        irrevocable, perpetual (or for the term of the protection),
        sub-licensable and transferable license to such Customer Content to
        access, use, store, copy, modify, prepare derivative works of,
        distribute, publish, transmit, stream, broadcast, and otherwise exploit
        in any manner such Customer Content to provide and/or promote the {updateAppName('Sporti')}
        Platform, in any media or platform. Insofar as Customer Content
        (including Verified Images) includes personal information, such Customer
        Content will only be used for these purposes if such use complies with
        applicable data protection laws in accordance with our Privacy Policy.
        Unless you provide specific consent, {updateAppName('Sporti')} does not claim any ownership
        rights in any Customer Content and nothing in these Terms will be deemed
        to restrict any rights that you may have to use or exploit your Customer
        Content.
      </li>
      <li>
        5.6 {updateAppName('Sporti')} may offer Providers the option of using the professional
        services of our business suite.
      </li>
      <li>
        5.7 You are solely responsible for all Customer Content that you make
        available on or through the {updateAppName('Sporti')} Platform. Accordingly, you represent
        and warrant that: (i) you either are the sole and exclusive owner of all
        Customer Content that you make available on or through the {updateAppName('Sporti')}
        Platform or you have all rights, licenses, consents and releases that
        are necessary to grant to {updateAppName('Sporti')} the rights in and to such Customer
        Content, as contemplated under these Terms; and (ii) neither the
        Customer Content nor your posting, uploading, publication, submission or
        transmittal of the Customer Content or {updateAppName('Sporti')}'s use of the Customer
        Content (or any portion thereof) as contemplated under these Terms will
        infringe, misappropriate or violate a third party's patent, copyright,
        trademark, trade secret, moral rights or other proprietary or
        intellectual property rights, or rights of publicity or privacy, or
        result in the violation of any applicable law or regulation.
      </li>
      <li>
        5.8 You will not post, upload, publish, submit or transmit any Customer
        Content that: (i) is fraudulent, false, misleading (directly or by
        omission or failure to update information) or deceptive; (ii) is
        defamatory, libelous, obscene, pornographic, vulgar or offensive; (iii)
        promotes discrimination, bigotry, racism, hatred, harassment or harm
        against any individual or group; (iv) is violent or threatening or
        promotes violence or actions that are threatening to any other person or
        animal; (v) promotes illegal or harmful activities or substances; or
        (vi) violates {updateAppName('Sporti')}’s Content Policy or any other {updateAppName('Sporti')} policy. {updateAppName('Sporti')}
        may, without prior notice, remove or disable access to any Customer
        Content that {updateAppName('Sporti')} finds to be in violation of applicable law, these
        Terms or {updateAppName('Sporti')}’s then-current Policies or Standards, or otherwise may
        be harmful or objectionable to {updateAppName('Sporti')}, its Customers, third parties, or
        property.
      </li>
      <li>
        5.9 {updateAppName('Sporti')} respects copyright law and expects its Customers to do the
        same. If you believe that any content on the {updateAppName('Sporti')} Platform infringes
        copyrights you own, please notify us.
      </li>
    </ul>
  );
};

export default Content;
