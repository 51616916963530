import React from 'react';
import { updateAppName } from '../../utils/misc';

const CancellationPolicy = () => {
  return (
    <>
      <ol className="list-decimal my-6 space-y-4">
        <li>1. User Cancellations</li>
        <ol className="ml-6 my-6 space-y-2">
          <li>
            1.1 A User who cancels a booking with more than 7 days remaining
            before the actual start date of the Sports Activity booked, will
            receive a full refund of 100% of the total Listing Fee paid, less a
            2% platform transaction processing fee.
          </li>
          <li>
            1.2 A User who cancels a booking with 1 to 7 days remaining before
            the actual start date of the Sports Activity booked, will receive a
            partial refund of 50% of the total Listing fee paid.
          </li>
          <li>
            1.3 A User who cancels a booking after the actual start date of the
            Sports Activity booked, will not receive a refund.
          </li>
        </ol>
        <li>Provider Cancellations</li>
        <ol className="ml-6 my-6 space-y-2">
          <li>
            2.1 Cancellations disrupt Users' plans and impact confidence in the
            {updateAppName('Sporti')} community, Providers should fulfill all bookings, however, if
            a Provider cannot fulfill a booking for any reason, it is their
            responsibility (and not the User’s) to cancel in a timely manner to
            allow their User to adjust their plans.
          </li>
          <li>
            If a Provider cancels a booking, the following consequences will
            apply except as described below, unless the Provider has legitimate
            concerns about the User’s profile, behavior or circumstances and can
            provide supporting evidence of the same, or has a valid reason for
            canceling the booking and can provide supporting evidence clearly
            showing why the booking was canceled.
          </li>
          <li>
            2.2 A Provider who cancels a booking with more than 7 days remaining
            before the actual start date of the Sports Activity booked, will be
            charged a $25 fee per booking, and a 2% platform transaction
            processing fee, which will be deducted from your next payout. The
            User will receive a full refund of the Listing Fee paid.
          </li>
          <li>
            2.3 A Provider who cancels a booking with less than 7 days remaining
            before the actual start date of the Sports Activity booked, will be
            charged a $50 fee per booking, and a 2% platform transaction
            processing fee, which will be deducted from your next payout. The
            User will receive a full refund of the Listing Fee paid.
          </li>
        </ol>
        <li>
          If weather poses a safety risk to Users, or if it prevents a Provider
          from carrying out a Sport Activity that takes place primarily
          outdoors, Providers may cancel the Sport Activity. Providers may also
          cancel the Sport Activity if other conditions exist that would prevent
          the Provider from offering the Provider Service safely, and should
          provide supporting evidence.
        </li>
        <li>
          2.5 In certain circumstances, {updateAppName('Sporti')} may decide, in its sole
          discretion, that it is necessary to cancel a booking and initiate
          corresponding refunds and payouts. This may be for reasons set forth
          in {updateAppName('Sporti')}'s Terms and Policies where {updateAppName('Sporti')} believes in good faith,
          while taking the legitimate interests of both parties into account,
          this is necessary to avoid significant harm to {updateAppName('Sporti')}, other
          Customers, third parties or property.
        </li>
        <li>
          If a User who books a Sport Activity suffers a Travel Issue, {updateAppName('Sporti')}
          may determine, in its sole discretion, to refund the User in part or
          in full of the Total Listing Fees paid.
        </li>
        <li>
          If a User or {updateAppName('Sporti')} cancels a booking, and the User receives a refund
          as a result of a Provider not being able to continue and fulfill its
          obligation under the booking, and the Provider has already been paid,
          {updateAppName('Sporti')} Payments will be entitled to recover the amount of any such
          refund from the Provider, including by subtracting such refund amount
          out from any future Payouts due to the Provider.
        </li>
        <li>
          Except as otherwise set out in these Terms, Customers may use the
          Resolution Center to send or request money for refunds, additional
          Provider Services or Damage Claims related to bookings. You agree to
          pay all amounts sent through the Resolution Center in connection with
          your {updateAppName('Sporti')} Account, and {updateAppName('Sporti')} Payments will handle all such
          payments.
        </li>
      </ol>
    </>
  );
};

export default CancellationPolicy;
