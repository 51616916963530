import React from 'react';
import RewardPolicy from './RewardPolicy';
import TextWrapper from '../../components/TextWrapper/Index';
import CancellationPolicy from './CancellationPolicy';
import { updateAppName } from '../../utils/misc';

const RewardTerms = () => {
  return (
    <section className="px-4 py-6 bg-primary-background text-justify text-gray-500">
      <div className="max-w-7xl mx-auto space-y-4">
        <h1 className="text-4xl text-black py-4">Rewards Terms</h1>
        <TextWrapper title={`${updateAppName('Sporti')} Rewards - “${updateAppName('Sporti')} Club Points”`}>
          <RewardPolicy />
        </TextWrapper>
        <TextWrapper title="Cancellation Policy">
          <CancellationPolicy />
        </TextWrapper>
      </div>
    </section>
  );
};

export default RewardTerms;
