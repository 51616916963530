import React from 'react';
import { updateAppName } from "../../utils/misc";

const Desclaimers = () => {
  return (
    <div className="space-y-4">
      <p>
        17.1 If you choose to use the Payment Services, you do so voluntarily
        and at your sole risk. To the maximum extent permitted by law, the
        Payment Services are provided “as is”, without warranty of any kind,
        either express or implied.
      </p>
      <p>
        17.2 Notwithstanding {updateAppName('Sporti')} Payments’ appointment as the limited payment
        collection agent of Providing Customers for the purposes of accepting
        payments from Purchasing Customers through the {updateAppName('Sporti')} Platform, {updateAppName('Sporti')}
        Payments explicitly disclaims all liability for any act or omission of
        any Customer or other third party. {updateAppName('Sporti')} Payments does not have any
        duties or obligations as agent for each Providing Customer except to the
        extent expressly set forth in these Payments Terms, and any additional
        duties or obligations as may be implied by law are, to the maximum
        extent permitted by applicable law, expressly excluded.
      </p>
      <p>
        17.3 If we choose to conduct identity verification on any Customer, to
        the extent permitted by applicable law, we disclaim warranties of any
        kind, either express or implied, that such checks will identify prior
        misconduct by a Customer or guarantee that a Customer will not engage in
        misconduct in the future.
      </p>
      <p>
        17.4 The foregoing disclaimers apply to the maximum extent permitted by
        law. You may have other statutory rights or warranties which cannot
        lawfully be excluded. However, the duration of any statutorily required
        warranties shall be limited to the maximum extent (if any) permitted by
        law.
      </p>
    </div>
  );
};

export default Desclaimers;
