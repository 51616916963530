import React from 'react';
import { updateAppName } from '../../utils/misc';

const Account = () => {
  return (
    <ul className="space-y-2">
      <li>
        4.1 You must register an account <strong>("{updateAppName('Sporti')} Account")</strong>{' '}
        to access and use the {updateAppName('Sporti')} Platform, such as publishing or booking a
        Listing. If you are registering as a Provider on a {updateAppName('Sporti')} Account for a
        business, organization or other legal entity, you represent and warrant
        that you have the authority to legally bind that entity and grant us all
        permissions and licenses provided in these Terms.
      </li>
      <li>
        4.2 You can register a {updateAppName('Sporti')} Account using an email address and
        creating a password, or through your account with certain third-party
        social networking services, such as Apple Facebook or Google{' '}
        <strong>("SNS Account")</strong>. You have the ability to disable the
        connection between your {updateAppName('Sporti')} Account and your SNS Account at any time,
        by accessing the "Settings" section of the {updateAppName('Sporti')} Platform.
      </li>
      <li>
        4.3 You must provide accurate, current and complete information during
        the registration process and keep your {updateAppName('Sporti')} Account and public {updateAppName('Sporti')}
        Account profile page information up-to-date at all times.
      </li>
      <li>
        4.4 You may not register more than one (1) {updateAppName('Sporti')} Account unless {updateAppName('Sporti')}
        authorizes you to do so. You may not assign or otherwise transfer your
        {updateAppName('Sporti')} Account to another party.
      </li>
      <li>
        4.5 You are responsible for maintaining the confidentiality and security
        of your {updateAppName('Sporti')} Account credentials and may not disclose your credentials
        to any third party. You must immediately notify {updateAppName('Sporti')} if you know or
        have any reason to suspect that your credentials have been lost, stolen,
        misappropriated, or otherwise compromised or in case of any actual or
        suspected unauthorized use of your {updateAppName('Sporti')} Account. You are liable for
        any and all activities conducted through your {updateAppName('Sporti')} Account, unless
        such activities are not authorized by you and you are not otherwise
        negligent (such as failing to report the unauthorized use or loss of
        your credentials).
      </li>
      <li>
        4.6 {updateAppName('Sporti')} may enable features that allow you to authorize other
        Customers or certain third parties to take certain actions that affect
        your {updateAppName('Sporti')} Account. For example, we may enable Customers to link their
        {updateAppName('Sporti')} Accounts to businesses and take actions for those businesses, we
        may enable eligible Customers or certain third parties to book Listings
        on behalf of other Customers, or we may enable Providers to add other
        Customers as Co-Providers to help manage their Listings. These features
        do not require that you share your credentials with any other person. No
        third party is authorized by {updateAppName('Sporti')} to ask for your credentials, and you
        shall not request the credentials of another Customer.
      </li>
      <li>
        4.7 You must not create duplicate accounts, profiles, organizations or
        listings.
      </li>
    </ul>
  );
};

export default Account;
