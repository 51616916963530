import React from 'react';
import { updateAppName } from '../../utils/misc';

const Eligibility = () => {
  return (
    <ul className="space-y-2">
      <li>
        2.1 In order to access and use the {updateAppName('Sporti')} Platform or register a {updateAppName('Sporti')}
        Account you must be a person aged at least 18 years old. If you are
        joining as a Provider you must be an Individual Provider or a Provider
        that is a duly organized, validly existing business, organization or
        other legal entity in good standing under the laws of the country you
        are established and able to enter into legally binding contracts.
      </li>
      <li>
        2.2 You will comply with any applicable laws in your local jurisdiction.
        You also represent and warrant that (i) neither you nor your Provider
        Service(s) are located or take place in a country that is subject to a
        U.S. Government embargo, or that has been designated by the U.S.
        Government as a "terrorist supporting" country, and (ii) you are not
        listed on any U.S. Government list of prohibited or restricted parties.
      </li>
      <li>
        2.3 {updateAppName('Sporti')} may make access to and use of the {updateAppName('Sporti')} Platform, or certain
        areas or features of the {updateAppName('Sporti')} Platform, subject to certain conditions
        or requirements, such as completing a verification process, meeting
        specific quality or eligibility criteria, meeting Ratings or Reviews
        thresholds, or a Customer’s booking and cancellation history.
      </li>
      <li>
        2.4 Verification on the Internet is difficult and we do not assume any
        responsibility for the confirmation of any Customer’s identity.
        Notwithstanding the above, for transparency and fraud prevention
        purposes, and as permitted by applicable laws, we may, but have no
        obligation to (i) ask Customers to provide a form of government
        identification or other information or undertake additional checks
        designed to help verify the identities or backgrounds of Customers, (ii)
        screen Customers against third party databases or other sources and
        request reports from service providers, and (iii) where we have
        sufficient information to identify a Customer, obtain reports from
        public records of criminal convictions or sex offender registrations or
        an equivalent version of background or registered sex offender checks in
        your local jurisdiction (if available).
      </li>
      <li>
        2.5 The access to or use of certain areas and features of the {updateAppName('Sporti')}
        Platform may be subject to separate policies, standards or guidelines,
        or may require that you accept additional Terms , before you can access
        the relevant areas or features of the {updateAppName('Sporti')} Platform. If there is a
        conflict between these Terms and Terms applicable to a specific area or
        feature of the {updateAppName('Sporti')} Platform, the latter Terms will take precedence
        with respect to your access to or use of that area or feature, unless
        specified otherwise in the latter Terms .
      </li>
      <li>
        2.6 If you access or download the Application from the Apple App Store
        and or the Google Play Store, you agree to Apple’s and Google’s Licensed
        Application End User License Agreement. Some areas of the {updateAppName('Sporti')}
        Platform implement Google Maps/Earth mapping services, including Google
        Maps API(s). Your use of Google Maps/Earth is subject to the Google
        Maps/Google Earth Additional Terms of Service.
      </li>
    </ul>
  );
};

export default Eligibility;
