type HeroSectionProps = {
  title: string;
  description: string;
  image: React.ReactNode;
};

const HeroSection = ({ title, description, image }: HeroSectionProps) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-20 lg:gap-32 items-center justify-between px-4 py-10">
        <div>
          <h1 className="text-5xl leading-tight font-bold mb-6 md:mb-4 lg:mb-2">
            {title}
          </h1>
          <p className="text-xl text-gray-500">{description}</p>
        </div>
        {image}
      </div>
    </>
  );
};

export default HeroSection;
