import React from 'react';
import { updateAppName } from '../../utils/misc';

const General = () => {
  return (
    <ul className="space-y-2">
      <li>
        23.1 Except as they may be supplemented by additional Terms , policies,
        guidelines or standards, these Terms constitute the entire Agreement
        between {updateAppName('Sporti')} and you pertaining to the subject matter hereof, and
        supersede any and all prior oral or written understandings or agreements
        between {updateAppName('Sporti')} and you in relation to the access to and use of the
        {updateAppName('Sporti')} Platform.
      </li>
      <li>
        23.2 No joint venture, partnership, employment, or agency relationship
        exists between you and {updateAppName('Sporti')} as a result of this Agreement or your use
        of the {updateAppName('Sporti')} Platform.
      </li>
      <li>
        23.3 These Terms do not and are not intended to confer any rights or
        remedies upon any person other than the parties.
      </li>
      <li>
        23.4 If any provision of these Terms is held to be invalid or
        unenforceable, such provision will be struck and will not affect the
        validity and enforceability of the remaining provisions.
      </li>
      <li>
        23.5 {updateAppName('Sporti')}'s failure to enforce any right or provision in these Terms
        will not constitute a waiver of such right or provision unless
        acknowledged and agreed to by us in writing. Except as expressly set
        forth in these Terms, the exercise by either party of any of its
        remedies under these Terms will be without prejudice to its other
        remedies under these Terms or otherwise permitted under law.
      </li>
      <li>
        23.6 You may not assign, transfer or delegate this Agreement and your
        rights and obligations hereunder without {updateAppName('Sporti')}'s prior written consent.
        {updateAppName('Sporti')} may without restriction assign, transfer or delegate this
        Agreement and any rights and obligations hereunder, at its sole
        discretion, with 30 days prior notice. Your right to terminate this
        Agreement at any time remains unaffected.
      </li>
      <li>
        23.7 Unless specified otherwise, any notices or other communications to
        Customers permitted or required under this Agreement, will be provided
        electronically and given by {updateAppName('Sporti')} via email, {updateAppName('Sporti')} Platform
        notification, or messaging service (including SMS and WeChat). If your
        booking is in respect of a Listing in Japan, you agree and acknowledge
        that such notifications via electronic means in lieu of a written
        statement, satisfies {updateAppName('Sporti')}'s obligations under Article 59 Paragraph 1
        of the Japanese Housing Sports Activities Business Act.
      </li>
      <li>23.8 If you have any questions about these Terms please email us.</li>
    </ul>
  );
};

export default General;
