import { updateAppName } from "../../utils/misc";

const CopyrightPolicy = () => {
  return (
    <section className="px-4 py-6 bg-primary-background text-justify text-gray-500">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl text-black py-4">Copyright Policy</h1>

        <div className="space-y-4">
          <Introduction />
        </div>
      </div>
    </section>
  );
};

export default CopyrightPolicy;

const Introduction = () => {
  return (
    <>
      <h3 className="text-black">Notification of Copyright Infringement</h3>
      <p>
        {updateAppName('Sporti')}, Inc. ("{updateAppName('Sporti')}") respects the intellectual property rights of
        others and expects its users to do the same. <br />
        It is {updateAppName('Sporti')}’s policy, in appropriate circumstances and at its
        discretion, to disable and/or terminate the account or access of users
        who repeatedly infringe or are repeatedly charged with infringing the
        copyrights or other intellectual property rights of others.
        <br />
        In accordance with the Digital Millennium Copyright Act of 1998, the
        text of which may be found on the U.S. Copyright Office website at
        http://www.copyright.gov/legislation/dmca.pdf, {updateAppName('Sporti')} will respond
        expeditiously to claims of copyright infringement committed using the
        {updateAppName('Sporti')} website and mobile application (the "Site and Application") that
        are reported to {updateAppName('Sporti')}’s Designated Copyright Agent, identified in the
        sample notice below. <br />
        If you are a copyright owner, or are authorized to act on behalf of one,
        or authorized to act under any exclusive right under copyright, please
        report alleged copyright infringements taking place on or through the
        Site and Application by completing the following DMCA Notice of Alleged
        Infringement and delivering it to {updateAppName('Sporti')}’s Designated Copyright Agent.
        Upon receipt of the Notice as described below, {updateAppName('Sporti')} will take whatever
        action, in its sole discretion, it deems appropriate, including removal
        of the challenged material from the Site and Application.
      </p>
      <h3 className="text-black">
        DMCA Notice of Alleged Infringement ("Notice")
      </h3>
      <ul className="list-decimal my-2 ml-8">
        <li>
          Identify the copyrighted work that you claim has been infringed, or -
          if multiple copyrighted works are covered by this Notice - you will
          provide a comprehensive list of the copyrighted works that you claim
          have been infringed.
        </li>

        <li>
          Identify the material that you claim is infringing (or to be the
          subject of infringing activity) and that is to be removed or access to
          which is to be disabled, and information reasonably sufficient to
          permit us to locate the material, including at a minimum, if
          applicable, the URL of the link shown on the Site and Application
          where such material may be found.
        </li>
        <li>
          Provide your mailing address, telephone number, and, if available,
          email address.
        </li>
        <li>
          Include both of the following statements in the body of the Notice:
          <ul className="list-disc pl-8 my-2">
            <li>
              "I hereby state that I have a good faith belief that the disputed
              use of the copyrighted material is not authorized by the copyright
              owner, its agent, or the law (e.g., as a fair use)."
            </li>
            <li>
              "I hereby state that the information in this Notice is accurate
              and, under penalty of perjury, that I am the owner, or authorized
              to act on behalf of the owner, of the copyright or of an exclusive
              right under the copyright that is allegedly infringed."
            </li>
          </ul>
        </li>

        <li>
          Provide your full legal name and your electronic or physical
          signature.
        </li>
      </ul>
      <p>
        {' '}
        Deliver this Notice, with all items completed, to {updateAppName('Sporti')}’s Designated
        Copyright Agent:
        <br />
        Copyright Agent
        <br />
        c/o Sporforya, Inc. 7901 Research Forest Dr, The Woodlands, TX 77382
      </p>
    </>
  );
};
