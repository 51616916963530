import React from 'react';
import ReactMarkdown from 'react-markdown';

const ArticleBody = ({selectedArticle, hasMarkdown, markdown }: any) => {
  return (
    <>
      {selectedArticle && hasMarkdown ? (
        <ReactMarkdown children={markdown} />
      ) : (
        <p>No Article Found</p>
      )}
    </>
  );
};

export default ArticleBody;
