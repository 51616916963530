import React from 'react';
import { updateAppName } from '../../utils/misc';

const Taxes = () => {
  return (
    <ul className="space-y-2">
      <li>
        13.1 As a Provider you are solely responsible for determining your
        obligations to report, collect, remit or include in your Listing Fees
        any applicable VAT or other indirect sales taxes, occupancy tax, tourist
        or other visitor taxes or income taxes (<strong>"Taxes"</strong>).
      </li>
      <li>
        13.2 Tax regulations may require us to collect appropriate Tax
        information from Providers, or to withhold Taxes from payouts to
        Providers, or both. If a Provider fails to provide us with the required
        documentation under applicable law (e.g., a tax number) that we
        determine to be sufficient to alleviate our obligation (if any) to
        withhold Taxes from payouts to you, we reserve the right to withhold
        payouts up to the tax-relevant amount as required by law, until
        resolution.
      </li>
      <li>
        13.3 You understand that any appropriate governmental agency, department
        and/or authority (<strong>"Tax Authority"</strong>) where your Sports
        Activities is located may require Taxes to be collected from Users or
        Providers on Listing Fees, and to be remitted to the respective Tax
        Authority. The laws in jurisdictions may vary, but these Taxes may be
        required to be collected and remitted as a percentage of the Listing
        Fees set by Providers, a set amount per day, or other variations.
      </li>
      <li>
        13.4 In certain jurisdictions, {updateAppName('Sporti')} may decide in its sole discretion
        to facilitate collection and remittance of Taxes from or on behalf of
        Users or Providers, in accordance with these Terms (
        <strong>"Collection and Remittance"</strong>) if such jurisdiction
        asserts {updateAppName('Sporti')} or Providers have a Tax collection and remittance
        obligation. In any jurisdiction in which we decide to facilitate direct
        Collection and Remittance, you hereby instruct and authorize {updateAppName('Sporti')} (via
        {updateAppName('Sporti')} Payments) to collect Taxes from Users on the Provider's behalf at
        the time Listing Fees are collected, and to remit such Taxes to the Tax
        Authority.
      </li>
      <li>
        13.5 You agree that any claim or cause of action relating to {updateAppName('Sporti')}'s
        facilitation of Collection and Remittance of Taxes shall not extend to
        any supplier or vendor that may be used by {updateAppName('Sporti')} in connection with
        facilitation of Collection and Remittance, if any. Users and Providers
        agree that we may seek additional amounts from you in the event that the
        Taxes collected and/or remitted are insufficient to fully discharge your
        obligations to the Tax Authority.
      </li>
      <li>
        13.6 {updateAppName('Sporti')} reserves the right, with prior notice to Providers, to cease
        the Collection and Remittance in any jurisdiction for any reason at
        which point Providers and Users are once again solely responsible and
        liable for the collection and/or remittance of any and all Taxes that
        may apply to Sports Activities in that jurisdiction.
      </li>
    </ul>
  );
};

export default Taxes;
