import { TArticle } from '../../store/type';
import QuestionText from './QuestionItem';

type QuestionSectionProps = {
  title: string;
  articles: TArticle[];
};

const QuestionSection: React.FC<QuestionSectionProps> = ({
  title,
  articles,
}) => {
  return (
    <div className="w-full md:w-3/4">
      <div className="flex items-start justify-center flex-col">
        <span className="text-center text-3xl font-black opacity-100 py-4">
          {title}
        </span>
        <ul className="w-full">
          {articles ? (
            articles.map(({ name, id }) => (
              <QuestionText text={name} key={id} id={id} />
            ))
          ) : (
            <p>No Articles Found</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default QuestionSection;
