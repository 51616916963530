import { updateAppName } from "../../utils/misc";

const Security = () => {
  return (
    <div className="space-y-4">
      <p>
        11.1 If you as a User (i) agree to pay the Provider in connection with a
        Damage Claim, or (ii) {updateAppName('Sporti')} determines that you are responsible for
        damaging an Sports Activities or any personal or other property located
        at an Sports Activities pursuant to the {updateAppName('Sporti')} Terms, you authorize
        {updateAppName('Sporti')} Payments to charge the Payment Method used to make the booking in
        order to collect any Security Deposit associated with the Listing, as
        well as any amount of the Damage Claim exceeding any Security Deposit.
        If the Listing does not have a Security Deposit, {updateAppName('Sporti')} Payments may
        charge the Payment Method used to make the booking for the amount of the
        Damage Claim. If we are unable to collect from your Payment Method used
        to make the booking, you agree that {updateAppName('Sporti')} Payments may charge any other
        Payment Method on file (and not otherwise unauthorized) in your {updateAppName('Sporti')}
        Account at the time of the Damage Claim.
      </p>
      <p>
        11.2 {updateAppName('Sporti')} Payments also reserves the right to otherwise collect
        payment from you and pursue any remedies available to {updateAppName('Sporti')} Payments in
        situations in which you are responsible for a Damage Claim pursuant to
        the {updateAppName('Sporti')} Terms.
      </p>
    </div>
  );
};

export default Security;
