import { updateAppName } from "../../utils/misc";

const Idemnification = () => {
  return (
    <p>
      To the maximum extent permitted by applicable law, you agree to release,
      defend (at {updateAppName('Sporti')} Payments’ option), indemnify, and hold {updateAppName('Sporti')} Payments
      and its affiliates and subsidiaries, and their officers, directors,
      employees, and agents, harmless from and against any claims, liabilities,
      damages, losses, and expenses, including, without limitation, reasonable
      legal and accounting fees, arising out of or in any way connected with (i)
      your breach of these Payments Terms; (ii) your improper use of the Payment
      Services; (iii) {updateAppName('Sporti')} Payments’ Collection and Remittance of Occupancy
      Taxes; or (iv) your breach of any laws, regulations, or third-party
      rights. If your country of residence is in the EEA, the indemnification
      obligation according to this Section 19 only applies if and to the extent
      that the claims, liabilities, damages, losses, and expenses have been
      adequately caused by your culpable breach of a contractual obligation.
    </p>
  );
};

export default Idemnification;
