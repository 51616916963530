import { useDispatch } from 'react-redux';
import { fetchTopicsByActivityName } from '../../store/reducers/activitySlice';
import { RootState } from '../../store/type';
import DrawerItem from './DrawerItem';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

type DrawerProps = {
  activityPath?: string;
  selectedTopicName?: string;
};

const Drawer: React.FC<DrawerProps> = ({ activityPath, selectedTopicName }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activityPath) return;
    dispatch(fetchTopicsByActivityName(activityPath));
  }, [activityPath, dispatch]);

  const newTopics = useSelector(
    (state: RootState) => state.activity.filteredTopics,
  );

  if (!activityPath) return <p>Please Select an Activity</p>;

  return (
    <nav className="w-full md:w-1/4">
      <ul className="flex flex-col gap-3">
        {newTopics.map(({ id, name }) => (
          <li key={id} className="w-full">
            <DrawerItem
              name={name}
              id={id}
              activityPath={activityPath}
              selectedTopicName={selectedTopicName}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Drawer;
