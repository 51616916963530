import { updateAppName } from "../../utils/misc";

const ProhibitedActivities = () => {
  return (
    <p>
      You are solely responsible for compliance with any and all laws, rules,
      regulations, and Tax obligations that may apply to your use of the Payment
      Services. In connection with your use of the Payment Services, you may not
      and you agree that you will not and will not assist or enable others to:
      <ul className="list-disc space-y-4 pl-6">
        <li>
          breach or circumvent any applicable laws or regulations, agreements
          with third parties, third-party rights, or the {updateAppName('Sporti')} Terms, Policies,
          or Standards;
        </li>
        <li>
          use the Payment Services for any commercial or other purposes that are
          not expressly permitted by these Payments Terms;
        </li>
        <li>
          register or use any Payment Method or Payout Method with your {updateAppName('Sporti')}
          Account that is not yours or you do not have authorization to use;
        </li>
        <li>
          avoid, bypass, remove, deactivate, impair, descramble, or otherwise
          circumvent any technological measure implemented by {updateAppName('Sporti')} Payments or
          any of {updateAppName('Sporti')} Payments’ providers or any other third party to protect
          the Payment Services;
        </li>
        <li>
          take any action that damages or adversely affects, or could damage or
          adversely affect, the performance or proper functioning of the Payment
          Services;
        </li>
        <li>
          attempt to decipher, decompile, disassemble, or reverse engineer any
          of the software used to provide the Payment Services; or
        </li>
        <li>
          violate or infringe anyone else’s rights or otherwise cause harm to
          anyone.
        </li>
      </ul>
    </p>
  );
};

export default ProhibitedActivities;
