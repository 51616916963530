import { useSelector } from 'react-redux';
import Card from '../Card';
import { RootState, TArticle } from '../../store/type';

type TRelatedArticlesProps = {
  selectedArticle: TArticle;
};

const RelatedArticles = ({ selectedArticle }: TRelatedArticlesProps) => {
  const articles = useSelector(
    (state: RootState) => state.activity.popularArticles,
  );

  const relatedArticles = articles
    .filter(article => article.id !== selectedArticle.id)
    .sort(() => Math.random() - 0.5)
    .slice(0, 3);

  return (
    <div className="my-16">
      <h3 className="font-bold text-3xl mb-7">Related Articles</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
        {relatedArticles.map(article => (
          <Card
            key={article.id}
            description={article.popular ? article.shortcontent : ''}
            title={article.name}
            id={article.id}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedArticles;
