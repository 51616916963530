import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { ReactComponent as Messenger } from '../../assets/icons/messenger.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as WhatsApp } from '../../assets/icons/whatsapp.svg';
import { updateAppName } from '../../utils/misc';

const SUPPORT_LINKS = [
  {
    name: 'Terms Conditions and Policies',
    href: '/terms-conditions-and-policies',
  },
  {
    name: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    name: 'Payment Terms',
    href: '/payment-terms',
  },
  {
    name: 'Copyright Policy',
    href: '/copyright-policy',
  },
  {
    name: 'Cookie Policy',
    href: '/cookie-policy',
  },
  {
    name: 'Reward Terms',
    href: '/reward-terms',
  },
];

const SOCIAL_LINKS = [
  {
    name: 'Instagram',
    icon: Instagram,
    href: 'https://www.instagram.com/sporforya/',
  },
  {
    name: 'Messenger',
    icon: Messenger,
    href: 'https://www.facebook.com/sporforya',
  },
  {
    name: 'Twitter',
    icon: Twitter,
    href: 'https://twitter.com/sporforya',
  },
  {
    name: 'WhatsApp',
    icon: WhatsApp,
    href: 'https://wa.me/+14158250178',
  },
];

const Footer = () => {
  return (
    <section className="bg-white shadow-3xl">
      <div className="max-w-7xl mx-auto tracking-tightest text-base ">
        <div className="grid grid-cols-1 px-4 py-8 lg:py-14 ">
          <ul className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 text-center">
            <>
              {SUPPORT_LINKS.map(({ name, href }, index) => {
                return (
                  <li key={index} className="flex-grow">
                    <a
                      rel="noreferrer"
                      className="text-gray-500 hover:text-gray-600 text-sm leading-9"
                      href={href}>
                      {name}
                    </a>
                  </li>
                );
              })}
            </>
          </ul>
        </div>
        <hr />
        <div className="px-4 py-6 lg:py-9 lg:px-0 flex flex-col md:flex-row md:justify-between md:items-center gap-4">
          <div className="flex flex-col items-center gap-4 tracking-tightest text-black/70 md:flex-row">
            <span className="md:ml-4">© {new Date().getFullYear()} {updateAppName('Sporti')}. All rights reserved.</span>
          </div>
          <div className="flex gap-4 md:justify-end md:mr-4">
            {SOCIAL_LINKS.map((link, index) => {
              return (
                <a
                  rel="noreferrer"
                  key={index}
                  href={link.href}
                  target="_blank"
                  className="w-7 h-7">
                  <link.icon />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
