import React from 'react';
import { updateAppName } from '../../utils/misc';

const Operating = () => {
  return (
    <p className="my-6">
      At this time, {updateAppName('Sporti')} only operates in the United States.{' '}
    </p>
  );
};

export default Operating;
