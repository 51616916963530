import { updateAppName } from "../../utils/misc";

const Liability = () => {
  return (
    <p>
      18.1 You acknowledge and agree that, to the maximum extent permitted by
      law, the entire risk arising out of your access to and use of the Payment
      Services remains with you. If you permit or authorize another person to
      use your {updateAppName('Sporti')} Account in any way, you are responsible for the actions
      taken by that person. Neither {updateAppName('Sporti')} Payments nor any other party involved
      in creating, producing, or delivering the Payment Services will be liable
      for any incidental, special, exemplary, or consequential damages,
      including lost profits, loss of data or loss of goodwill, service
      interruption, computer damage or system failure or the cost of substitute
      products or services, or for any damages for personal or bodily injury or
      emotional distress arising out of or in connection with (i) these Payments
      Terms, (ii) from the use of or inability to use the Payment Services, or
      (iii) from any communications, interactions, or meetings with other
      Customers or other persons with whom you communicate, interact, transact,
      or meet with as a result of your use of the Payment Services, whether
      based on warranty, contract, tort (including negligence), product
      liability, or any other legal theory, and whether or not {updateAppName('Sporti')} Payments
      has been informed of the possibility of such damage, even if a limited
      remedy set forth herein is found to have failed of its essential purpose.
      Except for our obligations to pay amounts to applicable Providing
      Customers pursuant to these Payments Terms or an approved payment request
      under the {updateAppName('Sporti')} Provider Guarantee, in no event will {updateAppName('Sporti')} Payments’
      aggregate liability arising out of or in connection with these Payments
      Terms and your use of the Payment Services including, but not limited to,
      from your use of or inability to use the Payment Services, exceed the
      amounts you have paid or owe for bookings via the {updateAppName('Sporti')} Platform as a
      User in the twelve (12) month period prior to the event giving rise to the
      liability, or if you are a Provider, the amounts paid by {updateAppName('Sporti')} Payments
      to you in the twelve (12) month period prior to the event giving rise to
      the liability, or one hundred U.S. dollars (US$100), if no such payments
      have been made, as applicable. The limitations of damages set forth above
      are fundamental elements of the basis of the bargain between {updateAppName('Sporti')}
      Payments and you. Some jurisdictions do not allow the exclusion or
      limitation of liability for consequential or incidental damages, so the
      above limitation may not apply to you. If you reside outside of the U.S.,
      this does not affect {updateAppName('Sporti')} Payments’ liability for death or personal
      injury arising from its negligence, nor for fraudulent misrepresentation,
      misrepresentation as to a fundamental matter, or any other liability which
      cannot be excluded or limited under applicable law.
    </p>
  );
};

export default Liability;
