import React, { ReactEventHandler } from 'react';
import { ReactComponent as FeedBackIcon } from '../../assets/icons/feedback.svg';
import { ReactComponent as CorrectIcon } from '../../assets/icons/correct.svg';

const options = [
  'This info is confusing or unclear',
  'These instructions didn’t work',
  'I didn’t find the answer to my question',
  'Something else',
];

type TRadioButtonProps = {
  id: string;
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  onChange: ReactEventHandler<HTMLInputElement>;
};

type TNegativeFeedbackProps = {
  setStep: (step: string) => void;
};

const HelpSection = () => {
  const [step, setStep] = React.useState('primary');
  return (
    <div className="bg-white p-4 rounded-xl border border-primary-[#15488F27] text-[#222222]">
      {step === 'primary' && (
        <div className="flex flex-col md:flex-row gap-8 items-center justify-between">
          <p className="text-lg font-semibold">Did you get the help you needed?</p>

          <div className="flex gap-8">
            <button
              onClick={() => setStep('positive')}
              className="border border-primary-link rounded-md px-9 py-2 hover:shadow-3xl hover:scale-95 transition-all text-primary-link hover:text-white hover:bg-primary-link">
              Yes
            </button>
            <button
              onClick={() => setStep('negative')}
              className="border border-primary-link rounded-md px-9 py-2 hover:shadow-3xl hover:scale-95 transition-all text-primary-link hover:text-white hover:bg-primary-link">
              No
            </button>
          </div>
        </div>
      )}
      {step === 'positive' && (
        <div className="flex gap-8 items-center">
          <FeedBackIcon />
          <p className="font-bold">Thanks for letting us know!</p>
        </div>
      )}

      {step === 'negative' && <NegativeFeedback setStep={setStep} />}

      {step === 'final' && (
        <div className="flex gap-8 items-center">
          <FeedBackIcon />
          <p className="font-bold">Thanks for your feedback!</p>
        </div>
      )}
    </div>
  );
};

const RadioButton = ({
  id,
  name,
  value,
  label,
  checked,
  onChange,
}: TRadioButtonProps) => {
  return (
    <div className="inline-block relative">
      <input
        type="radio"
        className="hidden"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label
        htmlFor={id}
        className="inline-flex items-center cursor-pointer select-none">
        <div
          className={`relative w-6 h-6 border border-gray-400 rounded-full transition-all ${
            checked ? 'bg-green-500' : ''
          }`}>
          <input
            type="radio"
            className="hidden"
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            checked={checked}
          />
          <span className="sr-only">{label}</span>
          {checked && (
            <span
              className="absolute inset-0 flex items-center justify-center"
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
              <CorrectIcon />
            </span>
          )}
        </div>
        <div data-testid={label} className="ml-2">{label}</div>
      </label>
    </div>
  );
};

const NegativeFeedback = ({ setStep }: TNegativeFeedbackProps) => {
  const [feedbackValue, setFeedbackValue] = React.useState('');
  return (
    <>
      <h3 className="text-2xl font-bold text-[#222222] mb-2">
        Tell us a little more.
      </h3>
      <p className="leading-6 text-gray-500 mb-9">
        Please let us know why did you not find this helpful.
      </p>

      <div className="flex flex-col gap-4 mb-9">
        {options.map((option, index) => (
          <RadioButton
            key={index}
            id={option}
            name="feedback"
            value={option}
            label={option}
            checked={feedbackValue === option}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFeedbackValue(e.target.value)
            }
          />
        ))}
      </div>
      <button
        disabled={!feedbackValue}
        onClick={() => setStep('final')}
        className="bg-primary-link text-white py-3 px-12 rounded-sm disabled:cursor-not-allowed">
        Submit
      </button>
    </>
  );
};
export default HelpSection;
