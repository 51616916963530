import { updateAppName } from "../../utils/misc";

const Modification = () => {
  return (
    <>
      <p>
        {updateAppName('Sporti')} Payments reserves the right to modify these Payments Terms at any
        time in accordance with this provision. If we make changes to these
        Payments Terms, we will post the revised Payments Terms on the {updateAppName('Sporti')}
        Platform and update the “Last Updated” date at the top of these Payments
        Terms. We will also provide you with notice by email of the modification
        at least thirty (30) days before the date they become effective,
        however, We will inform you about your right of refusal and your right
        to terminate this Agreement in the notification email. If you do not
        terminate your Agreement before the date the revised Terms become
        effective, your continued use of the Payment Services will constitute
        acceptance of the revised Payments Terms.
      </p>
    </>
  );
};

export default Modification;
