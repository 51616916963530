const Abandonment = () => {
  return (
    <p>
      If we cannot initiate a Payout, refund, or pay other funds due to you for
      the relevant period of time set forth by your state, country, or other
      governing body in its laws, we may process the funds due to you in
      accordance with our legal obligations, including by submitting such funds
      to the appropriate governing body as required by law.
    </p>
  );
};

export default Abandonment;
