import React from 'react';
import { updateAppName } from '../../utils/misc';

const Sharing = () => {
  return (
    <>
      <p className="my-6">
        <strong className="text-black">
          4.1 Advertising and Social Media; Sharing With Your Consent.
        </strong>
        <br />
        Where you have provided consent, we share your information, including
        personal information, as described at the time of consent, such as when
        you authorize a third party application or website to access your {updateAppName('Sporti')}
        Account or when you participate in promotional activities conducted by
        {updateAppName('Sporti')} partners or third parties. <br />
        Where permissible according to applicable law we may use certain limited
        personal information about you, such as your email address, to hash it
        and to share it with social media platforms, such as Facebook or Google,
        to generate leads, drive traffic to our websites or otherwise promote
        our products and services or the {updateAppName('Sporti')} Platform. These processing
        activities are based on our legitimate interest in undertaking marketing
        activities to offer you products or services that may be of your
        interest. <br />
        The social media platforms with which we may share your personal
        information are not controlled or supervised by {updateAppName('Sporti')}. Therefore, any
        questions regarding how your social media platform service provider
        processes your personal information should be directed to such provider.
        <br />
        Please note that you may, at any time, ask {updateAppName('Sporti')} to cease processing
        your data for these direct marketing purposes by sending an e-mail to
        opt-out@{updateAppName('sporti')}.com.
      </p>
      <p className="my-6">
        <strong className="text-black">4.2 Sharing between Customers. </strong>
        <br />
        To help facilitate bookings or other interactions between Customers, we
        may need to share certain information, including personal information,
        with other Customers, as it is necessary for the adequate performance of
        the contract between you and us, as follows: <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            When you as a User submit a booking request, certain information
            about you is shared with the Provider (and Co-Provider, if
            applicable), including your profile, full name, the full name of any
            additional Users, your cancellation history, and other information
            you agree to share. When your booking is confirmed, we will disclose
            additional information to assist with coordinating the trip, like
            your phone number.
          </li>
          <li>
            When you as a Provider (or Co-Provider, if applicable) have a
            confirmed booking, certain information is shared with the User (and
            the additional Users they may invite, if applicable) to coordinate
            the booking, such as your profile, full name, phone number, and
            Sports Activities or Experience address.
          </li>
          <li>
            When you as a Provider invite another Customer to become a
            Co-Provider, you authorize the Co-Provider to access and update your
            information and Customer Content, including but not limited to
            certain information like your full name, phone number, Sports
            Activities address, calendar, Listing information, Listing photos,
            and email address.
          </li>
          <li>
            When you as a User invite additional Users to a booking, your full
            name, travel dates, Provider name, Listing details, the Sports
            Activities address, and other related information will be shared
            with each additional User.
          </li>
          <li>
            When you as a User initiate a Group Payment Booking Request certain
            information about each participant such as first name, last initial,
            profile picture as well as the booking details is shared among all
            participants of the Group Payment Booking Request.
          </li>
        </ul>
        We don't share your billing and payout information with other Customers.
      </p>

      <p className="my-6">
        <strong className="text-black">
          4.3 Profiles, Listings, and other Public Information.
        </strong>
        <br />
        The {updateAppName('Sporti')} Platform lets you publish information, including personal
        information, that is visible to the general public. For example: <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            Parts of your public profile page, such as your first name, your
            description, and city, are publicly visible to others.
          </li>
          <li>
            Listing pages are publicly visible and include information such as
            the Sports Activities approximate location (neighborhood and city)
            or precise location (where you have provided your consent), Listing
            description, calendar availability, your public profile photo,
            aggregated demand information (like page views over a period of
            time), and any additional information you choose to share.
          </li>
          <li>
            After completing a booking, Users and Providers may write Reviews
            and rate each other. Reviews and Ratings are a part of your public
            profile page and may also be surfaced elsewhere on the {updateAppName('Sporti')}
            Platform (such as the Listing page).
          </li>
          <li>
            If you submit content in a community or discussion forum, blog or
            social media post, or use a similar feature on the {updateAppName('Sporti')} Platform,
            that content is publicly visible.
          </li>
        </ul>
        Based on our legitimate interest to promote the {updateAppName('Sporti')} Platform we may
        display parts of the {updateAppName('Sporti')} Platform (e.g., your Listing page) on sites
        operated by {updateAppName('Sporti')}'s business partners, using technologies such as
        widgets or APIs. If your Listings are displayed on a partner's site,
        information from your public profile page may also be displayed. <br />
        Information you share publicly on the {updateAppName('Sporti')} Platform may be indexed
        through third party search engines. In some cases, you may opt-out of
        this feature in your Account settings. If you change your settings or
        your public-facing content, these search engines may not update their
        databases. We do not control the practices of third party search
        engines, and they may use caches containing your outdated information.
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.4 Additional Services by Providers.
        </strong>
        <br />
        Providers may need to use third party services available through the
        {updateAppName('Sporti')} Platform to assist with managing their Sports Activities or
        providing additional services requested by you, such as cleaning
        services or lock providers. Providers may use features on the {updateAppName('Sporti')}
        Platform to share information about the User (like check-in and
        check-out dates, User name, User phone number) with such third party
        service providers for the purposes of coordinating the participation,
        managing the Sports Activities, or providing other services. Providers
        are responsible for third party service providers they use and ensuring
        those service providers process User information securely and in
        compliance with applicable law including data privacy and data
        protection laws.
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.5 Compliance with Law, Responding to Legal Requests, Preventing Harm
          and Protection of our Rights.
        </strong>
        <br />
        {updateAppName('Sporti')} and {updateAppName('Sporti')} Payments may disclose your information, including
        personal information, to courts, law enforcement, governmental
        authorities, tax authorities, or authorized third parties, if and to the
        extent we are required or permitted to do so by law or if such
        disclosure is reasonably necessary: (i) to comply with our legal
        obligations, (ii) to comply with a valid legal request or to respond to
        claims asserted against {updateAppName('Sporti')}, (iii) to respond to a valid legal
        request relating to a criminal investigation or alleged or suspected
        illegal activity or any other activity that may expose us, you, or any
        other of our users to legal liability (See more information on {updateAppName('Sporti')}'s
        Law Enforcement Guidelines here), (iv) to enforce and administer our
        Terms of Service, the Payment Terms or other agreements with Customers,
        or (v) to protect the rights, property or personal safety of {updateAppName('Sporti')}, its
        employees, its Customers, or Customers of the public. For example, if
        permitted due to the forgoing circumstances, Provider tax information
        may be shared with tax authorities or other governmental agencies.{' '}
        <br />
        These disclosures may be necessary to comply with our legal obligations,
        for the protection of your or another person's vital interests or for
        the purposes of our or a third party's legitimate interest in keeping
        the {updateAppName('Sporti')} Platform secure, preventing harm or crime, enforcing or
        defending legal rights, facilitating the collection of taxes and
        prevention of tax fraud or preventing damage. <br />
        Where appropriate, we may notify Customers about legal requests unless:
        (i) providing notice is prohibited by the legal process itself, by court
        order we receive, or by applicable law, or (ii) we believe that
        providing notice would be futile, ineffective, create a risk of injury
        or bodily harm to an individual or group, or create or increase a risk
        of fraud upon {updateAppName('Sporti')}'s property, its Customers and the {updateAppName('Sporti')} Platform.
        In instances where we comply with legal requests without notice for
        these reasons, we may attempt to notify that Customer about the request
        after the fact where appropriate and where we determine in good faith
        that we are no longer prevented from doing so.
      </p>
      <p className="my-6">
        <strong className="text-black">4.6 Service Providers. </strong> <br />
        {updateAppName('Sporti')} and {updateAppName('Sporti')} Payments uses a variety of third-party service
        providers to help us provide services related to the {updateAppName('Sporti')} Platform and
        the Payment Services. <br />
        For example, service providers may help us: (i) verify your identity or
        authenticate your identification documents, (ii) check information
        against public databases, (iii) conduct background or police checks,
        fraud prevention, and risk assessment, (iv) perform product development,
        maintenance and debugging, (v) allow the provision of the {updateAppName('Sporti')}
        Services through third party platforms and software tools (e.g. through
        the integration with our APIs), (vi) provide customer service,
        advertising, or payments services, or (vii) process, handle or assess
        insurance claims or other similar claims (such as claims under the
        {updateAppName('Sporti')} Provider Guarantee). These providers have limited access to your
        personal information to perform these tasks on our behalf, and are
        contractually bound to protect the personal information and only use the
        personal information in accordance with our instructions . <br />
        {updateAppName('Sporti')} and the {updateAppName('Sporti')} Payments will need to share your information,
        including personal information, in order to ensure the adequate
        performance of our contract with you.
      </p>
      <p className="my-6">
        <strong className="text-black">4.7 Corporate Affiliates. </strong>
        <br />
        To enable or support us in providing the {updateAppName('Sporti')} Platform and the Payment
        Services, we may share your information, including personal information,
        within our corporate family of companies (both financial and
        non-financial entities) that are related by common ownership or control.
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <strong className="text-black">Sharing with {updateAppName('Sporti')}, Inc.</strong>
            Even if your country of residence is not the United States, your
            information may be shared with {updateAppName('Sporti')} which provides the technical
            infrastructure for the {updateAppName('Sporti')} Platform, product development and
            maintenance, customer support, trust and safety and other business
            operation services to other {updateAppName('Sporti')} entities. The data sharing is
            necessary for the performance of the contract between you and us and
            is based on our legitimate interests in providing the {updateAppName('Sporti')}
            Platform globally.
          </li>
          <li>
            <strong className="text-black">
              Sharing with {updateAppName('Sporti')} Payments.
            </strong>
            In order to facilitate payments on or through the {updateAppName('Sporti')} Platform,
            certain information may be shared with the relevant {updateAppName('Sporti')} Payments
            entity. The data sharing is necessary for the performance of the
            contract between you and us.
          </li>
        </ul>
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.8 Collection and Remittance of Occupancy Taxes.
        </strong>
        <br />
        In jurisdictions where {updateAppName('Sporti')} facilitates the Collection and Remittance
        of Taxes, Providers and Users, where legally permissible according to
        applicable law, expressly grant us permission, without further notice,
        to disclose Providers' and Users' data and other information relating to
        them or to their transactions, bookings, Sports Activities Taxes to the
        relevant tax authority, including, but not limited to, the Provider's or
        User's name, Listing addresses, transaction dates and amounts, tax
        identification number(s), the amount of taxes received (or due) by
        Providers from Users, and contact information.
      </p>
      <p className="my-6">
        <strong className="text-black">4.9 Government Registration.</strong>
        <br />
        In jurisdictions where {updateAppName('Sporti')} facilitates or requires a registration,
        notification, permit, or license application of a Provider with a local
        governmental authority through the {updateAppName('Sporti')} Platform in accordance with
        local law, we may share information of participating Providers with the
        relevant authority, both during the application process and, if
        applicable, periodically thereafter, such as the Provider's full name
        and contact details, Sports Activities address, tax identification
        number, Listing details, and number of nights booked.
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.10 Information Provided to Enterprise Customers.
        </strong>
        <br />
        If you have linked your {updateAppName('Sporti')} Account to the {updateAppName('Sporti')} Account of a
        company or other organization (an “Enterprise”), added your work email
        address, or have a booking facilitated via another party (such as the
        future employer or other entity) or used a coupon in a similar capacity
        in connection with an Enterprise (such as using a coupon to pay for an
        Sports Activities for an enterprise related event like employment
        onboarding, orientation, meetings, etc.) through one of our Enterprise
        products, that Enterprise will have access to your name, contact
        details, permissions and roles, and other information as required to
        enable use by you and the Enterprise of such Enterprise products.
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.11 Provider Information Provided to {updateAppName('Sporti')} for Work Customers.
        </strong>
        <br />
        If a booking is designated as being for business purposes and made by a
        User affiliated with an Enterprise, and the Enterprise is enrolled in
        our {updateAppName('Sporti')} for Work , such as a booking made for business purposes using
        a coupon provided by an Enterprise, we may disclose information related
        to the booking to the Enterprise, such as the name of the Provider, the
        Sports Activities address, booking dates, pricing, Listing details, and
        other related information, to the extent necessary for the adequate
        performance of {updateAppName('Sporti')}'s contract with the Enterprise and to provide the
        services. . At the request of the Enterprise or the User, we may also
        share this information with third parties engaged by the Enterprise to
        provide travel management, travel planning, financial reporting,
        personnel management, crisis management, or other services.
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.12 User Information Provided to {updateAppName('Sporti')} for Work Customers.
        </strong>
        <br />
        If you have linked your {updateAppName('Sporti')} Account with an Enterprise, and you are
        included on a booking designated as being for business purposes, we may
        disclose information related to the booking to the Enterprise to the
        extent necessary for the adequate performance of {updateAppName('Sporti')}'s contract with
        the Enterprise. Typically, this includes information related to the
        booking, such as your name, dates of the booking, number of Users,
        pricing, Listing details, the Sports Activities address, and other
        related information. At the request of you or your Enterprise, we may
        also disclose this information to service providers engaged by it, such
        as companies that provide travel management, financial reporting,
        personnel management, crisis management, or other services. In the event
        of a dispute, emergency, or similar situation involving a booking
        identified as being for business purposes, {updateAppName('Sporti')} may also share
        additional relevant information it believes is necessary to safely and
        quickly address the situation.
      </p>
      <p className="my-6">
        <strong className="text-black">
          4.13 Programs with Managers and Owners.
        </strong>
        <br />
        We may share personal information and booking information with Providers
        in order to facilitate programs with these partners. For example, User
        booking and personal information may be shared with Providers where a
        Provider lives and/or the listing is to facilitate Providing services,
        security, billing, and other services that may be provided.
      </p>
      <p className="my-6">
        <strong className="text-black">4.14 Business Transfers. </strong> <br />
        If {updateAppName('Sporti')} undertakes or is involved in any merger, acquisition,
        reorganization, sale of assets, bankruptcy, or insolvency event, then we
        may sell, transfer or share some or all of our assets, including your
        information in connection with such transaction or in contemplation of
        such transaction (e.g., due diligence). In this event, we will notify
        you before your personal information is transferred and becomes subject
        to a different privacy policy.
      </p>
      <p className="my-6">
        <strong className="text-black">4.15 Aggregated Data. </strong> <br />
        We may also share aggregated information (information about our users
        that we combine together so that it no longer identifies or references
        an individual user) and other anonymized information for regulatory
        compliance, industry and market analysis, research, demographic
        profiling, marketing and advertising, and other purposes.
      </p>
    </>
  );
};

export default Sharing;
