import React from 'react';
import { updateAppName } from '../../utils/misc';

const KeyDefinitions = () => {
  return (
    <div className="space-y-4">
      <p>
        {' '}
        <strong className="text-black">“Payout” </strong>
        means a payment initiated by {updateAppName('Sporti')} Payments to a Provider for bookings
        of their Listings on the {updateAppName('Sporti')} Platform.
      </p>
      <p>
        {' '}
        <strong className="text-black">“Payment Method” </strong>
        means a financial instrument that you have added to your {updateAppName('Sporti')} Account,
        such as a credit card, debit card, or other authorized account.
      </p>
      <p>
        {' '}
        <strong className="text-black">“Payout Method” </strong>
        means a financial instrument that you have added to your {updateAppName('Sporti')} Account,
        such as a bank account, direct deposit, a prepaid card, or a debit card
        (where available).
      </p>
      <p>
        {' '}
        <strong className="text-black">“Provider Fees” </strong>
        means the fee the Provider must pay to {updateAppName('Sporti')} when a booking is made by
        a User.
      </p>
      <p>
        {' '}
        <strong className="text-black">“Listing Fees”</strong>
        means the fee the User must pay to {updateAppName('Sporti')} to book a listing from a
        Provider.
      </p>
    </div>
  );
};

export default KeyDefinitions;
