import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/icons/forwardArrow.svg';

type QuestionItemProps = { text: string; id: string };

const QuestionItem: React.FC<QuestionItemProps> = ({ text, id }) => {
  return (
    <Link
      to={`/articles/${id}`}
      className="group text-sm cursor-pointer py-3 flex items-center justify-between lg:justify-start text-black hover:text-primary-link md:py-4 md:text-base">
      {text}
      <Arrow className="cursor-pointer opacity-100 w-2 ml-7 group-hover:ml-8 transition-all" />
    </Link>
  );
};

export default QuestionItem;
