import React from 'react';
import { updateAppName } from '../../utils/misc';

const Security = () => {
  return (
    <p className="my-6">
      We are continuously implementing and updating administrative, technical,
      and physical security measures to help protect your information against
      unauthorized access, loss, destruction, or alteration. Some of the
      safeguards we use to protect your information are firewalls and data
      encryption, and information access controls. If you know or have reason to
      believe that your {updateAppName('Sporti')} Account credentials have been lost, stolen,
      misappropriated, or otherwise compromised or in case of any actual or
      suspected unauthorized use of your {updateAppName('Sporti')} Account, please contact us
      following the instructions in the Contact Us section below.
    </p>
  );
};

export default Security;
