import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../assets/icons/linkarrow.svg';

type TCardProps = {
  id: string;
  title: string;
  description: string;
};

const Card = ({ id, title, description }: TCardProps) => {
  return (
    <div data-testid="article-card" className="flex flex-col justify-between rounded-xl border border-[#15488F4A] px-5 py-3 relative bg-white hover:shadow-lg transition-all">
      <h2 className="text-xl font-semibold tracking-[0.2px] leading-7 w-3/4 mb-5">
        {title}
      </h2>
      <p data-testid="description-test-id" className="tracking-tightest font-light text-gray-400 mb-6 leading-5">
        {description}
      </p>
      <Link
        className="flex items-center text-primary-link tracking-tightest"
        to={`/articles/${id}`}>
        Read More
        <ArrowIcon className="-rotate-90" />
      </Link>
    </div>
  );
};

export default Card;
