import React from 'react';
import { updateAppName } from '../../utils/misc';

const YourRights = () => {
  return (
    <>
      <p className="my-6">
        Consistent with applicable law, you may exercise any of the rights
        described in this section before your applicable {updateAppName('Sporti')} Data Controller
        and Payments Data Controller. See here for information on data subject
        rights requests and how to submit a request. . Please note that we may
        ask you to verify your identity and request before taking further action
        on your request.
      </p>
      <p className="my-6">
        <strong className="text-black">6.1 Managing Your Information. </strong>{' '}
        <br />
        You may access and update some of your information through your Account
        settings. If you have chosen to connect your {updateAppName('Sporti')} Account to a
        third-party application, like Facebook or Google, you can change your
        settings and remove permission for the app by changing your Account
        settings. You are responsible for keeping your personal information
        up-to-date.
      </p>
      <p className="my-6">
        <strong className="text-black">
          6.2 Rectification of Inaccurate or Incomplete Information.
        </strong>{' '}
        <br />
        You have the right to ask us to correct inaccurate or incomplete
        personal information about you (and which you cannot update yourself
        within your {updateAppName('Sporti')} Account).
      </p>
      <p className="my-6">
        <strong className="text-black">6.3 Data Access and Portability.</strong>{' '}
        <br />
        In some jurisdictions, applicable law may entitle you to request certain
        copies of your personal information held by us. You may also be entitled
        to request copies of personal information that you have provided to us
        in a structured, commonly used, and machine-readable format and/or
        request us to transmit this information to another service provider
        (where technically feasible).
      </p>
      <p className="my-6">
        <strong className="text-black">6.4 Data Retention and Erasure.</strong>{' '}
        <br />
        We generally retain your personal information for as long as is
        necessary for the performance of the contract between you and us and to
        comply with our legal obligations. In certain jurisdictions, you can
        request to have all your personal information deleted entirely." Please
        note that if you request the erasure of your personal information:
        <br />
        <ul className="list-disc pl-6 space-y-2">
          <li>
            We may retain some of your personal information as necessary for our
            legitimate business interests, such as fraud detection and
            prevention and enhancing safety. For example, if we suspend a {updateAppName('Sporti')}
            Account for fraud or safety reasons, we may retain certain
            information from that {updateAppName('Sporti')} Account to prevent that Customer from
            opening a new {updateAppName('Sporti')} Account in the future.
          </li>
          <li>
            We may retain and use your personal information to the extent
            necessary to comply with our legal obligations. For example, {updateAppName('Sporti')}
            and {updateAppName('Sporti')} Payments may keep some of your information for tax, legal
            reporting and auditing obligations.
          </li>
          <li>
            Information you have shared with others (e.g Reviews, forum
            postings) may continue to be publicly visible on the {updateAppName('Sporti')}
            Platform, even after your {updateAppName('Sporti')} Account is canceled. However,
            attribution of such information to you will be removed.
            Additionally, some copies of your information (e.g., log records)
            may remain in our database, but are disassociated from personal
            identifiers.
          </li>
          <li>
            Because we maintain the {updateAppName('Sporti')} Platform to protect from accidental
            or malicious loss and destruction, residual copies of your personal
            information may not be removed from our backup systems for a limited
            period of time.
          </li>
        </ul>
      </p>
      <p className="my-6">
        <strong className="text-black">
          6.5 Withdrawing Consent and Restriction of Processing.
        </strong>{' '}
        <br />
        If we are processing your personal information based on your consent you
        may withdraw your consent at any time by changing your Account settings
        or by sending a communication to {updateAppName('Sporti')} specifying which consent you are
        withdrawing. Please note that the withdrawal of your consent does not
        affect the lawfulness of any processing activities based on such consent
        before its withdrawal. Additionally, in some jurisdictions, applicable
        law may give you the right to limit the ways in which we use your
        personal information, in particular where (i) you contest the accuracy
        of your personal information; (ii) the processing is unlawful and you
        oppose the erasure of your personal information; (iii) we no longer need
        your personal information for the purposes of the processing, but you
        require the information for the establishment, exercise or defense of
        legal claims; or (iv) you have objected to the processing pursuant to
        Section 6.6 and pending the verification whether the legitimate grounds
        of {updateAppName('Sporti')} override your own.
      </p>
      <p className="my-6">
        <strong className="text-black">6.6 Objection to Processing.</strong>{' '}
        <br />
        In some jurisdictions, applicable law may entitle you to require {updateAppName('Sporti')}
        and {updateAppName('Sporti')} Payments not to process your personal information for certain
        specific purposes (including profiling) where such processing is based
        on legitimate interest. If you object to such processing {updateAppName('Sporti')} and/or
        {updateAppName('Sporti')} Payments will no longer process your personal information for
        these purposes unless we can demonstrate compelling legitimate grounds
        for such processing or such processing is required for the
        establishment, exercise or defense of legal claims.
        <br />
        Where your personal information is processed for direct marketing
        purposes, you may, at any time, ask {updateAppName('Sporti')} to cease processing your data
        for these direct marketing purposes by sending an e-mail to
        opt-out@{updateAppName('Sporti')}.com.
      </p>
      <p className="my-6">
        <strong className="text-black">6.7 Lodging Complaints.</strong> <br />
        You have the right to lodge complaints about our data processing
        activities by filing a complaint with our Data Protection Officer who
        can be reached by the “Contact Us” section below or with a supervisory
        authority.
      </p>
    </>
  );
};

export default YourRights;
