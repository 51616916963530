import { Link } from 'react-router-dom';
import { ReactComponent as LinkArrowIcon } from '../../assets/icons/linkarrow.svg';
import { TArticle, TTopic } from '../../store/type';

const getName = (path: string) => {
  switch (path) {
    case '':
      return 'Help Center';
    case 'booking-activity':
      return 'I am Booking Sports Activity';
    case 'providing-activity':
      return 'I am Providing Sports Activity';
    default:
      return path;
  }
};

type TBreadCrumb = {
  activityName: string;
  topic?: TTopic;
  article?: TArticle;
};

export const DesktopBreadCrumb = ({
  activityName,
  topic,
  article,
}: TBreadCrumb) => {
  return (
    <div className="hidden lg:flex gap-4 md:gap-6">
      <Link to="/" className="hover:text-primary-link transition-all">
        Help Center
      </Link>
      <LinkArrowIcon className="-rotate-90" />
      <Link
        to={`/${activityName}`}
        className="hover:text-primary-link transition-all">
        {getName(activityName)}
      </Link>
      {topic && (
        <>
          <LinkArrowIcon className="-rotate-90" />
          <Link
            to={`/${activityName}/${topic.id}`}
            className="hover:text-primary-link transition-all">
            {topic.name}
          </Link>
        </>
      )}
      {topic && article && (
        <>
          <LinkArrowIcon className="-rotate-90" />
          <Link
            to={`/articles/${article.id}`}
            className="hover:text-primary-link transition-all">
            {article.name}
          </Link>
        </>
      )}
    </div>
  );
};

export const MobileBreadCrumb = ({
  activityName,
  topic,
  article,
}: TBreadCrumb) => {
  if (activityName && topic && article) {
    return (
      <>
        <LinkArrowIcon className="rotate-90" />
        <Link
          to={`/${activityName}/${topic.id}`}
          className="hover:text-primary-link transition-all">
          {topic.name}
        </Link>
      </>
    );
  } else if (activityName && topic) {
    return (
      <>
        <LinkArrowIcon className="rotate-90" />
        <Link
          to={`/${activityName}`}
          className="hover:text-primary-link transition-all">
          {getName(activityName)}
        </Link>
      </>
    );
  } else {
    return (
      <>
        <LinkArrowIcon className="rotate-90" />
        <Link to="/" className="hover:text-primary-link transition-all">
          Help Center
        </Link>
      </>
    );
  }
};

const BreadCrumb = ({ activityName, topic, article }: TBreadCrumb) => {
  return (
    <div className="bg-white text-gray-400 leading-5 my-2">
      <div className="flex max-w-7xl p-4 mx-auto items-center gap-4 md:gap-6 lg:gap-8">
        <div className="lg:hidden flex items-center">
          <MobileBreadCrumb
            activityName={activityName}
            topic={topic}
            article={article}
          />
        </div>
        <DesktopBreadCrumb
          activityName={activityName}
          topic={topic}
          article={article}
        />
      </div>
    </div>
  );
};

export default BreadCrumb;
