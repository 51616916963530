import { TTopic, TActivity, TArticle } from './../type.d';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import data from '../../data.json';

export type TInitialState = {
  data: TActivity[];
  filteredTopics: TTopic[];
  articles: TArticle[];
  popularArticles: TArticle[];
  filteredArticles: TArticle[];
  selectedArticle: TArticle | {};
  selectedActivity: TActivity;
  selectedTopic: TTopic;
};

const getAllArticles = () => {
  let allArticles: TArticle[] = [];
  data.activity.forEach(activity => {
    activity.topics.forEach(topic => {
      allArticles = [...allArticles, ...topic.articles];
    });
  });

  return allArticles;
};

const initialState: TInitialState = {
  data: data.activity as TActivity[],
  filteredTopics: [],
  articles: getAllArticles(),
  filteredArticles: [],
  popularArticles: [],
  selectedArticle: {},
  selectedActivity: {} as TActivity,
  selectedTopic: {} as TTopic,
};

const updateName = (arr: any[]) => (arr.map((el: any) => {
  let name = el.name;
  if (process.env.REACT_APP_NAME) {
    name = name.replaceAll('Sporti', process.env.REACT_APP_NAME);
    name = name.replaceAll('sporti', process.env.REACT_APP_NAME.toLowerCase());
  }
  return {...el, name}
}));

const ActivitySlice = createSlice({
  name: 'activity',
  initialState: initialState,
  reducers: {
    fetchAllActivities: state => {},
    fetchActivitiesByName: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(
        (activity: TActivity) => activity.name === action.payload,
      );
    },
    fetchTopicsByActivityName: (state, action: PayloadAction<string>) => {
      const activity = state.data.find(
        activity => activity.name === action.payload,
      );
      if (activity) {
        state.filteredTopics = activity.topics;
        state.selectedActivity = activity;
      } else {
        state.data = [];
      }
    },

    fetchAllArticles: state => {},

    fetchPopularArticles: state => {
      const allArticles = getAllArticles();
      let fArticle = allArticles.filter(article => article.popular);
      state.popularArticles = updateName(fArticle);
    },

    fetchArticleByID: (state, action: PayloadAction<string>) => {
      const allArticles = getAllArticles();
      const article = allArticles.find(
        article => article.id === action.payload,
      );
      if (article) {
        state.selectedArticle = article;

        for (let activity of state.data) {
          for (let t of activity.topics) {
            for (let a of t.articles) {
              if (a.id === article.id) {
                state.selectedActivity = activity;
                state.selectedTopic = t;
                break;
              }
            }
          }
        }
      } else {
        state.selectedArticle = {};
      }
    },
    fetchArticlesByTopicID: (state, action: PayloadAction<string>) => {
      const topic = state.filteredTopics.find(
        topic => topic.id === action.payload,
      );
      if (topic) {
        state.articles = topic.articles;
        state.selectedTopic = topic;
        for (let activity of state.data) {
          for (let t of activity.topics) {
            if (t.id === topic.id) {
              state.selectedActivity = activity;
              break;
            }
          }
        }
      } else {
        state.articles = [];
      }
    },

    fetchArticlesBySearch: (state, action: PayloadAction<string>) => {
      const allArticles = getAllArticles();
      let fArticle = allArticles.filter(article =>
        article.name.toLowerCase().includes(action.payload.toLowerCase()),
      )
      state.filteredArticles = updateName(fArticle);
    },
  },
});

export const {
  fetchAllActivities,
  fetchActivitiesByName,
  fetchTopicsByActivityName,
  fetchArticlesByTopicID,
  fetchArticlesBySearch,
  fetchArticleByID,
  fetchAllArticles,
  fetchPopularArticles,
} = ActivitySlice.actions;

export default ActivitySlice.reducer;
