import React from 'react';
import { updateAppName } from '../../utils/misc';

const Appointment = () => {
  return (
    <div className="space-y-4">
      <p>
        9.1 Each Customer collecting payment for services provided via the
        {updateAppName('Sporti')} Platform (such as Provider Services or certain transactions
        facilitated through the Resolution Center) (
        <strong className="text-black">“Providing Customer”</strong>) hereby
        appoints {updateAppName('Sporti')} Payments as the Providing Customer&apos;s payment
        collection agent solely for the limited purpose of accepting funds from
        Customers purchasing such services (
        <strong className="text-black">“Purchasing Customers”</strong>).
      </p>
      <p>
        9.2 Each Providing Customer agrees that payment made by a Purchasing
        Customer through {updateAppName('Sporti')} Payments, shall be considered the same as a
        payment made directly to the Providing Customer, and the Providing
        Customer will provide the purchased services to the Purchasing Customer
        in the agreed-upon manner as if the Providing Customer has received the
        payment directly from the Purchasing Customer. Each Providing Customer
        agrees that {updateAppName('Sporti')} Payments may refund the Purchasing Customer in
        accordance with the {updateAppName('Sporti')} Terms. Each Providing Customer understands
        that {updateAppName('Sporti')} Payments&apos; obligation to pay the Providing Customer is
        subject to and conditional upon successful receipt of the associated
        payments from Purchasing Customers. {updateAppName('Sporti')} Payments guarantees payments
        to Providing Customers only for such amounts that have been successfully
        received by {updateAppName('Sporti')} Payments from Purchasing Customers in accordance with
        these Payments Terms. In accepting appointment as the limited payment
        collection agent of the Providing Customer, {updateAppName('Sporti')} Payments assumes no
        liability for any acts or omissions of the Providing Customer.
      </p>
      <p>
        9.3 Each Purchasing Customer acknowledges and agrees that,
        notwithstanding the fact that {updateAppName('Sporti')} Payments is not a party to the
        agreement between you and the Providing Customer, {updateAppName('Sporti')} Payments acts
        as the Providing Customer&apos;s payment collection agent for the
        limited purpose of accepting payments from you on behalf of the
        Providing Customer. Upon your payment of the funds to {updateAppName('Sporti')} Payments,
        your payment obligation to the Providing Customer for the agreed upon
        amount is extinguished, and {updateAppName('Sporti')} Payments is responsible for remitting
        the funds to the Providing Customer in the manner described in these
        Payments Terms, which constitute {updateAppName('Sporti')} Payments&apos; agreement with
        the Purchasing Customer. In the event that {updateAppName('Sporti')} Payments does not
        remit any such amounts, the Providing Customer will have recourse only
        against {updateAppName('Sporti')} Payments and not the Purchasing Customer directly.
      </p>
    </div>
  );
};

export default Appointment;
