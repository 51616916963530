import TextWrapper from '../../components/TextWrapper/Index';
import Eligibility from './Eligibility';
import Modification from './Modification';
import Account from './Account';
import Content from './Content';
import Service from './Service';
import Provider from './Provider';
import UserTerms from './UserTerms';
import Cancellation from './Cancellation';
import Ratings from './Ratings';
import Damage from './Damage';
import Rounding from './Rounding';
import Taxes from './Taxes';
import Prohibited from './Prohibited';
import Trust from './Trust';
import Disclaimers from './Disclaimers';
import Liability from './Liability';
import Indemnification from './Indemnification';
import Dispute from './Dispute';
import Feedback from './Feedback';
import General from './General';
import Applicable from './Applicable';
import TermTermination from './TermTermination';
import { updateAppName } from '../../utils/misc';

const TermsConditionsAndPolicies = () => {
  return (
    <section className="px-4 py-6 bg-primary-background text-justify text-gray-500">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl text-black py-4">
          Terms Conditions and Policies
        </h1>
        <div className="space-y-4">
          <Introduction />
          <TextWrapper title={`1. Scope of ${updateAppName("Sporti")} Services`}>
            <ScopeofSporforyaServices />
          </TextWrapper>
          <TextWrapper
            title={
              `2. Eligibility, Using the ${updateAppName("Sporti")} Platform, Customer Verification`
            }>
            <Eligibility />
          </TextWrapper>
          <TextWrapper title={'3. Modification of these Terms'}>
            <Modification />
          </TextWrapper>
          <TextWrapper title={'4. Account Registration'}>
            <Account />
          </TextWrapper>
          <TextWrapper title={'5. Content'}>
            <Content />
          </TextWrapper>
          <TextWrapper title={'6. Service Fees'}>
            <Service />
          </TextWrapper>
          <TextWrapper title={'7. Provider Terms'}>
            <Provider />
          </TextWrapper>
          <TextWrapper title={'8. User Terms'}>
            <UserTerms />
          </TextWrapper>
          <TextWrapper title={'9. Cancellations, Refunds, Resolution Center'}>
            <Cancellation />
          </TextWrapper>
          <TextWrapper title={'10. Ratings and Reviews'}>
            <Ratings />
          </TextWrapper>
          <TextWrapper
            title={
              '11. Damage to Sports Activities, Disputes between Customers'
            }>
            <Damage />
          </TextWrapper>
          <TextWrapper title={'12. Rounding off'}>
            <Rounding />
          </TextWrapper>
          <TextWrapper title={'13. Taxes'}>
            <Taxes />
          </TextWrapper>
          <TextWrapper title={'14. Prohibited Activities'}>
            <Prohibited />
          </TextWrapper>
          <TextWrapper
            title={'15. Term, Termination, Suspension and other Measures'}>
            <TermTermination />
          </TextWrapper>
          <TextWrapper title={'16. Trust and Safety'}>
            <Trust />
          </TextWrapper>
          <TextWrapper title={'17. Disclaimers'}>
            <Disclaimers />
          </TextWrapper>
          <TextWrapper title={'18. Liability'}>
            <Liability />
          </TextWrapper>
          <TextWrapper title={'19. Indemnification'}>
            <Indemnification />
          </TextWrapper>
          <TextWrapper
            title={'20. Dispute Resolution and Arbitration Agreement'}>
            <Dispute />
          </TextWrapper>
          <TextWrapper title={'21. Feedback'}>
            <Feedback />
          </TextWrapper>
          <TextWrapper title={'22. Applicable Law and Jurisdiction'}>
            <Applicable />
          </TextWrapper>
          <TextWrapper title={'23. General Provisions'}>
            <General />
          </TextWrapper>
        </div>
      </div>
    </section>
  );
};

export default TermsConditionsAndPolicies;

const Introduction = () => {
  return (
    <>
      <p>
        Please read these Terms Conditions and Policies (“Terms”) carefully as
        they contain important information about your legal rights, remedies and
        obligations. By accessing or using the {updateAppName('Sporti')} Platform, you agree to
        comply with and be bound by these Terms.
      </p>
      <p>
        Please note: These Terms contain an arbitration clause and class action
        waiver that applies to all {updateAppName('Sporti')} Customers. If your country of
        residence is the United States, this provision applies to all disputes
        with {updateAppName('Sporti')}. If your country of residence is outside of the United
        States, this provision applies to any action you bring against {updateAppName('Sporti')} in
        the United States. It affects how disputes with {updateAppName('Sporti')} are resolved. By
        accepting these Terms, you agree to be bound by this arbitration clause
        and class action waiver. Please read it carefully.
      </p>
      <p>
        These Terms constitute a legally binding agreement (
        <strong>"Agreement"</strong>) between you and {updateAppName('Sporti')} (as defined below)
        governing your access to and use of the {updateAppName('Sporti')} website, including any
        subdomains thereof, and any other websites through which {updateAppName('Sporti')} makes
        its services available (collectively,
        <strong> "Site"</strong>), our mobile, tablet and other smart device
        applications, and application program interfaces (collectively,
        <strong> "Application"</strong>) and all associated services
        (collectively, <strong> "{updateAppName('Sporti')} Services"</strong>). The Site,
        Application and {updateAppName('Sporti')} Services together are hereinafter collectively
        referred to as the <strong> "{updateAppName('Sporti')} Platform”</strong>.
      </p>
      <p>
        When these Terms mention <strong>{updateAppName('Sporti')},”</strong> "
        <strong>we,</strong>” “<strong>us,</strong>” or “<strong>our,</strong>”
        it refers to the {updateAppName('Sporti')} company you are contracting with. Your
        contracting entity will generally be determined based on your country of
        residence or establishment.
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          If your country of residence or establishment is the United States,
          you are contracting with {updateAppName('Sporti')}, Inc., 7901 Research Forest Dr, The
          Woodlands, TX 77382, United States. Our collection and use of personal
          information in connection with your access to and use of the {updateAppName('Sporti')}
          Platform is described in our Privacy Policy.
        </li>
      </ul>
      <p>
        Any and all payment processing services through or in connection with
        your use of the {updateAppName('Sporti')} Platform <strong>("Payment Services")</strong>{' '}
        are provided to you by one or more {updateAppName('Sporti')} Payments entities
        (individually and collectively, as appropriate,{' '}
        <strong>"{updateAppName('Sporti')} Payments"</strong>) as set out in the Payments Terms
        of Service <strong>("Payments Terms")</strong>.
      </p>
      <p>
        Providers alone are responsible for identifying, understanding, and
        complying with all laws, rules and regulations that apply to their
        Listings and Provider Services. If you have questions about how local
        laws apply to your Listing(s) and Provider Service(s) on {updateAppName('Sporti')}, you
        should always seek legal guidance.
      </p>
      <h1 className="text-black font-semibold text-xl">Table of Contents</h1>
      <ul className="ml-8 list-decimal">
        <li>Scope of {updateAppName('Sporti')} Services</li>
        <li>Eligibility, Using the {updateAppName('Sporti')} Platform, Customer Verification</li>
        <li>Modification of these Terms</li>
        <li>Account Registration</li>
        <li>Content</li>
        <li>Service Fees</li>
        <li>Terms specific for Providers</li>
        <li>Terms specific for Users</li>
        <li>Cancellations and Refunds, Resolution Center</li>
        <li>Ratings and Reviews</li>
        <li>Damage to Sports Activities, Disputes between Customers</li>
        <li>Rounding off, Currency conversion</li>
        <li>Taxes</li>
        <li>Prohibited Activities</li>
        <li>Term, Termination, Suspension and other Measures</li>
        <li>Trust and Safety</li>
        <li>Disclaimers</li>
        <li>Liability</li>
        <li>Indemnification</li>
        <li>Dispute Resolution</li>
        <li>Feedback</li>
        <li>Applicable Law and Jurisdiction</li>
        <li>General Provisions</li>
      </ul>
    </>
  );
};

const ScopeofSporforyaServices = () => {
  return (
    <>
      <ul className="space-y-2">
        <li>
          1.1 The {updateAppName('Sporti')} Platform is an online marketplace that enables
          registered customers (“Customers”) and certain third parties who offer
          services (Customers and third parties who offer services are
          <strong> “Providers”</strong> and the services they offer are{' '}
          <strong> “Provider Services”</strong>) to publish such Provider
          Services on the {updateAppName('Sporti')} Platform <strong> (“Listings”) </strong>
          and to communicate and transact directly with Customers that are
          seeking to book such Provider Services (Customers using Provider
          Services are <strong>“Users”)</strong>. Provider Services may include
          the offering of Sports Activities services, events and facilities for
          use ("Sports Activities ").
        </li>
        <li>
          1.2 As the operator owner of the {updateAppName('Sporti')} Platform, {updateAppName('Sporti')} does not own,
          create, sell, resell, provide, control, manage, offer, deliver, or
          supply any Listings or Provider Services, nor is {updateAppName('Sporti')} an organizer
          or retailer of Sports Activities. Providers alone are responsible for
          their Listings and Provider Services. When Customers make or accept a
          booking, they are entering into a contract directly with each other.
          {updateAppName('Sporti')} is not and does not become a party to or other participant in
          any contractual relationship between Customers, {updateAppName('Sporti')} is not acting
          as an agent in any capacity for any Customer, except as specified in
          the Payments Terms.
        </li>
        <li>
          1.3 While we may help facilitate the resolution of disputes, {updateAppName('Sporti')}
          has no control over and does not guarantee (i) the existence, quality,
          safety, suitability, or legality of any Listings or Provider Services,
          (ii) the truth or accuracy of any Listing descriptions, Ratings,
          Reviews, or other Customer Content (as defined below), or (iii) the
          performance or conduct of any Customer or third party. {updateAppName('Sporti')} does not
          endorse any Customer, Listing or Provider Services. Any references to
          a Customer being "verified" (or similar language) only indicate that
          the Customer has completed a relevant verification or identification
          process and nothing else. Any such description is not an endorsement,
          certification or guarantee by {updateAppName('Sporti')} about any Customer, including of
          the Customer's identity or background or whether the Customer is
          trustworthy, safe or suitable. You should always exercise due
          diligence and care when deciding whether to participate in an Sports
          Activities, participate in an Experience or Event or use other
          Provider Services, accept a booking request from a User, or
          communicate and interact with other Customers, whether online or in
          person. Verified Images (as defined below) are intended only to
          indicate a photographic representation of a Listing at the time the
          photograph was taken, and are therefore not an endorsement by {updateAppName('Sporti')}
          of any Provider or Listing.
        </li>
        <li>
          1.4 If you choose to use the {updateAppName('Sporti')} Platform as a Provider, your
          relationship with {updateAppName('Sporti')} is limited to being an independent,
          third-party contractor, and not an employee, agent, joint venturer or
          partner of {updateAppName('Sporti')} for any reason, and you act exclusively on your own
          behalf and for your own benefit, and not on behalf, or for the
          benefit, of {updateAppName('Sporti')}. {updateAppName('Sporti')} does not, and shall not be deemed to,
          direct or control you generally or in your performance under these
          Terms specifically, including in connection with your provision of the
          Provider Services. You acknowledge and agree that you have complete
          discretion whether to list Provider Services or otherwise engage in
          other business or employment activities.
        </li>
        <li>
          1.5 To promote the {updateAppName('Sporti')} Platform and to increase the exposure of
          Listings to potential Users, Listings and other Customer Content may
          be displayed on other websites, in applications, within emails, and in
          online and offline advertisements. To assist Customers who speak
          different languages, Listings and other Customer Content may be
          translated, in whole or in part, into other languages. {updateAppName('Sporti')} cannot
          guarantee the accuracy or quality of such translations and Customers
          are responsible for reviewing and verifying the accuracy of such
          translations. The {updateAppName('Sporti')} Platform may contain translations powered by
          Google. Google disclaims all warranties related to the translations,
          express or implied, including any warranties of accuracy, reliability,
          and any implied warranties for merchantability, fitness for a
          particular purpose and non-infringement.
        </li>
        <li>
          1.6 The {updateAppName('Sporti')} Platform may contain links to third-party websites or
          resources <strong> (“Third-Party Services”)</strong>. Such Third-Party
          Services may be subject to different Terms and privacy practices.
          {updateAppName('Sporti')} is not responsible or liable for the availability or accuracy
          of such Third-Party Services, or the content, products, or services
          available from such Third-Party Services. Links to such Third-Party
          Services are not an endorsement by {updateAppName('Sporti')} of such Third-Party
          Services.
        </li>
        <li>
          1.7 Due to the nature of the Internet, {updateAppName('Sporti')} cannot guarantee the
          continuous and uninterrupted availability and accessibility of the
          {updateAppName('Sporti')} Platform. {updateAppName('Sporti')} may restrict the availability of the {updateAppName('Sporti')}
          Platform or certain areas or features thereof, if this is necessary in
          view of capacity limits, the security or integrity of our servers, or
          to carry out maintenance measures that ensure the proper or improved
          functioning of the {updateAppName('Sporti')} Platform. {updateAppName('Sporti')} may improve, enhance and
          modify the {updateAppName('Sporti')} Platform and introduce new {updateAppName('Sporti')} Services from time
          to time.
        </li>
      </ul>
    </>
  );
};
