import { updateAppName } from "../../utils/misc";

const PaymentMethods = () => {
  return (
    <div className="space-y-4">
      <p>
        6.1 When you add a Payment Method or Payout Method to your {updateAppName('Sporti')}
        Account, you will be asked to provide customary billing information such
        as name, billing address, and financial instrument information either to
        {updateAppName('Sporti')} Payments or its third-party payment processor(s). You must
        provide accurate, current, and complete information when adding a
        Payment Method or Payout Method, and it is your obligation to keep your
        Payment Method and Payout Method up-to-date at all times. The
        information required for Payout Methods will depend on the particular
        Payout Method and may include:
        <ul className="list-disc pl-6 space-y-2 my-2">
          <li>
            Your residential address, name on the account, account type, routing
            number, account number, email address, payout currency,
            identification number and account information associated with a
            particular payment processor.
          </li>
        </ul>
      </p>
      <p>
        6.2 When you add or use a new Payment Method, {updateAppName('Sporti')} Payments may verify
        the Payment Method by authorizing a nominal amount or by authenticating
        your account via a third-party payment service provider. For further
        verification, we may also (i) authorize your Payment Method for one or
        two additional nominal amounts, and ask you to confirm these amounts, or
        (ii) require you to upload a billing statement. We may, and retain the
        right to, initiate refunds of these amounts from your Payout Method.
        When you add a Payment Method during checkout, we will automatically
        save that Payment Method to your {updateAppName('Sporti')} Account so it can be used for a
        future transaction.
      </p>
      <p>
        6.3 Please note that Payment Methods and Payout Methods may involve the
        use of third-party payment service providers. These service providers
        may charge you additional fees when processing payments and Payouts in
        connection with the Payment Services (including deducting charges from
        the Payout amount), and {updateAppName('Sporti')} Payments is not responsible for any such
        fees and disclaims all liability in this regard. Your Payment Method or
        Payout Method may also be subject to additional Terms imposed by the
        applicable third-party payment service provider; please review these
        Terms before using your Payment Method or Payout Method.
      </p>
      <p>
        6.4 You authorize {updateAppName('Sporti')} Payments to store your Payment Method
        information and charge your Payment Method as outlined in these Payments
        Terms. If your Payment Method’s account information changes (e.g.,
        account number, routing number, expiration date) as a result of
        re-issuance or otherwise, we may acquire that information from our
        financial services partner or your bank and update your Payment Method
        on file in your {updateAppName('Sporti')} Account.
      </p>
      <p>
        6.5 You are solely responsible for the accuracy and completeness of your
        Payment Method and Payout Method information. {updateAppName('Sporti')} Payments is not
        responsible for any loss suffered by you as a result of incorrect
        Payment Method or Payout Method information provided by you.
      </p>
      <p>
        6.6 If your Contracting Entity's location is different than the country
        of your Payment Method or your selected currency is different than your
        Payment Method's billing currency, your payment may be processed outside
        your country of residence. For example, if you make a booking using a
        U.S.-issued card, but select Euro as your currency, your payment may be
        processed outside the U.S. Banks and credit card companies may impose
        international transaction fees and foreign exchange fees on such
        international transactions. In addition, if you select to pay with a
        currency that is different than your Payment Method's billing currency,
        your bank or credit card company may convert the payment amount to your
        billing currency associated with your Payment Method, based on an
        exchange rate and fee amount determined solely by your bank. As a
        result, the amount listed on your card statement may be a different
        amount than that shown on checkout. Please contact your bank or credit
        card company if you have any questions about these fees or the
        applicable exchange rate.
      </p>
      <p>
        6.7 {updateAppName('Sporti')} Payments and all payment processing services for Providers on
        {updateAppName('Sporti')} are provided by Stripe and are subject to the Stripe Connected
        Account Agreement, which includes the Stripe Terms of Service
        (collectively, the “Stripe Services Agreement''). By agreeing to these
        terms or continuing to operate as a Provider on {updateAppName('Sporti')}, you agree to be
        bound by the Stripe Services Agreement, as the same may be modified by
        Stripe from time to time. As a condition of {updateAppName('Sporti')} enabling payment
        processing services through Stripe, you agree to provide {updateAppName('Sporti')} accurate
        and complete information about you and your business, and you authorize
        {updateAppName('Sporti')} to share it and transaction information related to your use of
        the payment processing services provided by Stripe.
      </p>
    </div>
  );
};

export default PaymentMethods;
