import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { initContext, SporforyaContext } from './context';
import ArticleListPage from './pages/ArticleList';
import ActivityTemplate from './pages/ActivityPage';
import HomePage from './pages/Home';
import SupportPage from './pages/Support';
import store from './store/store';
import TermsConditionsAndPolicies from './pages/TermsConditionsAndPolicies/Index';
import PaymentTerms from './pages/PaymentTerms/Index';
import CopyrightPolicy from './pages/CopyrightPolicy/Index';
import ArticleDetailsPage from './pages/ArticleDetailsPage';
import CookiePolicy from './pages/CookiePolicy/Index';
import RewardTerms from './pages/RewardTerms/Index';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  return (
    <Provider store={store}>
      <SporforyaContext.Provider value={initContext}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SupportPage />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/booking-activity" element={<ActivityTemplate />} />
              <Route
                path="/providing-activity"
                element={<ActivityTemplate />}
              />
              <Route
                path="booking-activity/:topic"
                element={<ArticleListPage />}
              />
              <Route
                path="providing-activity/:topic"
                element={<ArticleListPage />}
              />
              <Route
                path="/articles/:articleId"
                element={<ArticleDetailsPage />}
              />
              <Route
                path="/terms-conditions-and-policies"
                element={<TermsConditionsAndPolicies />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/payment-terms" element={<PaymentTerms />} />
              <Route path="/copyright-policy" element={<CopyrightPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/reward-terms" element={<RewardTerms />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </SporforyaContext.Provider>
    </Provider>
  );
};

export default App;
