import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIocn } from '../../assets/headerIcons/searchIcon.svg';
import { useOnClickOutside } from '../../hooks/useClickOutSide';
import { TArticle } from '../../store/type';
import { Link, useNavigate } from 'react-router-dom';

type SearchInputProps = {
  options: TArticle[];
  value: string;
  placeholder: string;
  className: string;
  onChange: (value: string) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({
  options,
  value,
  placeholder,
  onChange,
  className,
}) => {
  const autocomplete = useRef<HTMLDivElement>(null);
  // states
  const [optionsData, setOptionsData] = useState<TArticle[]>([]);
  const [query, setQuery] = useState<string>(value);
  const [isShow, setIsShow] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleInputChange = (e: { target: HTMLInputElement }) => {
    const { value } = e.target;
    setQuery(value);
    onChange(value);
    const updatedOptions = !value
      ? []
      : [
          ...options.filter(
            option =>
              option.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
          ),
        ];
    setOptionsData(updatedOptions);
  };

  const handleClickOutSide = () => {
    setIsShow(false);
  };

  useOnClickOutside(autocomplete, handleClickOutSide);

  const hilightSearchText = (text: string) => {
    var pattern = new RegExp('(' + query + ')', 'gi');
    var new_text = text.replace(pattern, `<b>${query}</b>`);
    return new_text;
  };
  const selectedOptionData = (text: string) => {
    setQuery(text);
    setIsShow(false);
    onChange(text);
  };

  useEffect(() => {
    setIsShow(optionsData.length !== 0);
  }, [optionsData]);

  const handleItemClick = (item: TArticle) => {
    selectedOptionData(item.name);
    navigate(`/articles/${item.id}`);
  };

  return (
    <div
      data-testid="SearchInput-main-div"
      ref={autocomplete}
      className={`relative w-full ${className}`}>
      <div data-testid="SearchInput-input-div" className="relative">
        <input
          name="SearchInput-input"
          data-testid="SearchInput-input"
          className="relative w-full h-11 text-black px-3 rounded-lg border-solid border-2 border-[#e3e3e3] outline-none text-sm"
          type="search"
          placeholder={placeholder}
          value={query}
          onChange={handleInputChange}
        />
        {!query ? (
          <SearchIocn
            className="w-6 -top-[4.9rem] absolute right-2"
            data-testid="SearchInput-searchIcon"
          />
        ) : null}
      </div>
      {isShow && (
        <ul
          data-testid="SearchInput-list"
          className=" flex items-center justify-start flex-col mt-2 absolute bg-white w-full max-h-60 rounded top-11 z-10 overflow-y-auto shadow-md">
          {optionsData.map(optionItem => (
            <li
              data-testid="SearchInput-list-item"
              className="cursor-pointer border-none w-full outline-none text-left bg-white py-4 px-2 hover:bg-[#e2e2e2]"
              onClick={() => handleItemClick(optionItem)}
              key={optionItem.id}>
              <Link
                to={`/articles/${optionItem.id}`}
                data-testid="SearchInput-list-item-text"
                className="break-words overflow-x-hidden"
                dangerouslySetInnerHTML={{
                  __html: hilightSearchText(optionItem.name),
                }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
