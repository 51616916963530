import React from 'react';
import { updateAppName } from '../../utils/misc';

const TermTermination = () => {
  return (
    <ul className="space-y-2">
      <li>
        15.1 This Agreement shall be effective for a 30-day term, at the end of
        which it will automatically and continuously renew for subsequent 30-day
        terms until such time when you or {updateAppName('Sporti')} terminate the Agreement in
        accordance with this provision.
      </li>
      <li>
        15.2 You may terminate this Agreement at any time by sending us an
        email. If you cancel your {updateAppName('Sporti')} Account as a Provider, any booking(s)
        will be automatically canceled and your Users will receive a full
        refund. If you cancel your {updateAppName('Sporti')} Account as a User, any confirmed
        booking(s) will be automatically canceled and any refund will depend
        upon the terms of the Listing's cancellation policy.
      </li>
      <li>
        15.3 Without limiting our rights specified below, {updateAppName('Sporti')} may terminate
        this Agreement for convenience at any time by giving you thirty (30)
        days' notice via email to your registered email address.
      </li>
      <li>
        15.4 {updateAppName('Sporti')} may immediately, without notice, terminate this Agreement
        and/or stop providing access to the {updateAppName('Sporti')} Platform if (i) you have
        materially breached your obligations under these Terms, the Payments
        Terms, our Policies or Standards, (ii) you have violated applicable
        laws, regulations or third party rights, or (iii) {updateAppName('Sporti')} believes in
        good faith that such action is reasonably necessary to protect the
        personal safety or property of {updateAppName('Sporti')}, its Customers, or third parties
        (for example in the case of fraudulent behavior of a Customer).
      </li>
      <li>
        15.5 In addition, {updateAppName('Sporti')} may take any of the following measures (i) to
        comply with applicable law, or the order or request of a court, law
        enforcement or other administrative agency or governmental body, or if
        (ii) you have breached these Terms, the Payments Terms, our Policies or
        Standards, applicable laws, regulations, or third party rights, (iii)
        you have provided inaccurate, fraudulent, outdated or incomplete
        information during the {updateAppName('Sporti')} Account registration, Listing process or
        thereafter, (iv) you and/or your Listings or Provider Services at any
        time fail to meet any applicable quality or eligibility criteria, (v)
        you have repeatedly received poor Ratings or Reviews or {updateAppName('Sporti')} otherwise
        becomes aware of or has received complaints about your performance or
        conduct, (vi) you have repeatedly canceled confirmed bookings or failed
        to respond to booking requests without a valid reason, or (vii) {updateAppName('Sporti')}
        believes in good faith that such action is reasonably necessary to
        protect the personal safety or property of {updateAppName('Sporti')}, its Customers, or
        third parties, or to prevent fraud or other illegal activity:
        <ul className="space-y-2 list-disc">
          <li className="ml-4">
            refuse to surface, delete or delay any Listings, Ratings, Reviews,
            or other Customer Content;
          </li>
          <li className="ml-4">cancel any pending or confirmed bookings;</li>
          <li className="ml-4">
            limit your access to or use of the {updateAppName('Sporti')} Platform;
          </li>
          <li className="ml-4">
            temporarily or permanently revoke any special status associated with
            your {updateAppName('Sporti')} Account;
          </li>
          <li className="ml-4">
            temporarily or in case of severe or repeated offenses permanently
            suspend your {updateAppName('Sporti')} Account and stop providing access to the {updateAppName('Sporti')}
            Platform.
          </li>
        </ul>
        <p>
          In case of non-material breaches and where appropriate, you will be
          given notice of any intended measure by {updateAppName('Sporti')} and an opportunity to
          resolve the issue to {updateAppName('Sporti')}'s reasonable satisfaction.
        </p>
      </li>
      <li>
        15.6 If we take any of the measures described above (i) we may refund
        your Users in full for any and all bookings that have been canceled,
        irrespective of preexisting cancellation policies, and (ii) you will not
        be entitled to any compensation for pending or bookings that were
        canceled.
      </li>
      <li>
        15.7 When this Agreement has been terminated, you are not entitled to a
        restoration of your {updateAppName('Sporti')} Account or any of your Customer Content. If
        your access to or use of the {updateAppName('Sporti')} Platform has been limited or your
        {updateAppName('Sporti')} Account has been suspended or this Agreement has been terminated
        by us, you may not register a new {updateAppName('Sporti')} Account or access and use the
        {updateAppName('Sporti')} Platform through an {updateAppName('Sporti')} Account of another Customer.
      </li>
    </ul>
  );
};

export default TermTermination;
