import React from 'react';
import { updateAppName } from '../../utils/misc';

const ContactUs = () => {
  return (
    <p className="my-6">
      {updateAppName(`If you have any questions or complaints about this Privacy Policy or
      Sporti's information practices, you may email us at the email addresses
      provided in the relevant sections above or at info@sporti.com`)}
    </p>
  );
};

export default ContactUs;
