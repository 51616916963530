import React from 'react';
import { updateAppName } from '../../utils/misc';

const Service = () => {
  return (
    <ul className="space-y-2">
      <li>
        6.1 {updateAppName('Sporti')} may charge fees to Providers{' '}
        <strong>("Provider Fees")</strong> and/or Users{' '}
        <strong>("User Fees")</strong> (collectively,{' '}
        <strong>"Service Fees"</strong>) in consideration for the use of the
        {updateAppName('Sporti')} Platform. More information about when Service Fees apply and how
        they are calculated can be found in our Service Fees.
      </li>
      <li>
        6.2 Any applicable Service Fees (including any applicable Taxes) will be
        available to a Provider or User prior to publishing or booking a
        Listing. {updateAppName('Sporti')} reserves the right to change the Service Fees at any
        time, and will provide Customers adequate notice of any fee changes
        before they become effective. Such fee changes will not affect any
        bookings made prior to the effective date of the fee change.
      </li>
      <li>
        6.3 You are responsible for paying any Service Fees that you owe to
        {updateAppName('Sporti')}. The applicable Service Fees (including any applicable Taxes) are
        collected by {updateAppName('Sporti')} Payments. {updateAppName('Sporti')} Payments will deduct any Provider
        Fees from the Listing Fee before remitting the payout to the Provider.
        Any User Fees are included in the Total Fees collected by {updateAppName('Sporti')}
        Payments. Except as otherwise provided on the {updateAppName('Sporti')} Platform, Service
        Fees are non-refundable.
      </li>
    </ul>
  );
};

export default Service;
