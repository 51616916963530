import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPopularArticles } from '../../store/reducers/activitySlice';
import { RootState } from '../../store/type';
import Card from '../../components/Card';
import { updateAppName } from '../../utils/misc';

const Articles = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPopularArticles());
  }, [dispatch]);

  const articles = useSelector(
    (state: RootState) => state.activity.popularArticles,
  );
  if (!articles) return null;

  return (
    <div className="py-6 px-4 md:py-10 lg:pt-28 lg:pb-10">
      <h1 className="text-3xl font-bold mb-7">Popular Articles</h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {articles.map((article, index) => {
          if (article.popular) {
            return (
              <Card
                key={index}
                title={updateAppName(article.name)}
                id={article.id}
                description={article.popular ? updateAppName(article.shortcontent) : ''}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Articles;
