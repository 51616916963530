import React from 'react';
import { updateAppName } from '../../utils/misc';

const Ratings = () => {
  return (
    <ul className="space-y-2">
      <li>
        10.1 Within a certain time frame after completing a booking, Users and
        Providers can leave a public review (<strong>“Review”</strong>) and
        submit a star rating (<strong>“Rating”</strong>) about each other.
        Ratings or Reviews reflect the opinions of individual Customers and do
        not reflect the opinion of {updateAppName('Sporti')}. Ratings and Reviews are not verified
        by {updateAppName('Sporti')} for accuracy and may be incorrect or misleading.
      </li>
      <li>
        10.2 Ratings and Reviews by Users and Providers must be accurate and may
        not contain any offensive or defamatory language. Ratings and Reviews
        must comply with {updateAppName('Sporti')}'s policies.
      </li>
      <li>
        10.3 Customers are prohibited from manipulating the Ratings and Reviews
        system in any manner, such as instructing a third party to write a
        positive or negative Review about another Customer.
      </li>
      <li>
        10.4 Ratings and Reviews are part of a Customer's public profile and may
        also be surfaced elsewhere on the {updateAppName('Sporti')} Platform (such as the Listing
        page) together with other relevant information such as number of
        bookings, number of cancellations, average response time and other
        information.
      </li>
    </ul>
  );
};

export default Ratings;
