import { updateAppName } from "../../utils/misc";

const Account = () => {
  return (
    <div className="space-y-4">
      <p>
        5.1 In order to use the Payment Services, you must have a {updateAppName('Sporti')} Account
        in good standing. If you or {updateAppName('Sporti')} closes your {updateAppName('Sporti')} Account for any
        reason, you will no longer be able to use the Payment Services.
      </p>
      <p>
        5.2 You may authorize a third party to use your {updateAppName('Sporti')} Account in
        accordance with the {updateAppName('Sporti')} Terms. You acknowledge and agree that anyone
        you authorize to use your {updateAppName('Sporti')} Account may use the Payment Services on
        your behalf and that you will be responsible for any payments made by
        such person.
      </p>
    </div>
  );
};

export default Account;
