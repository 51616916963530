import React from 'react';
import { updateAppName } from '../../utils/misc';

const ApplicableLaw = () => {
  return (
    <div className="space-y-4">
      <p>
        21.1 If you are contracting with {updateAppName('Sporti')} Payments US, these Payments
        Terms will be interpreted in accordance with the laws of the State of
        Texas and the United States of America, without regard to
        conflict-of-law provisions. Judicial proceedings (other than small
        claims actions) that are excluded from the Arbitration Agreement in
        Section 23 must be brought in state or federal court in Texas, unless we
        both agree to some other location. You and we both consent to venue and
        personal jurisdiction in Texas.
      </p>
      <p>
        21.3 If you are contracting with {updateAppName('Sporti')} Payments UK, these Payments
        Terms will be interpreted in accordance with English law. The
        application of the United Nations Convention on Contracts for the
        International Sale of Goods (CISG) is excluded. The choice of law does
        not impact your rights as a consumer according to the consumer
        protection regulations of your country of residence. If you are acting
        as a consumer, judicial proceedings that you are able to bring against
        us arising from or in connection with these Payments Terms may only be
        brought in a court located in England or a court with jurisdiction in
        your place of residence. If {updateAppName('Sporti')} Payments wishes to enforce any of its
        rights against you as a consumer, we may do so only in the courts of the
        jurisdiction in which you are a resident. If you are acting as a
        business, you agree to submit to the exclusive jurisdiction of the
        English courts.
      </p>
    </div>
  );
};

export default ApplicableLaw;
