import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import Drawer from '../../components/Drawer/index';
import QuestionSection from '../../components/QuestionsSection/index';
import { fetchArticlesByTopicID } from '../../store/reducers/activitySlice';
import { RootState, TActivity, TTopic } from '../../store/type';

const ArticleList = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const pathname = location.pathname;

  const activityPath = pathname.split('/')[1];
  const topicPath = pathname.split('/')[2];

  useEffect(() => {
    dispatch(fetchArticlesByTopicID(topicPath));
  }, [dispatch, topicPath]);

  const articles = useSelector((state: RootState) => state.activity.articles);

  const selectedActivity = useSelector(
    (state: RootState) => state.activity.selectedActivity,
  ) as TActivity;

  const selectedTopic = useSelector(
    (state: RootState) => state.activity.selectedTopic,
  ) as TTopic;

  // const activityName = activity.name;
  const selectedTopicName = selectedTopic.name;
  const selectedActivityName = selectedActivity.name;

  return (
    <section className=" bg-primary-background">
      <BreadCrumbs activityName={selectedActivityName} topic={selectedTopic} />
      <div className="max-w-7xl mx-auto px-4 py-10 flex flex-col md:flex-row gap-14 lg:gap-28">
        <Drawer
          activityPath={activityPath}
          selectedTopicName={selectedTopicName}
        />
        <QuestionSection title={selectedTopicName} articles={articles} />
      </div>
    </section>
  );
};

export default ArticleList;
